// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
  justify-content: center;
}

.container-fluid {
  padding: 0;
  overflow: hidden;
}

.row {
  overflow: hidden;
}

.auth-form-wrapper {
  margin: 2.5rem auto 1.5rem;
  max-width: 34.5rem;
}
@media (max-width: 575.98px) {
  .auth-form-wrapper {
    padding: 0 2rem;
  }
}

.auth-form {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 2.75rem 3.375rem 3.875rem;
}
@media (min-width: 992px) {
  .auth-form::after, .auth-form::before {
    display: block;
    position: absolute;
    z-index: -1;
    background-position: center;
    background-size: 13px;
    width: 400px;
    height: 140px;
  }
  .auth-form::after {
    right: -100px;
    bottom: -100px;
    background-image: url("https://static.learnlux.com/www-2/background/dash-v1.0.jpg");
    content: "";
  }
  .auth-form::before {
    top: -70px;
    left: -100px;
    background-image: url("https://static.learnlux.com/www-2/background/dot-v1.0.jpg");
    content: "";
  }
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: -2rem;
  max-width: 34.5rem;
  font-size: 1.125rem;
}
.video-wrapper .icon {
  display: flex;
  margin-top: 1rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 34.5rem;
  font-size: 1.125rem;
}
.wrapper .icon {
  display: flex;
  margin-top: 1rem;
}

.support-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
}
@media (max-width: 575.98px) {
  .support-wrapper {
    justify-content: left;
    margin-top: -1.5rem;
  }
  .support-wrapper .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
}

.hero {
  padding: 2rem;
  text-align: start;
}
@media (min-width: 992px) {
  .hero {
    margin-left: auto;
    padding: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .hero {
    padding: 2rem;
  }
}
.hero .display-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hero li {
  margin-bottom: 0.25rem;
}
.hero p {
  width: 100%;
  font-weight: 200;
}

.video {
  border: 2px solid #f8f9f9;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  width: 95%;
}
.video .wistia_responsive_padding {
  position: relative;
  padding: 56.25% 0 0;
}
.video .wistia_responsive_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video .wistia_embed {
  position: relative;
  width: 100%;
  height: 100%;
}
.video .wistia_swatch {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 200ms;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video img {
  filter: blur(5px);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.color-box {
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #eac63f;
  padding: 20px;
  width: 100%;
  aspect-ratio: 16/9;
}
@media (max-width: 575.98px) {
  .color-box {
    border: none;
    box-shadow: none;
  }
}
.color-box img {
  position: absolute;
  bottom: -3%;
  width: 90%;
  max-width: 492px;
  height: auto;
}
.color-box .lottie {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.5rem;
  width: 20%;
  height: auto;
}

.color-box-subtitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.25rem;
}

.order-lg-last {
  padding-right: 0;
  padding-left: 0;
}

.marquee-box {
  padding: 2rem;
  text-align: start;
}
@media (min-width: 992px) {
  .marquee-box {
    padding: 3.75rem;
  }
}
.marquee-box .wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  max-width: 34.5rem;
  height: 100%;
  font-size: 1.125rem;
}
.marquee-box h1 {
  margin-bottom: 1.5rem;
}
.marquee-box p {
  margin-bottom: 1.5rem;
  width: 100%;
  font-weight: 200;
}
.marquee-box .display-5 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.learn-more {
  background-color: #082732;
}

.welcome-banner {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #082732;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 0;
  padding-left: 0;
  min-height: 511px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .welcome-banner {
    flex-direction: column;
    align-items: center;
    min-height: auto;
  }
}
.welcome-banner .background-img {
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  background-color: #082732;
  background-image: url("https://static.learnlux.com/images/backgrounds/background-budget-v1.0.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.welcome-banner .text-yellow {
  color: #eac63f;
}
.welcome-banner .blue-shape {
  position: relative;
  margin-top: auto;
  border-radius: 100px 100px 0 0;
  background-color: #082732;
  width: 539px;
  height: 469px;
}
@media (max-width: 767.98px) {
  .welcome-banner .blue-shape {
    margin-top: 0;
    margin-left: 0;
    background-color: transparent;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 575.98px) {
  .welcome-banner .blue-shape ul {
    padding-left: 0.5rem;
  }
}
.welcome-banner .blue-shape ul {
  margin-top: 0;
  margin-bottom: 4rem;
  margin-left: 1rem;
  list-style: none;
  font-weight: 700;
}
.welcome-banner .welcome-img {
  position: relative;
  margin-bottom: 0;
  width: auto;
  max-width: 617px;
  height: auto;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .welcome-banner .welcome-img {
    padding-right: 100px;
  }
}
@media (max-width: 767.98px) {
  .welcome-banner .welcome-img {
    margin-top: 100px;
    margin-right: 0;
    padding-right: 2rem;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .welcome-banner .welcome-img {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}
.welcome-banner .center-dots {
  position: absolute;
  top: -37%;
  left: 0%;
}
@media (max-width: 1199.98px) {
  .welcome-banner .center-dots {
    display: none;
  }
}
.welcome-banner .heading {
  margin-top: 7rem;
  margin-bottom: 2rem;
  margin-left: 3rem;
}
@media (max-width: 767.98px) {
  .welcome-banner .heading {
    margin-top: 2rem;
    margin-left: 3rem;
    padding-right: 2rem;
    text-align: left;
  }
}
@media (max-width: 575.98px) {
  .welcome-banner .heading {
    margin-left: 1.5rem;
  }
}
.welcome-banner .list {
  margin-bottom: 1.5rem;
  margin-left: 3rem;
}
@media (max-width: 575.98px) {
  .welcome-banner .list {
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
  }
}

.get-help {
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #082732;
  padding: 0;
  overflow: visible;
  color: #fff;
}
.get-help li {
  padding-left: 0.75rem;
  list-style: url("https://static.learnlux.com/images/icons/yellow-dot.svg");
}
.get-help .left-dots {
  position: absolute;
  top: -70px;
  left: -12%;
  overflow: visible;
}
@media (min-width: 1600px) {
  .get-help .left-dots {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .get-help .left-dots {
    display: none;
  }
}
.get-help .get-help-heading {
  margin-top: 4rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.75rem;
}
@media (max-width: 991.98px) {
  .get-help .get-help-heading {
    margin-top: 0.75rem;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .get-help .get-help-lists {
    padding-left: 100px;
  }
}
@media (min-width: 1200px) {
  .get-help .get-help-lists {
    padding-left: 150px;
  }
}

.grow-cards {
  background-color: #082732;
  padding-top: 5rem;
  overflow: visible;
  color: #fff;
}
@media (max-width: 991.98px) {
  .grow-cards {
    padding-top: 3rem;
  }
  .grow-cards .card-container {
    flex-direction: column;
  }
  .grow-cards .card-container .card {
    flex: 1 1 100%;
    transform: none;
    padding-top: 0;
  }
  .grow-cards .card-container .card:hover {
    flex: 1;
  }
  .grow-cards .card-container .card:first-child {
    padding-bottom: 1rem;
  }
  .grow-cards .card-container .card:nth-child(2), .grow-cards .card-container .card:last-child {
    padding-bottom: 8rem;
  }
}
@media (min-width: 992px) {
  .grow-cards {
    padding-top: 5rem;
  }
  .grow-cards .card-container:hover .card:not(:hover) {
    flex: 1;
  }
  .grow-cards .card-container:hover .card:hover {
    flex: 2;
    padding-top: 5rem;
  }
  .grow-cards .card-container:hover .card:first-child:not(:hover) {
    flex: 1;
    padding-top: 3rem;
  }
}
.grow-cards .growing-heading {
  text-align: center;
}
@media (max-width: 991.98px) {
  .grow-cards .growing-heading {
    padding-left: 2rem;
    text-align: left;
  }
}
.grow-cards .card-container {
  display: flex;
  gap: 10px;
  margin: 2rem auto 0;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .grow-cards .card-container {
    margin: 0 auto;
    padding: 0 2rem;
  }
}
.grow-cards .card-container .card {
  box-sizing: border-box;
  flex: 1;
  border-radius: 0.5rem;
  padding: 3rem 2rem;
  height: 500px;
  text-align: start;
  color: #fff;
}
.grow-cards .card-container .card:first-child {
  flex: 2;
  background-color: #0f313b;
  padding-top: 5rem;
  padding-bottom: 8rem;
}
.grow-cards .card-container .card:nth-child(2) {
  background-color: rgba(42, 90, 97, 0.4);
  padding-bottom: 8rem;
}
.grow-cards .card-container .card:last-child {
  background-color: rgba(42, 90, 97, 0.8);
  padding-bottom: 8rem;
}
.grow-cards .card-container .short-bar::after {
  padding-top: 1.5rem;
}

.quotes {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background-color: #082732;
  padding: 5%;
  overflow: visible;
}
@media (max-width: 991.98px) {
  .quotes {
    padding-top: 1.5rem;
  }
}
.quotes .emily {
  margin: auto;
  border-radius: 100px 100px 0 0;
  min-width: 300px;
  height: auto;
  object-fit: cover;
}
@media (min-width: 768px) {
  .quotes .emily {
    flex: 0 1 50%;
    margin-top: 20px;
    margin-right: 1rem;
    max-width: 600px;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  .quotes .emily {
    flex: 0 1 100%;
    margin-top: 20px;
    max-width: 512px;
    height: auto;
  }
}
.quotes .story {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: flex-start;
  margin: auto;
  text-align: left;
}
@media (min-width: 576px) {
  .quotes .story {
    margin-top: 1.5rem;
  }
}
@media (min-width: 768px) {
  .quotes .story {
    flex: 0 0 45%;
  }
}
@media (min-width: 992px) {
  .quotes .story {
    margin-top: 4rem;
  }
}
@media (max-width: 575.98px) {
  .quotes .story {
    flex: 0 1 100%;
    margin-top: 1.5rem;
  }
}
.quotes .story .right-dots {
  position: absolute;
  top: -120%;
  left: 60%;
  overflow: visible;
}
@media (min-width: 1600px) {
  .quotes .story .right-dots {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .quotes .story .right-dots {
    display: none;
  }
}

.testimonials {
  display: flex;
  justify-content: center;
  background-color: #082732;
  padding: 2rem 0;
  overflow: visible;
}
.testimonials .love-ll {
  margin-bottom: 3rem;
}
@media (max-width: 575.98px) {
  .testimonials .love-ll {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    text-align: left;
  }
}
.testimonials .quote-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  justify-content: space-around;
  padding: 0 5%;
  width: 100%;
}
@media (max-width: 991.98px) {
  .testimonials .quote-container {
    flex-direction: column;
    align-items: center;
  }
}

.quote-card {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start;
  border-radius: 0.5rem;
  background-color: #2a5a61;
  padding: 2rem;
  max-width: 335px;
  text-align: start;
}
@media (max-width: 991.98px) {
  .quote-card {
    max-width: 100%;
  }
}
.quote-card .quote-icon {
  margin-bottom: 1rem;
  background-image: url("https://static.learnlux.com/images/icons/quotes-v1.0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.quote-card p {
  flex-grow: 1;
  margin: 0;
}

.bottom-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #082732;
  padding: 2rem 0;
}
@media (max-width: 575.98px) {
  .bottom-banner {
    padding: 1.5rem;
  }
}

.gradient {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto;
  border-radius: 0.5rem;
  background: linear-gradient(to right, #eac63f 0%, #fff 38%);
  width: 80%;
  max-width: 1200px;
  height: 166px;
  color: #2a5a61;
}
@media (max-width: 575.98px) {
  .gradient {
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding-bottom: 2rem;
    width: 100%;
    height: auto;
    text-align: left;
  }
}
.gradient img {
  position: relative;
  margin-right: auto;
  max-width: 300px;
  height: auto;
  object-fit: cover;
}
@media (max-width: 575.98px) {
  .gradient img {
    position: static;
    max-width: 100%;
  }
}
.gradient h3 {
  margin-right: auto;
  padding: 0 1rem;
  font-size: 1.25rem;
}
@media (max-width: 767.98px) {
  .gradient h3 {
    font-size: 1rem;
  }
}
@media (max-width: 575.98px) {
  .gradient h3 {
    margin-left: auto;
    padding: 0 3rem;
  }
}

.outro {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767.98px) {
  .outro {
    padding: 0 3rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
