import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ParamMap } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  combineLatest,
  EMPTY,
  map,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationModule } from '@app/translation/translation.module';
import type { UserData } from '@app/users/user';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserDataService } from '@app/users/user-data.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ProviderStatusService } from '../../provider-status.service';
import { LinkModalContentComponent } from './modal-content/modal-content.component';

interface DismissedStatus {
  canLinkEmail: boolean;
  dismissed: boolean | undefined;
  providerCountLinked: number;
  providerCountTotal: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, TranslationModule ],
  selector: 'lux-link-modal',
  standalone: true,
  template: '<ng-container *ngIf="handleDisplay$ | async" />',
})
export class LinkModalComponent {
  public readonly handleDisplay$: Observable<boolean | undefined>;

  private readonly _dismiss$: Observable<boolean>;
  private readonly _dismissed$: Observable<boolean | undefined>;
  private readonly _dismissedStatus$: Observable<DismissedStatus>;
  private readonly _newUser$: Observable<boolean>; // coming from onboarding

  constructor(
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly providerStatusService: ProviderStatusService,
    private readonly userDataService: UserDataService,
  ) {
    // make the code read declaratively
    this._dismiss$ = this.userDataService.updateUserData({ dismissedfinishprofile: true });

    // make the code read declaratively
    this._dismissed$ = this.userDataService.userData$.pipe(
      map((userData: UserData): boolean => userData.dismissedfinishprofile === true),
    );

    this._dismissedStatus$ = combineLatest([
      this._dismissed$,
      this.providerStatusService.providerCountLinked$,
      this.providerStatusService.providerCountTotal$,
      this.providerStatusService.canLinkEmail$,
    ]).pipe(
      map((
        [ dismissed, providerCountLinked, providerCountTotal, canLinkEmail ]: [ boolean | undefined, number, number, boolean ],
      ): DismissedStatus => (
        {
          canLinkEmail,
          dismissed,
          providerCountLinked,
          providerCountTotal,
        }
      )),
    );

    this._newUser$ = this.route.queryParamMap.pipe(
      map((params: ParamMap): boolean => {
        const previousLocation = params.get('previousLocation') ?? '';
        return previousLocation === 'onboarding';
      }),
    );

    this.handleDisplay$ = combineLatest([
      this._dismissedStatus$,
      this._newUser$,
    ]).pipe(
      switchMap(([ dismissedStatus, newUser ]: [ DismissedStatus, boolean ]): Observable<boolean | undefined> => {
        const { canLinkEmail, dismissed, providerCountLinked, providerCountTotal } = dismissedStatus;
        // While it would be wonderful to make providerCountTotal accurately reflect email
        // the interlocking nature of the mutable index with these configs makes this a
        // better choice temporarily.
        const total = canLinkEmail ? providerCountTotal + 1 : providerCountTotal;
        // >= because only email would be 1 === 0
        if (newUser || dismissed || providerCountLinked >= total) {
          return EMPTY;
        }

        this.modalService.open(LinkModalContentComponent);
        // Only display this modal once in the lifetime of a member
        return this._dismiss$;
      }),
    );
  }
}
