import { Inject, Injectable } from '@angular/core';
import type { ITranslateParams } from '@transifex/native';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { ENV } from '@app/env.provider';
import type { Environment } from '@app/env.provider';

import type { TxNative } from './tx.provider';
import { TX } from './tx.provider';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  /** Emits the current locale when changed */
  public readonly updateTranslations$: Observable<string>;

  private readonly _updateTranslationsSub$: BehaviorSubject<string>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    @Inject(ENV) private readonly env: Environment,
    @Inject(TX) private readonly tx: TxNative,
  ) {
    this._updateTranslationsSub$ = new BehaviorSubject<string>(this.appConfig.locale);

    this.updateTranslations$ = this._updateTranslationsSub$.pipe(distinctUntilChanged());
  }

  /**
   * Converts English Dialects to `en-US`, but otherwise returns the locale parameter unchanged.
   * The Transifex pricing model actually does count other English dialects (e.g. en-IE) against
   * our subscription quota, so we'll need to stop making calls to Transifex for non en-US English
   * dialects OR make sure that all English dialects point to en-US.
   * Note: This assumes that the locale has already been normalized!
   */
  public engDialectToEn(localeId: string): string {
    return this.isEngDialect(localeId) ? 'en-US' : localeId;
  }

  public async initTransifex(): Promise<void> {
    this.tx.init({ token: this.env.transifexToken });
    await this.tx.getLanguages();
    await this.setCurrentLocale(this.appConfig.locale);
  }

  /**
   * Returns true if the locale is English, but not US Country codes.
   * Note: This assumes that the locale has already been normalized!
   */
  public isEngDialect(localeId: string): boolean {
    return localeId.includes('en');
  }

  /**
   * Transifex uses ISO/IEC 15897 formatted locale codes which use underscores
   * @param locale - two letter language code (typically lower case), separator (typically - or _),
   *   two letter country code (typically upper case).
   * @returns - `<lower case language code>_<upper country code>`
   */
  public normalizeLocale(locale: string = 'en_US'): string {
    const [ langCode, countryCode ] = locale.split(/[_.-]/u);

    if (!langCode) {
      throw new Error(`Invalid Locale: '${locale}'`);
    }

    if (countryCode) {
      return `${langCode.toLowerCase()}_${countryCode.toUpperCase()}`;
    }

    return langCode.toLowerCase();
  }

  /**
   * Accepts the Angular formatted locale (two char lower case language code, dash, two char upper
   * case country code) and corrects it for Transifex. Then emits the updated translations Subject.
   */
  public async setCurrentLocale(currentLocale: string): Promise<void> {
    await this.tx.setCurrentLocale(this.normalizeLocale(this.engDialectToEn(currentLocale)));
    this._updateTranslationsSub$.next(currentLocale); // Needs to be the Angular formatted with dashes.
  }

  /**
   * Translate default english text to the current locale
   * @param str - english text to translate
   * @param params - uses the ITranslateParams type: https://developers.transifex.com/docs/angular-sdk#translationservice-service
   *
   * [key: string]: ICU variables
   *
   * _charlimit?: number; Will display a warning in the Translate dashboard if a translation exceeds this number.
   *
   * _context?: string; Warning! Be mindful of how you use contexts.
   * Using different contexts for a string will duplicate source string content because it affects key generation.
   * This can be useful if we may need multiple meanings/translations
   * associated with the same phrase/source string. Otherwise, use with caution.
   *
   * _comment?: string; Displays a message in a "DEVELOPER NOTES" section of the Translate dashboard.
   *
   * _escapeVars?: boolean; If HTML escaping should be applied to ICU variables:
   * Using the translation as is from the t function inside HTML is dangerous for XSS attacks.
   * The translation must be escaped based on two scenarios. Can use escape(t()) or this param.
   * https://github.com/transifex/transifex-javascript/tree/master/packages/native#escaping-translations
   * Angular mostly handles this, unless you set sanitize true!
   *
   * _key?: string; Overrides the hashed source string key Transifex normally generates.
   *
   * _tags?: string; Improves searchability of source strings and translations with a comma separated list of tags.
   *
   * @returns translated string
   */
  public translate(str: string, params: ITranslateParams = {}): string {
    return this.tx.translate(str, params);
  }
}
