import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { OnChanges } from '@angular/core';
import type { ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { combineLatest, map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import { ChartDataService } from '@app/charts/chart-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ChartData, ChartValue } from '@app/charts/chart-data.service';
import { TranslationModule } from '@app/translation/translation.module';
import { TranslationService } from '@app/translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export interface ViewModel {
  chartData: ChartData;
  hasData: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, NgChartsModule, TranslationModule ],
  selector: 'lux-net-worth-chart',
  standalone: true,
  templateUrl: './net-worth-chart.component.html',
})
export class NetWorthChartComponent implements OnChanges {
  /** Total of all asset category accounts current balances. Calucalted by the backend. */
  @Input({ required: true }) public assets!: number;

  /** Total of all liability category accounts current balances. Calucalted by the backend. */
  @Input({ required: true }) public liabilities!: number;

  public readonly chartOptions: ChartOptions;
  public readonly vm$: Observable<ViewModel>;

  // Needs to be a ReplaySubject because ngOnChanges is called before ngOnInit
  private readonly _dataSub$: ReplaySubject<[number, number]>;

  constructor(
    private readonly chartService: ChartDataService,
    private readonly translationService: TranslationService,
  ) {
    this._dataSub$ = new ReplaySubject<[number, number]>(1);

    // Configuration for Chart.js
    this.chartOptions = {
      aspectRatio: 4,
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: '#ffffff',
            font: {
              size: 14,
            },
            padding: 14,
          },
          position: 'right',
        },
        tooltip: {
          callbacks: {
            label: this.chartService.chartLabelCallback,
          },
        },
      },
    };

    this.vm$ = combineLatest([
      this._dataSub$,
      this.translationService.updateTranslations$, // When locale changes this will cause the labels to be re-translated.
    ]).pipe(
      map(([ [ assets, liabilities ], _ ]: [ [ number, number ], string ]): ViewModel => {
        const chartData = this._updateChart(assets, liabilities);
        const hasData = chartData.datasets[0] != undefined && chartData.datasets[0].data.length > 0;

        return { chartData, hasData };
      }),
    );
  }

  public ngOnChanges(): void {
    this._dataSub$.next([ this.assets, this.liabilities ]);
  }

  private _updateChart(assets: number, liabilities: number): ChartData {
    const values: ChartValue[] = [
      {
        // Negative liabilities are actually an asset for the member.
        // For example, -$10 means the credit card company owes them $10.
        amount: liabilities < 0 ? assets - liabilities : assets,
        color: '#29a59a', // LearnLux Green
        name: this.translationService.translate(
          'Assets',
          { _key: 'accounts.category.assets', _comment: 'Chart label' },
        ),
      },
      {
        amount: liabilities,
        color: '#eac63f', // Ronchi Yellow
        name: this.translationService.translate(
          'Liabilities',
          { _key: 'accounts.category.liabilities', _comment: 'Chart label' },
        ),
      },
    ];

    return this.chartService.updateChart(values);
  }
}
