/**
 * Firebase only has one URL for the three different emails it can send out. So this page is
 * specifically to identify the action (mode) being taken by the unauthenticated visitor and
 * redirect them to out specific Component for that action.
 */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ParamMap } from '@angular/router';
import { ignoreElements, map, tap } from 'rxjs';
import type { Observable } from 'rxjs';

import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';

interface AuthActionState {
  actionCode: string;
  mode: string;
  nextUrl?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, SpinnerComponent ],
  selector: 'lux-auth-actions',
  standalone: true,
  template: '<lux-spinner *ngIf="(vm$ | async) === null" />',
})
export class ActionsComponent {
  public readonly vm$: Observable<AuthActionState>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.vm$ = this.route.queryParamMap.pipe(
      map((params: ParamMap): AuthActionState => {
        const mode = params.get('mode') ?? '';
        if (!mode) {
          console.error('ActionsComponent#vm$', 'Missing Auth Actions mode');
        }

        const actionCode = params.get('oobCode') ?? '';
        if (!actionCode) {
          console.error('ActionsComponent#vm$', 'Missing Auth Actions oobCode');
        }

        let nextUrl: string | undefined;
        const continueUrl: string | null = params.get('continueUrl');
        if (continueUrl) {
          // Convert fully qualified URL to an absolute URL
          const url = new URL(continueUrl);
          nextUrl = `${url.pathname}${url.search}${url.hash}`;
        }

        // There is also lang:
        // https://firebase.google.com/docs/auth/custom-email-handler

        return { actionCode, mode, nextUrl };
      }),
      tap((state: AuthActionState): void => {
        const modePaths: Record<string, string> = {
          recoverEmail: '/recover-email',
          resetPassword: '/reset-password',
          verifyAndChangeEmail: '/confirm-email',
          verifyEmail: '/confirm-email',
        };
        const path = modePaths[state.mode] ?? '/'; // When query params are missing redirect to default, which is login

        this.router.navigateByUrl(path, { replaceUrl: true, state })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((err: unknown): void => {
            console.error('ActionsComponent#vm$#NavigateOnError', err);
          });
      }),
      // Using AsyncPipe in the template to subscribe to this Observable, but don't actually emit
      // anything so that the spinner stays until the redirect happens.
      ignoreElements(),
    );
  }
}
