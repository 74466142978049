/**
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
import { CommonModule, Location } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { FirebaseError } from '@angular/fire/app';
import { Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  catchError,
  map,
  of,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';
import { modelNotFound } from '@app/utilities/rxjs-model-not-found';

import { getActionState } from '../action-state';
import type { AuthActionState } from '../action-state';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EmailVerificationService } from '../email-verification.service';

export interface ViewModel {
  errorCode: string;
  nextUrl: string;
  validCode: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ErrorMessageComponent,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-confirm-email',
  standalone: true,
  templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly emailVerifyService: EmailVerificationService,
    private readonly location: Location,
    private readonly router: Router,
  ) {
    this.vm$ = of(this.location.getState()).pipe(
      map(getActionState),
      modelNotFound(this.router),
      switchMap(
        (state: AuthActionState): Observable<ViewModel> => this.emailVerifyService.handleChangeEmailCode(state.actionCode).pipe(
          map((): ViewModel => ({ errorCode: '', nextUrl: state.nextUrl, validCode: true })),
          catchError((err: unknown): Observable<ViewModel> => this._handleError(err)),
        ),
      ),
    );
  }

  /**
   * Possible errors:
   * auth/expired-action-code
   * auth/invalid-action-code
   * auth/user-disabled
   * auth/user-not-found
   *
   * @param err - The error to handle.
   * @returns Observable that emits the error state view model to display.
   */
  private _handleError(err: unknown): Observable<ViewModel> {
    console.error('ConfirmEmailComponent#EmailVerificationService#handleChangeEmailCode Error', err);
    if (err instanceof Error) {
      const someErr = err as Error | FirebaseError;
      return of({
        errorCode: 'code' in someErr ? someErr.code : someErr.message,
        nextUrl: '/',
        validCode: false,
      });
    }
    throw new Error('Unknown Error Occurred.');
  }
}
