import { distinctUntilChanged } from 'rxjs';
import type { MonoTypeOperatorFunction } from 'rxjs';

type DistinctCompare = <T, K extends keyof T>(k: K, x: T[K], y: T[K]) => boolean;

const defaultCompare: DistinctCompare = <T, K extends keyof T>(_k: K, x: T[K], y: T[K]): boolean => x === y;

/**
 * Returns an Observable that emits all items emitted by the source Observable that are distinct by
 * comparison from the previous item, using properties accessed by using the keys provided to check
 * if the two items are distinct.
 *
 * `compare` should return `true` when the values are the same. If `compare` returns false then emit.
 *
 * Based on: https://stackoverflow.com/a/45356905
 */
export const distinctUntilKeysChanged = <T, K extends keyof T>(
  keys: K[],
  compare: DistinctCompare = defaultCompare,
): MonoTypeOperatorFunction<T> => {
  return distinctUntilChanged((x: T, y: T): boolean => {
    // Negate the `compare` function return so that if it finds a change (returns `false`) the
    // `Array#some` will stop and return `true`; which is then negated so the `distinctUntilChanged`
    // will emit.
    return !keys.some((k: K): boolean => !compare(k, x[k], y[k]));
  });
};
