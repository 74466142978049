import { inject } from '@angular/core';
import { Router } from '@angular/router';
import type { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, take } from 'rxjs';
import type { Observable } from 'rxjs';

import { AuthService } from '../auth.service';

/**
 * Guard for canActivate and canActivateChild routes.
 */
export const activateAuthGuard = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<UrlTree | boolean> => {
  return loginRequired(state.url);
};

// This guard isn't currently used, but could be useful in the future, so yes SonarCloud
// I am keeping this commented out code around.
// /**
//  * Guard for canMatch routes.
//  */
// export const matchAuthGuard = (): Observable<UrlTree | boolean> => {
//   const router = inject(Router);
//   // There doesn't seem to be a good way to get a nice URL string from the parameters of canLoad.
//   // So this method will attempt to get a URL, but if it cannot then default to the root path.
//   const nav = router.getCurrentNavigation();
//   const url = nav ? nav.extractedUrl.toString() : '/';
//
//   return loginRequired(url);
// };

const loginRequired = (url: string): Observable<UrlTree | boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn$.pipe(
    take(1), // RouterGuards that return observables must complete!
    map((loggedIn: boolean): UrlTree | boolean => {
      if (loggedIn) {
        return true;
      }

      // Store the attempted URL for redirecting
      authService.redirectUrl = url;

      // Navigate to the login page
      return router.parseUrl('login');
    }),
  );
};
