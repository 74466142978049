import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  finalize,
  map,
  take,
  timer,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationModule } from '@app/translation/translation.module';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';
import { tapOnce } from '@app/utilities/rxjs-tap-once';

import { BouncerComponent } from '../bouncer/bouncer.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ BouncerComponent, CommonModule, TranslationModule ],
  selector: 'lux-onboarding-calculating',
  standalone: true,
  styleUrls: [ './calculating.component.scss' ],
  templateUrl: './calculating.component.html',
})
export class CalculatingComponent {
  public readonly messages$: Observable<string>;

  private readonly _messages: readonly string[] = [
    'profile',
    'lessons',
    'recommendations',
    '', // Message that doesn't really appear during the finalize navigation
  ] as const;

  constructor(
    private readonly router: Router,
    private readonly userTrackingService: UserTrackingService,
  ) {
    // Display new message every 2 seconds up to the number of _messages in array
    this.messages$ = timer(0, 2000).pipe(
      take(this._messages.length),
      tapOnce((): void => {
        this.userTrackingService.trackEvent(EVENTS.onboardingCompleted);
      }),
      map((tick: number): string => this._messages[tick] ?? ''),
      finalize((): void => {
        this.router.navigate([ '/dashboard' ], {
          queryParams: {
            previousLocation: 'onboarding',
          },
          replaceUrl: true,
        })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((err: unknown): void => {
            console.error('CalculatingComponent#messages$#finalize', err);
          });
      }),
    );
  }
}
