import type { Router } from '@angular/router';
import {
  catchError,
  EMPTY,
  from,
  switchMap,
} from 'rxjs';
import type { Observable, OperatorFunction } from 'rxjs';

/**
 * Tells the router to display the PageNotFound Component instead of the current routed view.
 * Completes the source Observable to prevent further emissions.
 */
export const modelNotFound = <T>(router: Router): OperatorFunction<T, T> => {
  return catchError((): Observable<never> =>
    // This path does not exist and so triggers the wildcard ('**') route.
    from(router.navigateByUrl('/pageNotFound', { skipLocationChange: true }))
      .pipe(switchMap((): Observable<never> => EMPTY)));
};
