import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FirebaseError } from '@angular/fire/app';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ParamMap } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  map,
  mergeMap,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import type { SelfSignupConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { DirtyErrorsPipe } from '@app/pipes/dirty-errors/dirty-errors.pipe';
import { InvalidFormPipe } from '@app/pipes/invalid-form/invalid-form.pipe';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { SessionStorageService } from '@app/storage/session-storage.service';
import { AutofocusDirective } from '@app/stuff/autofocus/autofocus.directive';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';
import type { FormUserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

import { CountryDropdownComponent } from '../country-dropdown/country-dropdown.component';
import { EmailPassLoginService } from '../email-pass-login.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { PasswordStrengthComponent } from '../password-strength/password-strength.component';
import { emailDomains } from '../validators/email-domains.validator';
import { passwordsMatch, passwordStrength } from '../validators/password.validator';

interface FormValues {
  email: string;
  password1: string;
}

interface JoinForm {
  authForm: FormGroup;
  emailCntrl: FormControl;
  pass1Cntrl: FormControl;
  pass2Cntrl: FormControl;
}

interface ViewModelSubject {
  errorCode: string;
  showForm: boolean;
}

export interface ViewModel extends JoinForm, ViewModelSubject {
  joinEnabled: boolean;
  minPasswordLen: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutofocusDirective,
    CommonModule,
    CountryDropdownComponent,
    DirtyErrorsPipe,
    ErrorMessageComponent,
    InvalidFormPipe,
    PasswordStrengthComponent,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-join',
  standalone: true,
  styles: [ '.org-logo { height: 34px; }' ],
  templateUrl: './join.component.html',
})
export class JoinComponent {
  public readonly logo?: string;
  public readonly organization: string;
  public readonly vm$: Observable<ViewModel>;

  private readonly _submitSub$: Subject<FormValues>;
  private readonly _vmSub$: BehaviorSubject<ViewModelSubject>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly clientConfig: ClientConfigService,
    private readonly emailLoginService: EmailPassLoginService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly storage: SessionStorageService,
    private readonly userService: UserDataService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this._submitSub$ = new Subject<FormValues>();
    this._vmSub$ = new BehaviorSubject<ViewModelSubject>({
      errorCode: '',
      showForm: true,
    });

    this.logo = this.appConfig.clientLogo;
    this.organization = this.appConfig.organization;

    this._submitSub$.pipe(
      switchMap(
        ({ email, password1 }: FormValues): Observable<boolean> =>
          this.emailLoginService.register(email, password1).pipe(
            tap((): void => {
              this.userTrackingService.trackEvent(EVENTS.authUserCreated, { sso: false });
            }),
            // We could special case 'auth/email-already-exists' error here.
            catchError((err: unknown): Observable<never> => {
              if (err instanceof Error) {
                this._handleError(err);
              }
              return EMPTY;
            }),
          ),
      ),
      mergeMap((): Observable<boolean> => this._handleTasks()),
      switchMap((): Observable<boolean> =>
        this.clientConfig.selfSignupConfig$.pipe(map((config: SelfSignupConfig): boolean => {
          for (const provider of config.providers) {
            if (provider.emailLinkingEnabled) {
              return true;
            }
          }
          return false;
        }))),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => { console.error('JoinComponent#subscribe#error', err); },
      next: (emailLinkingEnabled: boolean): void => {
        const nextUrl = emailLinkingEnabled ? '/link-external' : '/start';
        this.router.navigateByUrl(nextUrl)
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((err: unknown): void => {
            console.error('JoinComponent#subscribe#navigateByUrl', err);
          });
      },
    });

    this.route.queryParamMap
      .pipe(takeUntilDestroyed())
      .subscribe({
        error: (err: unknown): void => { console.error('JoinComponent#queryParamMap', err); },
        next: (params: ParamMap): void => { this._storeParams(params); },
      });

    this.vm$ = combineLatest([
      this.clientConfig.selfSignupConfig$,
      this._vmSub$,
    ]).pipe(
      map(([ selfSignupConfig, { errorCode, showForm } ]: [ SelfSignupConfig, ViewModelSubject ]): ViewModel => ({
        ...this._createForm(selfSignupConfig),
        errorCode,
        joinEnabled: selfSignupConfig.enabled,
        minPasswordLen: selfSignupConfig.password.minLength,
        showForm,
      })),
    );
  }

  public onSubmit(authForm: FormGroup): void {
    if (authForm.invalid) {
      throw new Error('Form Invalid');
    }

    this._vmSub$.next({
      errorCode: '', // Hide any existing error message.
      showForm: false, // Hide form to prevent multiple submission.
    });

    const { email, password1 } = authForm.value as FormValues;

    this._submitSub$.next({ email, password1 });
  }

  private _createForm(config: SelfSignupConfig): JoinForm {
    const emailValidators = [ Validators.required, Validators.email ];

    if (config.allowedDomains.length > 0) {
      emailValidators.push(emailDomains(config.allowedDomains));
    }

    const emailCntrl = new FormControl('', emailValidators);
    const pass1Cntrl = new FormControl('', [
      Validators.required,
      Validators.minLength(config.password.minLength),
      passwordStrength(config.password.minStrength),
    ]);
    const pass2Cntrl = new FormControl('', [ Validators.required, Validators.minLength(config.password.minLength) ]);

    const authForm = new FormGroup(
      {
        email: emailCntrl,
        password1: pass1Cntrl,
        password2: pass2Cntrl,
      },
      { validators: passwordsMatch('password1', 'password2') },
    );

    return {
      authForm,
      emailCntrl,
      pass1Cntrl,
      pass2Cntrl,
    };
  }

  private _handleError(err: Error | FirebaseError): void {
    console.error('Auth Register Error', err);

    this._vmSub$.next({
      errorCode: 'code' in err ? err.code : err.message,
      showForm: true,
    });
  }

  private _handleTasks(): Observable<boolean> {
    const updates: FormUserData = {
      employername: this.organization,
    };

    // Get attribution key from session storage.
    const attribution = this.storage.get('attribution');

    // If provided, save to the new user object.
    if (attribution) {
      updates.attribution = attribution;
    }

    return this.userService.updateUserData(updates);
  }

  /** Store the 'src' parameter to session store if it was provided in the inbound url. */
  private _storeParams(params: ParamMap): void {
    const src = params.get('src');

    if (src) {
      this.storage.set('attribution', src);
    }
  }
}
