import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

import { ChartDataService } from '@app/charts/chart-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ChartData, ChartValue } from '@app/charts/chart-data.service';
import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';
import { TranslationService } from '@app/translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { UserData, UserDataComputed } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

const MONTHS_PER_YEAR = 12;

export interface ViewModel {
  chartData: ChartData;
  hasData: boolean;
}

interface ChartValueGlobal extends ChartValue {
  hide?: boolean; // Should this chart value be ignored? Used to exlude U.S. specific elements if Generic Budget is enabled.
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgChartsModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-budget-chart',
  standalone: true,
  styleUrls: [ './budget-chart.component.scss' ],
  templateUrl: './budget-chart.component.html',
})
export class BudgetChartComponent {
  public readonly chartOptions: ChartOptions;
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly chartService: ChartDataService,
    private readonly dataService: UserDataService,
    private readonly translationService: TranslationService,
  ) {
    // Configuration for Chart.js
    this.chartOptions = {
      aspectRatio: 3,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'left',
        },
        tooltip: {
          callbacks: {
            label: this.chartService.chartLabelCallback,
          },
        },
      },
    };

    this.vm$ = combineLatest([
      // Is this app currently running the generic version of budget? If so, we pass this data into our array of fields, then filter the
      // list based on which categories should be available on the given app.
      this.clientConfig.featuresConfig$.pipe(map((featuresConfig: FeaturesConfig): boolean => featuresConfig.budget === 'generic')),
      this.dataService.userData$,
      this.translationService.updateTranslations$, // When locale changes this will cause the labels to be re-translated.
    ]).pipe(
      map(
        ([ budgetGenericEnabled, userData, _ ]: [ boolean, UserData, string ]): ViewModel => {
          const chartData = this._updateChart(budgetGenericEnabled, userData.computed);
          const hasData = chartData.datasets[0] != undefined && chartData.datasets[0].data.length > 0;

          return { chartData, hasData };
        },
      ),
    );
  }

  private _updateChart(budgetGenericEnabled: boolean, computed: UserDataComputed): ChartData {
    let values: ChartValueGlobal[] = [

      {
        amount: computed.taxtotal / MONTHS_PER_YEAR,
        color: '#eac63f', // Ronchi Yellow
        hide: budgetGenericEnabled,
        name: this.translationService.translate(
          'Taxes',
          { _key: 'text.taxes', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlyinsurance,
        color: '#adb5bd',
        hide: budgetGenericEnabled,
        name: this.translationService.translate(
          'Insurance',
          { _key: 'budget.list.header-insurance-general', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlyretirement,
        color: '#29a59a', // Primary Green
        hide: budgetGenericEnabled,
        name: this.translationService.translate(
          'Retirement',
          { _key: 'text.retirement', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlyexpenses,
        color: '#ff9786',
        name: this.translationService.translate(
          'Expenses',
          { _key: 'budget.list.header-expenses', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlydebt,
        color: '#f45253', // Primary Red
        name: this.translationService.translate(
          'Debt',
          { _key: 'budget.list.header-debt', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlysavings,
        color: '#06c7a9', // Teal / Money Green
        name: this.translationService.translate(
          'Savings',
          { _key: 'budget.list.header-savings', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        amount: computed.totalmonthlyinvestments,
        color: '#e2f9f6',
        hide: budgetGenericEnabled,
        name: this.translationService.translate(
          'Investments',
          { _key: 'budget.list.header-investments', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
      {
        // Remaining could be negative number, and will then be skipped from the chart.
        // Chart.js would display the absolute value as a slice of the pie, which isn't accurate and informative.
        amount: budgetGenericEnabled ? (computed.totalincome / 12) - computed.monthlybudget : computed.monthlynet,
        color: '#c8cfd1', // gray-200
        name: this.translationService.translate(
          'Monthly left',
          { _key: 'budget.budget-chart.labels-remaining', _comment: 'Appears on hover', _context: 'Pie Chart Label' },
        ),
      },
    ];

    // Filter the list of possible chart values in order to exlude items that
    // are U.S. specific should the Generic Budget feature be enabled.
    values = values.filter((value: ChartValueGlobal): boolean => !value.hide);

    return this.chartService.updateChart(values);
  }
}
