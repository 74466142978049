import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

import type { BannerConfig, BannerConfigOrUndef } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { SessionStorageService } from '@app/storage/session-storage.service';
import { TranslationModule } from '@app/translation/translation.module';

export interface ViewModel {
  readonly content: string;
  readonly dismissable: boolean;
  readonly show: boolean;
  readonly translationKeys?: {
    readonly content: string;
  };
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Apply styles to the innerHTML content as well
  encapsulation: ViewEncapsulation.None, // eslint-disable-line @angular-eslint/use-component-view-encapsulation
  imports: [ CommonModule, TranslationModule ],
  selector: 'lux-banner',
  standalone: true,
  styleUrls: [ './banner.component.scss' ],
  templateUrl: './banner.component.html',
})
export class BannerComponent {
  public readonly vm$: Observable<ViewModel>;

  private readonly _showSub$: BehaviorSubject<boolean>;

  constructor(
    private readonly clientConfigService: ClientConfigService,
    private readonly http: HttpClient,
    // Use LocalStorageService to persist this close across sessions.
    private readonly storage: SessionStorageService,
  ) {
    this._showSub$ = new BehaviorSubject<boolean>(true);

    this.vm$ = combineLatest([
      this._showSub$,
      this.http.get<BannerConfig>('assets/banner/default.json'),
      this.clientConfigService.bannerConfig$,
    ]).pipe(
      map(([ showVal, defaultConfig, clientConfig ]: [ boolean, BannerConfig, BannerConfigOrUndef ]): ViewModel => {
        const config = clientConfig ?? defaultConfig;
        // If showVal is false: then show is false.
        // Else if config.hide is true: then show is false.
        // Else if storage says this banner has been previously closed: then show is false.
        // Else: show is true.
        const show = showVal && !config.hide && this.storage.get('lux-banner') !== config.msgVersion;
        // Remember that this banner was manually closed for later visits within this session.
        if (!showVal) {
          this.storage.set('lux-banner', config.msgVersion);
        }
        return {
          content: config.content,
          // Handle configs without this value. Default to dismissable unless specified.
          // The database doesn't actually always have this field so backfill it and keep the type checker happy.
          dismissable: config.dismissable !== false, // eslint-disable-line @typescript-eslint/no-unnecessary-boolean-literal-compare
          show,
          translationKeys: config.translationKeys,
        };
      }),
    );
  }

  /**
   * Close the banner, and record the current message version in storage so the marquee remains
   * closed next load.
   */
  public close(): void {
    this._showSub$.next(false);
  }
}
