// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin: 0 auto 1rem;
  border-bottom: 1px solid #f1f3f3;
  max-width: 400px;
  height: 174px;
  text-align: center;
}

svg {
  width: 80px;
  height: 80px;
  animation: bungee 4s ease-in-out infinite;
}
svg .chart {
  fill: #29a59a;
}

@keyframes bungee {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(90px);
  }
  100% {
    transform: translateY(0);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
