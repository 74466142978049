import { Injectable } from '@angular/core';
import {
  from,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { CollectionReference, DocumentReference } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from './user-tracking.service';

interface UserSuggestionBase {
  id?: string;

  createdAt: Date | FirestoreTimestamp;
  favorited: boolean;
  lastModified: Date | FirestoreTimestamp;
  priority: number; // 0 is highest priority, should be like an index and used to order suggestions. Can have duplicates
  referenceKey: string; // CheckupItem key when type is Checkup
  remindAt?: Date | FirestoreTimestamp;
  status: 'dismissed' | 'done' | 'inprogress';
  type: 'Advisor' | 'Budget' | 'Checkup';
}

export interface UserSuggestion extends UserSuggestionBase {
  createdAt: Date;
  lastModified: Date;
  remindAt?: Date;
}

// Should only be used for testing!
interface UserSuggestionFirestore extends UserSuggestionBase {
  createdAt: FirestoreTimestamp;
  lastModified: FirestoreTimestamp;
  remindAt?: FirestoreTimestamp;
}

const createdAtSort = (a: UserSuggestion, b: UserSuggestion): number => b.createdAt.getTime() - a.createdAt.getTime();

@Injectable({ providedIn: 'root' })
export class UserSuggestionsService {
  /** All user's suggested task completed and incomplete without order */
  public readonly allSuggestions$: Observable<UserSuggestion[]>;
  /** All suggestions marked 'done' status sorted by lastModified descending. */
  public readonly completedSuggestions$: Observable<UserSuggestion[]>;
  /** All suggestions marked 'inprogress' status sorted by assending priority and lastModified descending. */
  public readonly incompleteSuggestions$: Observable<UserSuggestion[]>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this.allSuggestions$ = this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<UserSuggestionFirestore> =>
          this.afStore.collection<UserSuggestionFirestore>(`users/${user.uid}/suggestions`),
      ),
      switchMap(
        (collRef: CollectionReference<UserSuggestionFirestore>): Observable<UserSuggestionFirestore[]> =>
          this.afStore.collectionData(collRef, { idField: 'id' }), // Need the IDs
      ),
      // Format Dates
      map((rawSuggestions: UserSuggestionFirestore[]): UserSuggestion[] => rawSuggestions.map(
        (raw: UserSuggestionFirestore): UserSuggestion => ({
          ...raw,
          createdAt: raw.createdAt.toDate(),
          lastModified: raw.lastModified.toDate(),
          remindAt: raw.remindAt?.toDate(),
        }),
      )),
      // Sort Suggestions by Date Created (Newest to Oldest). Mutates Array!
      tap((allSuggestions: UserSuggestion[]): void => { allSuggestions.sort(createdAtSort); }),
      // Prevent Duplicate Suggestions
      map(
        (allSuggestions: UserSuggestion[]): UserSuggestion[] => {
          // List of checkup keys for which the user has a suggestion (of any status).
          const checkupItemKeys: string[] = [];

          // For each suggestion
          const uniqueSuggestions = allSuggestions.filter((sug: UserSuggestion): boolean => {
            // If the suggestion is for a checkup item.
            if (sug.referenceKey) {
              // If the suggestions list already contains a more recent suggestion for the same checkup
              // item key, filter out the older suggestion.
              if (checkupItemKeys.includes(sug.referenceKey)) {
                return false;
              }

              // If the suggestion list does NOT yet contain a suggestion for the given key, add the
              // key to the list to check, and keep the suggestion.
              checkupItemKeys.push(sug.referenceKey);
              return true;
            }

            // If the suggestion is not for a checkup item (currently unsupported feature).
            return true;
          });

          uniqueSuggestions.reverse(); // Re-sort the suggestions from Oldest to Newest.

          return uniqueSuggestions;
        },
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.completedSuggestions$ = this.allSuggestions$.pipe(
      map(
        (allSuggestions: UserSuggestion[]): UserSuggestion[] => allSuggestions.filter(
          (suggestion: UserSuggestion): boolean => suggestion.status === 'done',
        ),
      ),
    );

    this.incompleteSuggestions$ = this.allSuggestions$.pipe(
      map(
        (allSuggestions: UserSuggestion[]): UserSuggestion[] => allSuggestions.filter(
          (suggestion: UserSuggestion): boolean => suggestion.status === 'inprogress',
        ),
      ),
    );
  }

  /**
   * Create a new suggestion for the user with the status inprogress
   * - type: should be 'Checkup' (for now)
   * - referenceKey: should be the key for the CheckupItem this suggestion is from
   * - priority: should be a number (0 highest priority)
   */
  public addSuggestion(type: UserSuggestionBase['type'], referenceKey: string, priority: number): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<UserSuggestionFirestore> =>
          this.afStore.collection<UserSuggestionFirestore>(`users/${user.uid}/suggestions`),
      ),
      switchMap((collRef: CollectionReference<UserSuggestionFirestore>): Observable<boolean> => {
        const nowTs = FirestoreTimestamp.now();

        const payload: UserSuggestionFirestore = {
          createdAt: nowTs,
          favorited: false,
          lastModified: nowTs,
          priority,
          referenceKey,
          status: 'inprogress',
          type,
        };

        const addPromise = this.afStore.addDoc(collRef, payload);
        return from(addPromise).pipe(
          tap((doc: DocumentReference<UserSuggestionFirestore>): void => {
            this.userTrackingService.trackEvent(EVENTS.suggestionsSuggestionCreated, {
              id: doc.id,
              ...payload,
              createdAt: payload.createdAt.valueOf(),
              lastModified: payload.lastModified.valueOf(),
              remindAt: payload.remindAt?.valueOf(),
            });
          }),
          map((): boolean => true),
        );
      }),
    );
  }

  /**
   * Favorite an existing user suggestion. Updates the Suggestion lastModified.
   * If the id doesn't exist then firebase will throw an error.
   */
  public favoriteSuggestion(id: string, favorited: boolean): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<UserSuggestionFirestore> =>
          this.afStore.doc<UserSuggestionFirestore>(`users/${user.uid}/suggestions/${id}`),
      ),
      switchMap((docRef: DocumentReference<UserSuggestionFirestore>): Observable<boolean> => {
        const lastModified = FirestoreTimestamp.now();
        const updatePromise = this.afStore.updateDoc(docRef, { favorited, lastModified });
        return from(updatePromise).pipe(map((): boolean => true));
      }),
    );
  }

  /** Fetch a specific suggestion by the ID. */
  public getSuggestionById(id: string): Observable<UserSuggestion> {
    return this.allSuggestions$.pipe(
      map((suggestions: UserSuggestion[]): UserSuggestion => {
        const [ suggestion, ...duplicates ] = suggestions.filter((s: UserSuggestion): boolean => s.id === id);

        if (duplicates.length > 0) {
          throw new Error(`Duplicate '${id}' UserSuggestion found.`);
        }

        if (suggestion == undefined) {
          throw new Error(`Suggestion '${id}' not found.`);
        }

        return suggestion;
      }),
    );
  }

  /**
   * Set a reminder for an existing user suggestion.
   * If the id doesn't exist then firebase will throw an error.
   */
  public setReminder(id: string, reminderDate: Date): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<UserSuggestionFirestore> =>
          this.afStore.doc<UserSuggestionFirestore>(`users/${user.uid}/suggestions/${id}`),
      ),
      switchMap((docRef: DocumentReference<UserSuggestionFirestore>): Observable<boolean> => {
        const updatePromise = this.afStore.updateDoc(docRef, { remindAt: FirestoreTimestamp.fromDate(reminderDate) });
        return from(updatePromise).pipe(map((): boolean => true));
      }),
    );
  }

  /**
   * Change the status of an existing user suggestion. Updates the Suggestion lastModified.
   * If the id doesn't exist then firebase will throw an error.
   */
  public setStatus(id: string, status: UserSuggestionBase['status']): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<UserSuggestionFirestore> =>
          this.afStore.doc<UserSuggestionFirestore>(`users/${user.uid}/suggestions/${id}`),
      ),
      switchMap((docRef: DocumentReference<UserSuggestionFirestore>): Observable<boolean> => {
        const lastModified = FirestoreTimestamp.now();
        const updatePromise = this.afStore.updateDoc(docRef, { lastModified, status });
        return from(updatePromise).pipe(map((): boolean => true));
      }),
    );
  }
}
