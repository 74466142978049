// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #2f3638;
}

.support-wrapper {
  border-top: 1px solid #eac63f;
  background-color: #fff;
}
.support-wrapper .btn {
  --bs-btn-font-size: 1rem;
  border: none;
  padding: 0 1.5rem;
}

.container {
  --bs-gutter-x: 3.5rem;
  padding-top: 1.6rem;
  padding-bottom: 1rem;
  color: #9faaae;
  font-size: 0.9rem;
}
.container a {
  font-weight: 700;
}

.disclaimer {
  margin-bottom: 2rem;
  max-width: 900px;
  color: #5e6b6f;
  font-size: 0.875rem;
}

.policies a + a {
  padding-left: 1rem;
}

@media (min-width: 768px) {
  .footer-nav {
    display: flex;
  }
  .copyright {
    flex: 1;
  }
}
:host-context(.onboarding) :host,
:host-context(.lessons-viewer-component) :host {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
