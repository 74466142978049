import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ReplaySubject, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserSuggestionsService } from '@app/users/user-suggestions.service';
import type { UserSuggestion } from '@app/users/user-suggestions.service';

import { SuggestionItemComponent } from '../../suggestion-item/suggestion-item.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SpinnerComponent,
    SuggestionItemComponent,
    TranslationModule,
  ],
  selector: 'lux-suggestion-preview-modal',
  standalone: true,
  styleUrls: [ './preview-modal.component.scss' ],
  templateUrl: './preview-modal.component.html',
})
export class SuggestionPreviewModalComponent {
  public readonly suggestion$: Observable<UserSuggestion>;

  private readonly _suggestionIdSub$: ReplaySubject<string>;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly service: UserSuggestionsService,
  ) {
    this._suggestionIdSub$ = new ReplaySubject<string>(1);

    this.suggestion$ = this._suggestionIdSub$.pipe(
      switchMap((id: string): Observable<UserSuggestion> => this.service.getSuggestionById(id)),
    );
  }

  @Input({ required: true }) public set suggestionId(id: string) {
    this._suggestionIdSub$.next(id);
  }
}
