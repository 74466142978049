import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {
  DEFAULT_CURRENCY_CODE,
  Inject,
  Injectable,
  LOCALE_ID,
  Pipe,
} from '@angular/core';
import type { PipeTransform } from '@angular/core';

type CurrencyFormat = number | string | null | undefined;

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'luxCurrency', standalone: true })
export class DefaultCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private readonly localeId: string,
    @Inject(DEFAULT_CURRENCY_CODE) private readonly currencyCode: string,
  ) {}

  /** Get currency format. */
  public getFormat = (value: CurrencyFormat, numberFormat: string = '1.0-0'): string | null => {
    if (!isValue(value)) {
      return null; // eslint-disable-line unicorn/no-null
    }

    const currency = this.getSymbol();
    const num = strToNumber(value);
    return formatCurrency(num, this.localeId, currency, this.currencyCode, numberFormat);
  };

  /** Get the currency symbol */
  public getSymbol = (): string => {
    return getCurrencySymbol(this.currencyCode, 'narrow', this.localeId);
  };

  public transform(value: CurrencyFormat, numberFormat: string = '1.0-0'): string | null {
    return this.getFormat(value, numberFormat);
  }
}

/**
 * Checks that value is defined, and not an empty string and not NaN.
 * https://github.com/angular/angular/blob/7e6fc10a0dd36616226d480fe12eff99f01d9031/packages/common/src/pipes/number_pipe.ts#L299
 */
export const isValue = (value: CurrencyFormat): value is number | string => {
  return !(value == undefined || value === '' || Number.isNaN(value));
};

/**
 * Transforms a string into a number (if needed).
 * https://github.com/angular/angular/blob/7e6fc10a0dd36616226d480fe12eff99f01d9031/packages/common/src/pipes/number_pipe.ts#L306
 */
export const strToNumber = (value: number | string): number => {
  // Convert strings to numbers
  if (typeof value === 'string' && !Number.isNaN(Number(value) - Number.parseFloat(value))) {
    return Number(value);
  }

  if (typeof value !== 'number') {
    throw new TypeError(`${value} is not a number`);
  }

  return value;
};
