/**
 * A service that handles email verification actions.
 */
import { Injectable } from '@angular/core';
import {
  catchError,
  firstValueFrom,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService, FirebaseActionCodeInfo } from '../angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '../angular-fire-shims/angular-fire-functions.service';

interface EmailVerifiedResponse {
  result: string | null;
}

@Injectable({ providedIn: 'root' })
export class EmailVerificationService {
  constructor(
    private readonly afFns: AngularFireFunctionsService,
    private readonly afAuth: AngularFireAuthService,
  ) {}

  /**
   * Applies an email verification action code with a side effect of calling the
   * userEmailVerified Firebase Function. This does not wait for userEmailVerified to finish.
   *
   * @param actionCode - The action code to apply.
   * @returns An observable that emits the FirebaseActionCodeInfo once the action code has been applied.
   */
  public handleChangeEmailCode(actionCode: string): Observable<FirebaseActionCodeInfo> {
    return from(this.afAuth.checkActionCode(actionCode)).pipe(
      switchMap(
        (codeInfo: FirebaseActionCodeInfo): Observable<FirebaseActionCodeInfo> => from(this.afAuth.applyActionCode(actionCode)).pipe(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          tap(async (): Promise<EmailVerifiedResponse> => firstValueFrom(this._userEmailVerified(codeInfo.data.email))),
          map((): FirebaseActionCodeInfo => codeInfo),
        ),
      ),
    );
  }

  /**
   * Calls the userEmailVerified Firebase Function.
   * Since this is a side effect it catchs it's own errors to not affect the parent observable.
   *
   * @param email - The email to change from.
   * @returns An observable that completes when the request finishes.
   */
  private _userEmailVerified(email: string | null | undefined): Observable<EmailVerifiedResponse> {
    /* eslint-disable unicorn/no-null */
    const userEmailVerifiedFunc = this.afFns.httpsCallable<string | null, EmailVerifiedResponse>('userEmailVerified');
    return userEmailVerifiedFunc(email ?? null).pipe(
      catchError((): Observable<EmailVerifiedResponse> => {
        console.error('AuthService#handleVerifyEmailCode#changeUserEmail');
        return of({ result: null });
      }),
    );
    /* eslint-enable unicorn/no-null */
  }
}
