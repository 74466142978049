/**
 * https://firebase.google.com/docs/auth/custom-email-handler
 */

export interface AuthActionState {
  actionCode: string;
  // Don't care about this for this field for our app:
  // mode: string;
  nextUrl: string;
}

export const getActionState = (maybeState: unknown): AuthActionState => {
  // No great way to do this... https://github.com/microsoft/TypeScript/issues/21732
  if (typeof maybeState === 'object' && maybeState != null) { // eslint-disable-line unicorn/no-null
    const state = maybeState as Partial<AuthActionState>;
    if (state.actionCode) {
      return {
        actionCode: state.actionCode,
        nextUrl: state.nextUrl ?? '/',
      };
    }

    throw new Error(`Missing Navigation State actionCode: '${JSON.stringify(state)}'`);
  }

  throw new Error(`Invalid Navigation State '${JSON.stringify(maybeState)}'`);
};
