import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';
import type { AbstractControl, ValidationErrors } from '@angular/forms';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';

/**
 * Watches a Form Control type object for status changes, but only reports errors when the control
 * is dirty.
 */
@Pipe({ name: 'luxDirtyErrors', standalone: true })
export class DirtyErrorsPipe implements PipeTransform {
  public transform(cntrl: AbstractControl): Observable<ValidationErrors | null> {
    return cntrl.statusChanges.pipe(
      map((_: string): ValidationErrors | null => {
        if (cntrl.pristine) {
          // Don't display errors until the field has been interacted with.
          return null; // eslint-disable-line unicorn/no-null
        }
        return cntrl.errors;
      }),
    );
  }
}
