import { Inject, Injectable } from '@angular/core';
import {
  filter,
  first,
  from,
  map,
  of,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { LocalStorageService } from '@app/storage/local-storage.service';
import { WINDOW } from '@app/window.provider';

@Injectable({ providedIn: 'root' })
export class AuthService {
  /**
   * Provide the domain for sending password reset and email change messages. Also used to set the
   * learnlux.com public website login cookie domain, and in user monitor to report the website.
   */
  public readonly appDomain: string;

  /** Wait for login then emit the current FirebaseUser. */
  public readonly currentUser$: Observable<FirebaseUser>;

  /**
   * Firebase persistant auth needs an async process to determine if the user is logged in.
   * This value could be incorrect if the user's sessions times out.
   */
  public readonly isLoggedIn$: Observable<boolean>;

  public loggedOutDueToInactivity: boolean = false;

  /**
   * Store the URL so we can redirect after logging in. Used by auth.component.
   * By default navigate to the default route defined in app-routing.module.
   */
  public redirectUrl: string = '';

  /**
   * Used to get the current user for purposes of writing to Firebase.
   * Throws an error if there currently isn't a logged in user.
   */
  public readonly requireUser$: Observable<FirebaseUser>;

  constructor(
    private readonly afAuth: AngularFireAuthService,
    private readonly localStorage: LocalStorageService,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    this.appDomain = this.window.location.hostname;

    this.currentUser$ = this.afAuth.authState$.pipe(filter((user: FirebaseUser | null): user is FirebaseUser => !!user));

    // This should not emit until a request has been made to Firebase to determine if the current session is authenticated or not.
    this.isLoggedIn$ = this.afAuth.authState$.pipe(map((user: FirebaseUser | null): boolean => !!user));

    this.requireUser$ = this.afAuth.authState$.pipe(
      first(), // Each subscribe should emit once then complete.
      map((user: FirebaseUser | null): FirebaseUser => {
        if (!user) {
          throw new Error('User is not logged in.');
        }
        return user;
      }),
    );
  }

  /**
   * Uses Firebase auth to sign out the current user.
   * Updates this.isLoggedIn to false.
   */
  public logout(dueToInactivity: boolean = false): Observable<void> {
    this.loggedOutDueToInactivity = dueToInactivity;
    this.localStorage.remove('sessionId');
    const signOutPromise = this.afAuth.signOut();
    return from(signOutPromise);
  }

  /**
   * User must be currently authenticated to updateEmail.
   * Firebase requires a "recent" authentication by the user in order to change the email. But for
   * provider based login we don't necessaribly want to do that.
   * Since this is used in both Email-Password and Provider logins it remains in the AuthService.
   */
  public setEmail(user: FirebaseUser, newEmail: string): Observable<boolean> {
    if (!newEmail) {
      console.error('AuthService#setEmail', 'blank new email');
      return of(false);
    }
    const updateEmailPromise = this.afAuth.verifyBeforeUpdateEmail(user, newEmail);
    return from(updateEmailPromise).pipe(map((): boolean => true));
  }
}
