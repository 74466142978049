import { NgModule } from '@angular/core';

import { TComponent } from './t.component';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  exports: [ TComponent, TranslatePipe ],
  imports: [ TComponent, TranslatePipe ],
})
export class TranslationModule {}
