import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  catchError,
  EMPTY,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { AdvisorConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { InvalidFormPipe } from '@app/pipes/invalid-form/invalid-form.pipe';
import { TranslationModule } from '@app/translation/translation.module';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    InvalidFormPipe,
    NgOptimizedImage,
    ReactiveFormsModule,
    TranslationModule,
  ],
  selector: 'lux-data-consent-modal',
  standalone: true,
  styleUrls: [ './modal.component.scss' ],
  templateUrl: './modal.component.html',
})
export class DataConsentModalComponent {
  public readonly acceptDataConsentCtrl: FormControl;
  public readonly advisorConfig$: Observable<AdvisorConfig>;

  private readonly _submitSub$: Subject<void>;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly clientConfig: ClientConfigService,
    private readonly dataService: UserDataService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this._submitSub$ = new Subject<void>();

    this._submitSub$.pipe(
      switchMap((): Observable<boolean> => {
        const updates = {
          accepteddataconsent: true,
          accepteddataconsentdate: FirestoreTimestamp.now(),
        };

        return this.dataService.updateUserData(updates).pipe(
          catchError((err: unknown): Observable<never> => {
            console.error('DataConsentModalComponent#updateUserData', err);
            return EMPTY;
          }),
          tap((): void => {
            this.userTrackingService.trackEvent(EVENTS.privacyActiveConsentApproved);
          }),
        );
      }),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => { console.error('DataConsentModalComponent#subscribe#error', err); },
      next: (): void => { this.activeModal.close('consented'); },
    });

    this.acceptDataConsentCtrl = new FormControl(false, Validators.requiredTrue);
    this.advisorConfig$ = this.clientConfig.advisorConfig$;
  }

  /** Create a consent record. */
  public onSubmit(): void {
    if (this.acceptDataConsentCtrl.invalid) {
      throw new Error('Form Invalid');
    }

    this._submitSub$.next();
  }
}
