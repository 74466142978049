/** Service for retrieving lesson data from a datasource and transforming it into Lessons and LessonTags */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Slides } from '@app/presentation/slides/slides';

import type { Lesson, LessonTags } from '../lessons';

@Injectable({ providedIn: 'root' })
export abstract class LessonsDataService {
  /** Array of lessons */
  public abstract readonly lessons$: Observable<Lesson[]>;
  /** Sorted array of lesson tags */
  public abstract readonly lessonTags$: Observable<LessonTags[]>;

  /**
   * Fetch slides for a lesson given the lesson id
   * @param lessonId - The id of the lesson to fetch slides for
   * @returns An observable of the slides for the lesson
   */
  public abstract getSlidesForLessonById(lessonId: string): Observable<Slides[]>;
}
