import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  combineLatest,
  first,
  map,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { LoggerService } from '@app/logger/logger.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { UserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { DataConsentModalComponent } from './modal/modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule ],
  selector: 'lux-data-consent',
  standalone: true,
  template: '<ng-container *ngIf="showModal$ | async" />',
})
export class DataConsentComponent {
  public readonly showModal$: Observable<boolean>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly dataService: UserDataService,
    private readonly logger: LoggerService,
    private readonly modalService: NgbModal,
  ) {
    this.showModal$ = combineLatest([
      this.dataService.userData$,
      this.clientConfig.featuresConfig$,
    ]).pipe(
      map(([ userData, featuresConfig ]: [ UserData, FeaturesConfig]): boolean => {
        return featuresConfig.dataConsentEnabled && !userData.accepteddataconsent;
      }),
      first(),
      tap((showModal: boolean): void => {
        if (showModal) {
          this.open();
        }
      }),
    );
  }

  public open(): NgbModalRef {
    this.logger.track('DataConsentComponent#open');
    const modalOpts: NgbModalOptions = {
      // The DataConsentModalComponent _cannot_ be dismissed (by the NgbModal.dismissAll in AppComponent) it must be accepted by the user
      // to continue.
      beforeDismiss: (): false => false,
      fullscreen: true,
      keyboard: false, // Prevent ESC key from closing modal.
    };
    return this.modalService.open(DataConsentModalComponent, modalOpts);
  }
}
