import { InjectionToken } from '@angular/core';
import confetti from 'canvas-confetti'; // Needs tsconfig allowSyntheticDefaultImports to work and line up with types.

export type ConfettiType = typeof confetti;

export const CONFETTI = new InjectionToken<ConfettiType>(
  'confetti',
  {
    factory: (): ConfettiType => confetti,
    providedIn: 'root',
  },
);
