import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '@app/window.provider';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public readonly localStorage: Storage;

  constructor(@Inject(WINDOW) private readonly window: Window) {
    this.localStorage = this.window.localStorage;
  }

  public get(key: string): string | null {
    return this.localStorage.getItem(key);
  }

  public remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  public set(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }
}
