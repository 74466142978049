/*
 * User Monitor Events
 * Manually triggered event tracking for analytics (Pendo, etc).
 *
 * Template:
 *   {{ FeatureCategory }}_{{ Target }}_{{ Action }}
 *
 * Where:
 * - FeatureCategory - (noun, plural) is the feature that the member is using.
 * - Target - (noun) is the concept, component, or data that’s being interacted with.
 * - Action - (verb, past tense) is what the user did to the target.
 */

export const EVENTS = {
  advisorAdvisorDataCreated: 'Advisor_AdvisorData_Created',
  advisorAdvisorMeetingScheduled: 'Advisor_AdvisorMeeting_Scheduled',
  advisorAdvisorViewedMemberData: 'Advisor_AdvisorViewed_MemberData',
  advisorSpecialRequestClicked: 'Advisor_SpecialRequest_Clicked',
  authUserCreated: 'Auth_User_Created',
  benefitsBenefitPreviewModalOpened: 'Benefits_BenefitPreviewModal_Opened',
  budgetBudgetProgressCompleted: 'Budget_BudgetProgress_Completed',
  calculatorDebtPayoffAccountsConnected: 'Calculator_DebtPayoff_AccountsConnected',
  calculatorDebtPayoffAddFundsEntered: 'Calculator_DebtPayoff_AddFundsEntered',
  calculatorDebtPayoffMethodSelected: 'Calculator_DebtPayoff_MethodSelected',
  calculatorDebtPayoffVisited: 'Calculator_DebtPayoff_Visited',
  calculatorEmergencySavingsCompleted: 'Calculator_EmergencySavings_Completed',
  calculatorEmergencySavingsMonthlySavingsUpdated: 'Calculator_EmergencySavings_MonthlySavingsUpdated',
  calculatorRetirementVisited: 'Calculator_Retirement_Visited',
  calculatorSavingsGoalCompleted: 'Calculator_SavingsGoal_Completed',
  checkupCheckupItemAnswered: 'Checkup_CheckupItem_Answered',
  checkupCheckupResultsViewed: 'Checkup_CheckupResults_Viewed',
  lessonsLessonProgressCompleted: 'Lessons_LessonProgress_Completed',
  lessonsLessonProgressStarted: 'Lessons_LessonProgress_Started',
  lessonsLessonRatingCreated: 'Lessons_LessonRating_Created',
  lessonsLessonSearchSubmitted: 'Lessons_LessonSearch_Submitted',
  myAccountsAccountHidden: 'MyAccounts_Account_Hidden',
  myAccountsAccountUnhidden: 'MyAccounts_Account_Unhidden',
  myAccountsInstitutionAdded: 'MyAccounts_Institution_Added',
  myAccountsInstitutionRemoved: 'MyAccounts_Institution_Removed',
  myAccountsInstitutionUpdated: 'MyAccounts_Institution_Updated',
  myAccountsManualAccountAdded: 'MyAccounts_ManualAccount_Added',
  myAccountsManualAccountRemoved: 'MyAccounts_ManualAccount_Removed',
  myAccountsManualAccountUpdated: 'MyAccounts_ManualAccount_Updated',
  myAccountsPlaidAccountDataUpdated: 'MyAccounts_PlaidAccount_DataUpdated',
  onboardingCompleted: 'Onboarding_Completed',
  preferencesPreferenceSet: 'Preferences_Preference_Set',
  privacyActiveConsentApproved: 'Privacy_ActiveConsent_Approved',
  privacyWhyWeAskModalOpened: 'Privacy_WhyWeAskModal_Opened',
  suggestionsSuggestionCompleted: 'Suggestions_Suggestion_Completed',
  suggestionsSuggestionCreated: 'Suggestions_Suggestion_Created',
  suggestionsSuggestionDismissed: 'Suggestions_Suggestion_Dismissed',
  suggestionsSuggestionPreviewModalOpened: 'Suggestions_SuggestionPreviewModal_Opened',
  suggestionsSuggestionUndone: 'Suggestions_Suggestion_Undone',
} as const;
