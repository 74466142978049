import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

import type { AdvisorConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { HubSpotService } from '@app/hub-spot/hub-spot.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { TranslationModule } from '@app/translation/translation.module';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { TranslationService } from '@app/translation/translation.service';

export interface ViewModel {
  callEnabled: boolean;
  chatEnabled: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, RouterModule, TranslationModule ],
  selector: 'lux-ask-question-buttons',
  standalone: true,
  templateUrl: './ask-question-buttons.component.html',
})
export class AskQuestionButtonsComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly hubspot: HubSpotService,
    private readonly translationService: TranslationService,
  ) {
    this.vm$ = combineLatest([
      this.translationService.updateTranslations$,
      this.clientConfig.advisorConfig$,
    ]).pipe(
      map(
        ([ currentLocale, advisorConfig ]: [ string, AdvisorConfig ]): ViewModel => ({
          callEnabled: advisorConfig.callEnabled,
          chatEnabled: advisorConfig.chatEnabled && currentLocale === 'en-US',
        }),
      ),
    );
  }

  public openChat(): void {
    this.hubspot.openChat();
  }
}
