import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs';

import { DefaultCurrencyPipe } from '@app/pipes/default-currency/default-currency.pipe';
import { TranslationModule } from '@app/translation/translation.module';
import type { UserData } from '@app/users/user';
import type { Account } from '@app/users/user-accounts.service';
import { UserAccountsService } from '@app/users/user-accounts.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { AccountingCurrencyPipe } from '../accounting-currency/accounting-currency.pipe';
import { NetWorthChartComponent } from '../net-worth-chart/net-worth-chart.component';

export interface ViewModel {
  hasAccounts: boolean;
  totalAssets: number;
  totalLiabilities: number;
  totalNetWorth: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AccountingCurrencyPipe,
    CommonModule,
    DefaultCurrencyPipe,
    NetWorthChartComponent,
    TranslationModule,
  ],
  selector: 'lux-accounts-header',
  standalone: true,
  styleUrls: [
    '../accounts-shared.scss',
    './accounts-header.component.scss',
  ],
  templateUrl: './accounts-header.component.html',
})
export class AccountsHeaderComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly userAccountsService: UserAccountsService,
    private readonly userDataService: UserDataService,
  ) {
    this.vm$ = combineLatest([
      this.userDataService.userData$,
      this.userAccountsService.accounts$,
    ]).pipe(
      map(([ userData, userAccounts ]: [UserData, Account[] ]): ViewModel => ({
        hasAccounts: userAccounts.length > 0,
        totalAssets: userData.accountsSummary?.totalAssets ?? 0,
        totalLiabilities: userData.accountsSummary?.totalLiabilities ?? 0,
        totalNetWorth: userData.accountsSummary?.netWorth ?? 0,
      })),
    );
  }
}
