import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lux-graphic-page-not-found',
  standalone: true,
  styleUrls: [ './graphic.component.scss' ],
  templateUrl: './graphic.component.html',
})
export class GraphicComponent {}
