import { NgModule } from '@angular/core';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  exports: [ FooterComponent, HeaderComponent ],
  imports: [ FooterComponent, HeaderComponent ],
})
export class LayoutModule {}
