import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports -- Analytics is Angular Injected
import { Analytics, logEvent } from '@angular/fire/analytics';
import type { AnalyticsCallOptions } from '@angular/fire/analytics';

@Injectable({ providedIn: 'root' })
export class AngularFireAnalyticsService {
  constructor(private readonly analytics: Analytics) {}

  public logEvent(eventName: string, eventParams?: Record<string, unknown>, options?: AnalyticsCallOptions): void {
    logEvent(this.analytics, eventName, eventParams, options);
  }
}
