import { Injectable } from '@angular/core';
import { forkJoin, map, of } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FormUserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { AuthService } from './auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ProviderLogin } from './provider-login.service';

@Injectable({ providedIn: 'root' })
export class ProviderDataService {
  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserDataService,
  ) {}

  public handleProviderData(result: ProviderLogin): Observable<ProviderLogin> {
    if (result.isNewUser && result.profile) {
      return forkJoin([
        this._mapProviderProfile(result.profile),
        this._setProviderEmail(result.profile, result.user),
      ]).pipe(map((): ProviderLogin => result));
    }
    return of(result);
  }

  private _mapProviderProfile(profile: Record<string, string>): Observable<boolean> {
    const userData: FormUserData = {};
    for (const [ key, value ] of Object.entries(profile)) {
      // Do not import falsey values.
      if (value) {
        const lKey = this._normalizeKey(key);
        if (lKey === 'companyid') {
          // companyid is the company name "AARONS" for Aaron's SAML.
          userData.employername = value;
        }
        if (lKey === 'eid' || lKey === 'employeeid' || lKey === 'uniqueid') {
          // e-id is the employee id for Fifth Third's SAML, employeeid for Plenty, UniqueId is for Aarons.
          userData.employeeId = value;
        }
        if (lKey === 'firstname' || lKey === 'givenname') {
          // TypeScript is happier using explicit property names instead of userData[key.toLowerCase()]
          userData.firstname = value;
        }
        if (lKey === 'lastname' || lKey === 'surname') {
          // TypeScript is happier using explicit property names instead of userData[key.toLowerCase()]
          userData.lastname = value;
        }
      }
    }
    return Object.keys(userData).length > 0 ? this.userService.updateUserData(userData) : of(true);
  }

  /**
   * Since each SSO can use differnet field names, we normalize the field names to lower case
   * without any symbols (non-alphanumeric).
   * In the case of Azure we have namespaced names, which might actually be confusing if there are duplicates.
   * But for now just take the last part of the URL path as the key. If there are duplicates then we
   * don't handle that for now.
   */
  private _normalizeKey(key: string): string {
    return key
      .slice(Math.max(0, key.lastIndexOf('/') + 1))
      .toLowerCase()
      .replaceAll(/[^a-z0-9]/gu, '');
  }

  private _setProviderEmail(profile: Record<string, string>, user?: FirebaseUser): Observable<boolean> {
    // If somehow user is null (shouldn't happen)
    // or if the email is already set (Afterpay it doesn't appear to be set automatically)
    // then skip.
    if (user && !user.email) {
      for (const [ key, value ] of Object.entries(profile)) {
        const lKey = this._normalizeKey(key);
        if ((lKey === 'email' || lKey === 'emailaddress') && value) {
          return this.authService.setEmail(user, value);
        }
      }
    }

    return of(true);
  }
}
