import { ChangeDetectionStrategy, Component } from '@angular/core';

/** This is the same component mostly as is used in index.html spinner. We should keep them in sync. */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lux-spinner',
  standalone: true,
  styleUrls: [ './spinner.component.scss' ],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {}
