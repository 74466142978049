import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import player from 'lottie-web';
import type { AnimationItem, LottiePlayer } from 'lottie-web';
import type { AnimationOptions } from 'ngx-lottie';
import { AnimationLoader as LottieAnimationLoader, LottieModule, provideLottieOptions } from 'ngx-lottie';
import { combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import type { AdvisorConfig, FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { ArrowComponent } from '@app/stuff/arrow/arrow.component';
import { DotsCenterComponent } from '@app/stuff/dots/center/dots.component';
import { DotsLeftComponent } from '@app/stuff/dots/left/dots.component';
import { DotsRightComponent } from '@app/stuff/dots/right/dots.component';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

export interface ViewModel {
  advisorConfig: AdvisorConfig;
  wistiaId: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    LottieModule,
    RouterModule,
    SpinnerComponent,
    DotsLeftComponent,
    DotsRightComponent,
    DotsCenterComponent,
    ArrowComponent,
    TranslationModule,
  ],
  providers: [
    LottieAnimationLoader,
    provideLottieOptions({ player: (): LottiePlayer => player }),
  ],
  selector: 'lux-auth',
  standalone: true,
  styleUrls: [ './auth.component.scss' ],
  templateUrl: './auth.component.html',
})
export class AuthComponent {
  public readonly isUSApp: boolean;
  public readonly options: AnimationOptions = {
    path: 'https://static.learnlux.com/images/animations/spinning-sun-v1.0.json',
  };

  public readonly organization: string;
  public readonly shouldShowVideo: boolean;
  public readonly vm$: Observable<ViewModel>;
  public readonly wantsVideoCustomers: boolean;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly clientConfig: ClientConfigService,
  ) {
    this.vm$ = combineLatest([
      this.clientConfig.advisorConfig$,
      this.clientConfig.featuresConfig$.pipe(
        map((featuresConfig: FeaturesConfig): string => featuresConfig.wistiaId),
      ),
    ]).pipe(
      map(([ advisorConfig, wistiaId ]: [ AdvisorConfig, string ]): ViewModel => ({
        advisorConfig,
        wistiaId,
      })),
    );
    this.organization = this.appConfig.organization;
    // Turns off the video for international members
    this.isUSApp = this.appConfig.locale === 'en-US';
    this.wantsVideoCustomers = this.organization === 'United';
    this.shouldShowVideo = this.isUSApp && this.wantsVideoCustomers;
  }

  public animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(0.1);
  }
}
