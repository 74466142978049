import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, TitleStrategy } from '@angular/router';
import type { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';

import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';

import { TitleTranslationService } from './title-translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

@Injectable({ providedIn: 'root' })
export class BreadcrumbTitleService extends TitleStrategy {
  private _queryParams: Params = {};
  private _titleSegments: string[] = [];

  constructor(private readonly titleService: TitleTranslationService) {
    super();
  }

  /**
   * Because of the TitleStrategy interface we must return some string or undefined. But what we are
   * really using this method for is to set `this.queryParams` and `this.titleSegments` for translation.
   */
  public override buildTitle(snapshot: RouterStateSnapshot): string | undefined {
    this._titleSegments = [];
    let route: ActivatedRouteSnapshot | undefined = snapshot.root;

    // Save the ?query=params for translations
    this._queryParams = route.queryParams; // This is a reference to an object, so it might be unsafe access.

    while (route !== undefined) {
      const title = this.getResolvedTitleForRoute(route) as string | undefined;
      // If this route is the wildcard ('**') path then don't use the Url to make the title.
      if (route.component === PageNotFoundComponent) {
        this._titleSegments = [ 'page-not-found.title' ]; // Page Not Found route title
        return undefined;
      }
      if (title) {
        this._titleSegments.push(title);
      }
      route = route.children.find((child: ActivatedRouteSnapshot): boolean => child.outlet === PRIMARY_OUTLET);
    }

    return undefined;
  }

  /** Performs the application title update. */
  public updateTitle(routerState: RouterStateSnapshot): void {
    this.buildTitle(routerState);
    this.titleService.setRouteTitle([ ...this._titleSegments ], { ...this._queryParams });
  }
}
