/**
 * The `LocaleService` is _only_ **ever** for setting the Member's Preferred Language!
 * Do _not_ use the Service to read the current locale as that is determined by Transifex not the
 * user's preferences.
 * If you are looking at this file, you are 95% certainly in the wrong place. Look at `TranslationService`
 * unless you are doing something to change how a user sets their preferred locale.
 */
import { Inject, Injectable } from '@angular/core';
import type { FirebaseError } from '@angular/fire/app';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  merge,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { AppConfig } from '@app/app-config/app-config';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { LocaleOption, TranslationConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { TranslationService } from '@app/translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { UserPreference } from '@app/users/user-preferences.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserPreferencesService } from '@app/users/user-preferences.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

export interface LanguageConfig {
  selectedLocale: string;
  translationConfig: TranslationConfig;
}

@Injectable({ providedIn: 'root' })
export class LocaleService {
  public languages$: Observable<LanguageConfig>;

  constructor(
    @Inject(APP_CONFIG) public readonly appConfig: AppConfig,
    private readonly authService: AngularFireAuthService,
    private readonly clientConfig: ClientConfigService,
    private readonly translationService: TranslationService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    const selectedLocale$ = merge(
      this.userPreferencesService.userLanguagePreference$.pipe(
        // Because this is being used outside of the authenticated area by the
        // LanguagePickerFooterComponent it causes an error if the user logs out from their session.
        catchError((err: unknown): Observable<undefined> => {
          if (err instanceof Error) {
            const someErr = err as Error | FirebaseError;
            if ('code' in someErr && someErr.code === 'permission-denied') {
              return of(undefined);
            }
          }

          console.error('LocaleService#userLanguagePreference$', err);
          throw err;
        }),
        map((pref: UserPreference | undefined): string | undefined => {
          if (typeof pref?.['locale'] === 'string') {
            return pref['locale'];
          }

          return undefined;
        }),
        filter((selectedLocale: string | undefined): selectedLocale is string => !!selectedLocale),
        tap((selectedLocale: string): void => {
          this.translationService.setCurrentLocale(selectedLocale)
            // eslint-disable-next-line promise/prefer-await-to-then
            .catch((err: unknown): void => {
              console.error('LocaleService#translationService.setCurrentLocale', err);
            });
        }),
      ),
      this.translationService.updateTranslations$,
    ).pipe(distinctUntilChanged());

    this.languages$ = combineLatest([
      this.clientConfig.translationConfig$,
      selectedLocale$,
    ]).pipe(
      map(([ translationConfig, selectedLocale ]: [ TranslationConfig, string ]): LanguageConfig => {
        if (translationConfig.default !== this.appConfig.locale) {
          console.error(
            'LocaleService',
            `AppConfig and ClientConfig default locale mismatch: '${this.appConfig.locale}' vs '${translationConfig.default}'`,
          );
        }

        if (!translationConfig.locales.some((opt: LocaleOption): boolean => opt.id === selectedLocale)) {
          console.error('LocaleService', `Invalid selected locale '${selectedLocale}' not in TranslationConfig`);
          return { selectedLocale: translationConfig.default, translationConfig };
        }

        return { selectedLocale, translationConfig };
      }),
      tap((config: LanguageConfig): void => {
        this.authService.setLanguageCode(config.selectedLocale);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Set the current Transifex locale and, if the user is logged in,
   * updates the users locale language preference in the database
   * tracks the update to preference in Pendo
   * @param localeId - Language and region identifier in a specified format: e.g. en_US (English_United States)
   */
  public setLocale(localeId: string): Observable<void> {
    return forkJoin({
      transifex: this.translationService.setCurrentLocale(localeId),
      // If this is triggered outside of a user session then it will patiently wait for the user to
      // login and not error.
      userPref$: this.userPreferencesService.setPreferences('language', { locale: localeId })
        .pipe(
          tap((): void => {
            this.userTrackingService.trackEvent(EVENTS.preferencesPreferenceSet, {
              id: 'language',
              preferences: JSON.stringify({ locale: localeId }),
            });
          }),
        ),
    }).pipe(map((): undefined => undefined));
  }
}
