import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';

import { GoalsService } from '../goals.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { Goal, UserGoals } from '../goals.service';

export interface ViewModel {
  goals: Goal[];
  userGoals: UserGoals;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterModule,
    SpinnerComponent,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-goals-preview',
  standalone: true,
  styleUrls: [ './goals-preview.component.scss' ],
  templateUrl: './goals-preview.component.html',
})
export class GoalsPreviewComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(private readonly goalsService: GoalsService) {
    this.vm$ = combineLatest([
      this.goalsService.goals$.pipe(
        map((goals: Goal[]): Goal[] => goals.filter(
          (goal: Goal): boolean => goal.enabled,
        )),
      ),
      this.goalsService.userGoals$,
    ]).pipe(
      map(([ goals, userGoals ]: [ Goal[], UserGoals ]): ViewModel => ({ goals, userGoals })),
    );
  }
}
