import { NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ NgIf, NgOptimizedImage ],
  selector: 'lux-check-circle',
  standalone: true,
  styleUrls: [ './check-circle.component.scss' ],
  templateUrl: './check-circle.component.html',
})
export class CheckCircleComponent {
  @Input() public complete: boolean = false;
}
