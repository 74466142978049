// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold {
  color: #2a5a61;
  font-size: 6rem;
}

.content {
  padding: 10px 20px;
}
@media (min-width: 576px) {
  .content {
    padding: 20px 50px;
  }
}
@media (min-width: 768px) {
  .content {
    padding: 40px 100px;
  }
}
@media (min-width: 992px) {
  .content {
    padding: 80px 200px;
  }
}
@media (min-width: 1200px) {
  .content {
    padding: 100px 400px;
  }
}

.divider {
  border: none;
  background-color: #5e6b6f;
  width: 100%;
  height: 0.2rem;
  color: #5e6b6f;
}
@media (min-width: 576px) {
  .divider {
    width: 75%;
  }
}
@media (min-width: 768px) {
  .divider {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .not-found-graphic {
    position: absolute;
    right: 50px;
    bottom: 150px;
  }
}
@media (min-width: 992px) {
  .not-found-graphic {
    right: 200px;
  }
}
@media (min-width: 1200px) {
  .not-found-graphic {
    right: 250px;
  }
}

.spacer {
  background-color: #bdebe3;
  padding-top: 10rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
