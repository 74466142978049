// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;
}
button img {
  display: none;
}
button .title {
  flex: 1;
  color: #181b1c;
  font-size: 1rem;
  font-weight: 600;
}
button .material-symbols-rounded {
  border-radius: 50%;
  background-color: #d4edeb;
  padding: 4px;
  color: #29a59a;
  font-size: 2rem;
}
button .btn-outline-dark {
  padding: 0.75rem;
  width: 8.75rem;
  color: #29a59a;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  button {
    flex-direction: row;
  }
  button img {
    display: inline-block;
    width: 40px;
  }
  button .title {
    text-align: start;
  }
}
.done {
  box-shadow: none;
  background-color: #f1f3f3;
}

.dismissed {
  box-shadow: none;
}
.dismissed .title {
  color: #d6dbdc;
}
.dismissed .dismissed {
  font-size: 0.875rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
