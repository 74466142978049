/**
 * Estimate taxes for a user.
 *
 * This does a bunch of monetary math, which is risky...
 * https://frontstuff.io/how-to-handle-monetary-values-in-javascript
 */
/* eslint-disable max-lines */
import { HttpClient } from '@angular/common/http'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  first,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { UserDataFirestore } from '@app/users/user';
import { roundToCents } from '@app/utilities/round-to-cents';

import { taxDataUrls } from './tax-data';
import type {
  FilingStatus,
  IncomeTaxBracket,
  IncomeTaxBracketMap,
  IncomeTaxDeductionMap,
  StateCodes,
  StateTaxData,
  StateTaxDataMap,
  TaxData,
  TaxDataJson,
  TaxesComputed,
  TaxLawSettings,
} from './tax-data';

const MONTHS_PER_YEAR = 12;

@Injectable({ providedIn: 'root' })
export class TaxDataService {
  public readonly currentTaxYear: number = new Date().getFullYear();
  public readonly currentTaxYearData$: Observable<TaxData>;
  public readonly taxData$: Observable<TaxData>;
  public readonly taxYearSub$: BehaviorSubject<number>; // eslint-disable-line rxjs/no-exposed-subjects

  constructor(private readonly http: HttpClient) {
    // Default FY for tax calculations is set to this every app load.
    this.taxYearSub$ = new BehaviorSubject<number>(this.currentTaxYear);
    this.taxData$ = this.taxYearSub$.pipe(
      switchMap((taxYear: number): Observable<TaxData> => this.getTaxData(taxYear)),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    // This is the current tax year data, it will not update if the selected tax year changes.
    // Depends on taxData$ initializing to currentTaxYear, which it always will.
    this.currentTaxYearData$ = this.taxData$.pipe(
      first(),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /**
   * An "Above the line" deduction is a tax break that lowers the gross income that a member
   * would otherwise have to pay taxes on. In our case, this value is based on the sum of contributions
   * to the members 401(k), IRA, and HSA.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeAboveTheLineDeductions(userData: UserDataFirestore): number {
    const monthly401kpayment: number = userData.monthly401kpayment ?? 0;
    const monthlyirapayment: number = userData.monthlyirapayment ?? 0;
    const monthlysavingshsa: number = userData.monthlysavingshsa ?? 0;
    const monthlyhealthinsurancepayment: number = userData.monthlyhealthinsurancepayment ?? 0;
    const monthlysavingsfsa: number = userData.monthlysavingsfsa ?? 0;

    // Traditional 401k would already be removed in the w-2 so you wouldn't normally include this
    // in the AGI, but we are getting raw numbers from the user.
    const fourOhOneK = monthly401kpayment * MONTHS_PER_YEAR;
    const ira = monthlyirapayment * MONTHS_PER_YEAR;
    const hsa = monthlysavingshsa * MONTHS_PER_YEAR;
    const healthinsurance = monthlyhealthinsurancepayment * MONTHS_PER_YEAR;
    const fsa = monthlysavingsfsa * MONTHS_PER_YEAR;

    const abovethelinedeductions = fourOhOneK + ira + hsa + healthinsurance + fsa;

    // https://blog.taxact.com/itemized-vs-above-line-deductions/
    // https://smartasset.com/taxes/what-are-above-the-line-deductions
    // https://www.investopedia.com/ask/answers/111815/do-401k-contributions-reduce-agi-andor-magi.asp
    // Should be rounded as we will do further math on this value.
    return roundToCents(abovethelinedeductions);
  }

  /**
   * Subtracts above the line deductions and pre-tax income from total income to get approximately
   * the adjusted gross income.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeAdjustedGrossIncome(totalIncome: number, aboveTheLineDeducations: number): number {
    // Should be rounded as we will do further math on this value.
    return roundToCents(totalIncome - aboveTheLineDeducations);
  }

  /**
   * Total income remaining after expenses, retirement, and savings (monthly)
   * Calling this Net is not standard. This is the Remaining Unbudgeted Income
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeMonthlyNet(monthlybudget: number, incomeaftertaxes: number): number {
    // Seems reasonable to round this division so we deal with whole cents for the remaining budget.
    const monthlyIncome = roundToCents(incomeaftertaxes / MONTHS_PER_YEAR);

    return monthlyIncome - monthlybudget;
  }

  /**
   * This computes the *Federal* taxable income after the standard deduction.
   * In the event that totalIncome is less than all the subtractions (e.g. someone earning $10,000
   * year) this will return zero.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTaxableIncome(
    adjustedGrossIncome: number,
    taxfilingstatus: FilingStatus,
    federalDeductions: IncomeTaxDeductionMap,
  ): number {
    const standardDeduction = this._getFederalTaxDeduction(taxfilingstatus, federalDeductions);
    const taxableIncome = adjustedGrossIncome - standardDeduction; // Taxable income is AGI - federal deduction

    // Can't be negative, and should be rounded as we will do further math on this value.
    return Math.max(roundToCents(taxableIncome), 0);
  }

  /**
   * Estimates the federal income tax for an individual, based on taxable income, their filing
   * status, and tax brackets.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTaxFederal(taxableIncome: number, taxfilingstatus: FilingStatus, federalBrackets: IncomeTaxBracketMap): number {
    const brackets = this._getFederalTaxBrackets(taxfilingstatus, federalBrackets);
    return this._computeTaxBracket(taxableIncome, brackets);
  }

  /**
   * Medicare is taxed at 1.45% of earned income, or 2.35% if you make more then a specified amount
   * as determined by your filing status.
   * FICA is calculated on earned income.
   * These calculations are wrong for Self-Employed income.
   * https://www.irs.gov/taxtopics/tc751
   * https://www.irs.gov/businesses/small-businesses-self-employed/questions-and-answers-for-the-additional-medicare-tax
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTaxMedicare(totalIncome: number, taxfilingstatus: FilingStatus, medicareSettings: TaxLawSettings['medicare']): number {
    // Threshold before you must pay the "additional" Medicare tax.
    if (taxfilingstatus in medicareSettings.wageBaseLimits) {
      let medicareTax = 0;
      const wageBaseLimit = medicareSettings.wageBaseLimits[taxfilingstatus];
      let rate = medicareSettings.baseRate;
      let income = totalIncome;
      if (income > wageBaseLimit) {
        // Tax all the income up to the wageBaseLimit using the base rate.
        medicareTax += roundToCents(wageBaseLimit * rate);
        // Increase the rate for any remaining income
        rate += medicareSettings.additionalRateOverWageBase;
        // Remove the already taxed income
        income -= wageBaseLimit;
      }
      medicareTax += roundToCents(income * rate);
      return medicareTax;
    }
    throw new Error(`Medicare wage base limit for '${taxfilingstatus}' filing status does not exist.`);
  }

  /**
   * 6.2% of Income or $132,900 - Whichever is lower.
   * FICA is calculated on earned income.
   * These calculations are wrong for Self-Employed income.
   * https://www.irs.gov/taxtopics/tc751
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTaxSocialSecurity(totalIncome: number, socialSecuritySettings: TaxLawSettings['socialSecurity']): number {
    return roundToCents(socialSecuritySettings.rate * Math.min(totalIncome, socialSecuritySettings.maxIncome));
  }

  /**
   * Finds the tax percentage for the specified state, converts it to a rate and then calculates the
   * state taxes.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTaxState(
    adjustedgrossincome: number,
    federalTaxableIncome: number,
    state: StateCodes,
    taxfilingstatus: FilingStatus,
    stateTaxDatum: StateTaxDataMap,
  ): number {
    const stateTaxData = this._getStateTaxData(state, stateTaxDatum);

    // Some states don't have different taxes for married separate/jointly vs single vs head of
    // household.
    // If we don't have a bracket for the filing status, then default to the 'Single' bracket.
    const brackets = stateTaxData.brackets[taxfilingstatus] ?? stateTaxData.brackets.Single;
    if (brackets == undefined) {
      throw new Error(`State tax bracket for '${taxfilingstatus}' or fallback Single filing status does not exist.`);
    }

    const deduction = stateTaxData.deductions[taxfilingstatus] ?? stateTaxData.deductions.Single;
    if (deduction == undefined) {
      throw new Error(`State tax deduction for '${taxfilingstatus}' or fallback Single filing status does not exist.`);
    }

    const taxableIncome = stateTaxData.income === 'agi' ? adjustedgrossincome : federalTaxableIncome;
    return this._computeTaxBracket(taxableIncome - deduction, brackets);
  }

  /**
   * Sum of all the individual taxes calculated for the year.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _computeTotalTaxes(computed: TaxesComputed): number {
    // This is used in further arithmetic so round
    return roundToCents(computed.taxfederal + computed.taxmedicare + computed.taxsocialsecurity + computed.taxstate);
  }

  /**
   * Use tax data to compute all of the tax calculations.
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _fillComputedFields(totalincome: number, monthlybudget: number, userData: UserDataFirestore, taxData: TaxData): TaxesComputed {
    // Initialize data. Ensure that returned values are never undefined.
    const computed: TaxesComputed = {
      abovethelinedeductions: 0,
      adjustedgrossincome: 0,
      incomeaftertaxes: 0,
      monthlynet: 0,
      taxableincome: 0,
      taxfederal: 0,
      taxlocalavg: 0,
      taxmedicare: 0,
      taxsocialsecurity: 0,
      taxstate: 0,
      taxtotal: 0,
    };

    // Gets the total of the member's above the line deductions.
    computed.abovethelinedeductions = this._computeAboveTheLineDeductions(userData);

    // Gets adjusted gross income once above the line deductions are subtracted.
    computed.adjustedgrossincome = this._computeAdjustedGrossIncome(totalincome, computed.abovethelinedeductions);

    if (userData.taxfilingstatus) {
      // This is the *federal* taxable income, states might be different.
      computed.taxableincome = this._computeTaxableIncome(
        computed.adjustedgrossincome,
        userData.taxfilingstatus,
        taxData.federalIncomeTaxDeductions,
      );

      // @TODO: 1099 and Business income is taxed differently.
      computed.taxfederal = this._computeTaxFederal(computed.taxableincome, userData.taxfilingstatus, taxData.federalIncomeTaxBrackets);

      computed.taxmedicare = this._computeTaxMedicare(totalincome, userData.taxfilingstatus, taxData.taxLawSettings.medicare);

      if (userData.state) {
        computed.taxstate = this._computeTaxState(
          // Some states have their own deduction
          computed.adjustedgrossincome,
          // Some states use the federal standard deduction
          computed.taxableincome,
          userData.state,
          userData.taxfilingstatus,
          taxData.stateIncomeTaxData,
        );
      }
    }

    computed.taxsocialsecurity = this._computeTaxSocialSecurity(totalincome, taxData.taxLawSettings.socialSecurity);

    computed.taxtotal = this._computeTotalTaxes(computed);
    computed.incomeaftertaxes = totalincome - computed.taxtotal;

    computed.monthlynet = this._computeMonthlyNet(monthlybudget, computed.incomeaftertaxes);

    return computed;
  }

  public computeTaxes(totalincome: number, monthlybudget: number, userData: UserDataFirestore): Observable<TaxesComputed> {
    return this.taxData$.pipe(
      map((taxData: TaxData): TaxesComputed => this._fillComputedFields(totalincome, monthlybudget, userData, taxData)),
    );
  }

  // Retrieve the tax data for the specified year.
  public getTaxData(taxYear: number): Observable<TaxData> {
    const url: string | undefined = taxDataUrls[taxYear];
    if (url == undefined) {
      throw new Error(`No tax data for year '${taxYear}'`);
    }

    return this.http.get<TaxDataJson>(url).pipe(map(
      (data: TaxDataJson): TaxData => ({
        ...data,
        dates: {
          autoExtensionIrs: new Date(data.dates.autoExtensionIrs),
          currentTaxYear: data.dates.currentTaxYear,
          deadlineAlt: data.dates.deadlineAlt ? new Date(data.dates.deadlineAlt) : undefined,
          deadlineIrs: new Date(data.dates.deadlineIrs),
          extensionIrs: new Date(data.dates.extensionIrs),
          startIrs: new Date(data.dates.startIrs),
        },
      }),
    ));
  }

  /**
   * Calculates Income Tax Brackets for taxabileincome.
   * Example:
   * ```
   * brackets = [{ bracket: 13600, rate: 0.1 }, { bracket: 51800, rate: 0.12 },
   *             { bracket: 82500, rate: 0.22 }, { bracket: 157500, rate: 0.24 }];
   * taxableIncome = 84000;
   * tax =
   *  // calculate tax for each bracket less than total taxable
   *  13600 * .1 + (51800 - 13600) * .12 + (82500 - 51800) * .22 +
   *  // Now tax the remaining
   *  (84000 - 82500) * .24;
   *  // = 1360 + 4584 + 6754 + 360
   *  // = 13058
   * ```
   */
  private _computeTaxBracket(taxableIncome: number, brackets: IncomeTaxBracket[]): number {
    let tax = 0; // Total accumulated taxes.
    let prevBracket = 0; // Lower bound of the bracket range.
    for (const { bracket, rate } of brackets) {
      if (bracket == undefined || taxableIncome < bracket) {
        tax += roundToCents((taxableIncome - prevBracket) * rate);
        break;
      }
      // Else the upper bound of the range of income to be taxed at this rate.
      tax += roundToCents((bracket - prevBracket) * rate);
      prevBracket = bracket;
    }

    return roundToCents(tax);
  }

  /** Get the federal tax brackets based on the user's provided taxfilingstatus. */
  private _getFederalTaxBrackets(taxfilingstatus: FilingStatus, federalIncomeTaxBrackets: IncomeTaxBracketMap): IncomeTaxBracket[] {
    if (taxfilingstatus in federalIncomeTaxBrackets) {
      return federalIncomeTaxBrackets[taxfilingstatus];
    }
    throw new Error(`Federal tax bracket for '${taxfilingstatus}' filing status does not exist.`);
  }

  /** Get the federal standard deduction based on the user's provided taxfilingstatus. */
  private _getFederalTaxDeduction(taxfilingstatus: FilingStatus, federalIncomeTaxDeductions: IncomeTaxDeductionMap): number {
    if (taxfilingstatus in federalIncomeTaxDeductions) {
      return federalIncomeTaxDeductions[taxfilingstatus];
    }
    throw new Error(`Federal tax bracket for '${taxfilingstatus}' filing status does not exist.`);
  }

  /** Get the data (deduction, brackets, etc) for state. */
  private _getStateTaxData(state: StateCodes, stateIncomeTaxData: StateTaxDataMap): StateTaxData {
    if (state in stateIncomeTaxData) {
      return stateIncomeTaxData[state];
    }

    throw new Error(`State '${state}' does not exist.`);
  }
}
