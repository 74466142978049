/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import type { OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { Params } from '@angular/router';
import {
  map,
  merge,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationService } from '@app/translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

type ICUVars = Record<string, string>;
type RouteTitleParams = [ string[], ICUVars ];

@Injectable({ providedIn: 'root' })
export class TitleTranslationService implements OnDestroy {
  private readonly _additionalICUVars$: Subject<ICUVars>;
  private readonly _routeTitleSub$: Subject<RouteTitleParams>;
  private readonly _unsubscribeSub$: Subject<void>;

  constructor(
    private readonly title: Title,
    private readonly translator: TranslationService,
  ) {
    this._additionalICUVars$ = new Subject<ICUVars>();
    this._routeTitleSub$ = new Subject<RouteTitleParams>();
    this._unsubscribeSub$ = new Subject<void>();

    // As a service we cannot use AsyncPipe. So doing this manually.
    // @Injectable()s don't implement OnInit for some reason says the linter.
    // But OnDestroy is used by Angular so that should be fine.
    merge(
      // If _routeTitleSub$ emits, then just set the title.
      this._routeTitleSub$,
      // If _additionalICUVars$ emits, then use the last value from _routeTitleSub$. Combine the
      // ICUVars, then emit the same interface as _routeTitleSub$
      this._additionalICUVars$.pipe(
        withLatestFrom(this._routeTitleSub$),
        map((
          [ moreVars, [ keys, queryVars ] ]: [ ICUVars, RouteTitleParams ],
        ): RouteTitleParams => [ keys, { ...queryVars, ...moreVars } ]),
      ),
    ).pipe(
      switchMap(([ keys, vars ]: RouteTitleParams): Observable<string> => this.translator.updateTranslations$.pipe(
        map((): string => {
          const titleSegments: string[] = [];
          for (const key of keys) {
            const segment = this._translateTitles(key, vars);
            if (segment) {
              titleSegments.push(segment);
            }
          }
          const pageTitle = titleSegments.join(' - ');
          if (pageTitle) {
            return `${pageTitle} | LearnLux`;
          }
          return 'LearnLux';
        }),
      )),
      takeUntil(this._unsubscribeSub$),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('TitleTranslationService#_doTranslate$', err);
      },
      next: (pageTitle: string): void => {
        this.title.setTitle(pageTitle);
      },
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribeSub$.next();
    this._unsubscribeSub$.complete();
  }

  /** Sets additional ICU variables for translation. */
  public setAdditionalVars(vars: Record<string, string>): void {
    this._additionalICUVars$.next(vars);
  }

  /**
   * Sets the route segment title translation keys and the route queryParameters (mostly for Lesson
   * Search terms).
   */
  public setRouteTitle(keys: string[], queryParms: Params): void {
    this._routeTitleSub$.next([ keys, queryParms ]);
  }

  /**
   * For easier pushing these translations to Transifex we need to have one `TranslationService#translate` call per string/key.
   * So while this is not efficient code, it will suffice.
   */
  private _translateTitles(key: string, vars: ICUVars): string | undefined {
    switch (key) {
      case 'accounts.category.assets': {
        return this.translator.translate('Assets', {
          _key: 'accounts.category.assets',
          _comment: 'My Accounts Assets list page',
          _context: 'App Routes',
        });
      }

      case 'accounts.category.liabilities': {
        return this.translator.translate('Liabilities', {
          _key: 'accounts.category.liabilities',
          _comment: 'My Accounts Liabilities list page',
          _context: 'App Routes',
        });
      }

      case 'nav.link.my-accounts': {
        return this.translator.translate('My Accounts', {
          _key: 'nav.link.my-accounts',
          _comment: 'Page for members connected financial accounts',
          _context: 'App Routes',
        });
      }

      case 'accounts.transactions.title': {
        return this.translator.translate('Transactions', {
          _key: 'accounts.transactions.title',
          _comment: 'My Accounts Transactions list page',
          _context: 'App Routes',
        });
      }

      case 'auth.confirm-email.verify-email-link': {
        return this.translator.translate('Verify Email', {
          _key: 'auth.confirm-email.verify-email-link',
          _comment: 'Unused page where members send owndership verification emails',
          _context: 'App Routes',
        });
      }

      case 'auth.forgot-password.reset-password': {
        return this.translator.translate('Reset Password', {
          _key: 'auth.forgot-password.reset-password',
          _comment: 'Page where members reset a forgotten password',
          _context: 'App Routes',
        });
      }

      case 'auth.join.select-location': {
        return this.translator.translate('Select your location', {
          _key: 'auth.join.select-location',
          _comment: 'Page where members switch to LearnLux region specific apps from list',
          _context: 'App Routes',
        });
      }

      case 'auth.reset-password.forgot-password-link': {
        return this.translator.translate('Forgot Password', {
          _key: 'auth.reset-password.forgot-password-link',
          _comment: 'Page where members request a password reset',
          _context: 'App Routes',
        });
      }

      case 'auth.reset-password.login-link': {
        return this.translator.translate('Login', {
          _key: 'auth.reset-password.login-link',
          _comment: 'Page where members login to LearnLux',
          _context: 'App Routes',
        });
      }

      case 'budget.insights.header': {
        return this.translator.translate('Budget Insights', {
          _key: 'budget.insights.header',
          _comment: 'Page where members view insights between spending and budget',
          _context: 'App Routes',
        });
      }

      case 'forms.button.change-password': {
        return this.translator.translate('Change Password', {
          _key: 'forms.button.change-password',
          _comment: 'Page where members update their email login password. Not SSO',
          _context: 'App Routes',
        });
      }

      case 'forms.button.confirm': {
        return this.translator.translate('Confirm', {
          _key: 'forms.button.confirm',
          _comment: 'Page where members schedule their call with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'forms.login-options': {
        return this.translator.translate('Login Options', {
          _key: 'forms.login-options',
          _comment: 'Page where members configure their login methods (SSO/Email & Password)',
          _context: 'App Routes',
        });
      }

      case 'nav.link.advisor': {
        return this.translator.translate('Advisor', {
          _key: 'nav.link.advisor',
          _comment: 'RPC partner page for member access to their Advisors',
          _context: 'App Routes',
        });
      }

      case 'nav.link.benefits': {
        return this.translator.translate('Benefits', {
          _key: 'nav.link.benefits',
          _comment: 'Page where members view employer benefits',
          _context: 'App Routes',
        });
      }

      case 'nav.link.budget': {
        return this.translator.translate('Budget', {
          _key: 'nav.link.budget',
          _comment: 'Page where members create budgets',
          _context: 'App Routes',
        });
      }

      case 'nav.link.my-checkup': {
        return this.translator.translate('Checkup', {
          _key: 'nav.link.my-checkup',
          _comment: 'Member financial Checkup page',
          _context: 'App Routes',
        });
      }

      case 'nav.link.my-profile': {
        return this.translator.translate('My Profile', {
          _key: 'nav.link.my-profile',
          _comment: 'Page where member updates their member profile.',
          _context: 'App Routes',
        });
      }

      case 'nav.link.settings': {
        return this.translator.translate('Settings', {
          _key: 'nav.link.settings',
          _comment: 'Pages for members to manage account settings',
          _context: 'App Routes',
        });
      }

      case 'page-not-found.title': {
        return this.translator.translate('Page Not Found', {
          _key: 'page-not-found.title',
          _comment: 'Catchall page for URLs that do not exist',
          _context: 'App Routes',
        });
      }

      case 'page-title.accounts.connections': {
        return this.translator.translate('Connections', {
          _key: 'page-title.accounts.connections',
          _comment: 'Page where members manage connected financial accounts.',
          _context: 'App Routes',
        });
      }

      case 'page-title.advisor-admin': {
        return this.translator.translate('Planner Admin', {
          _key: 'page-title.advisor-admin',
          _comment: 'Hidden pages for LearnLux Planners',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'page-title.advisor-admin.login': {
        return this.translator.translate('Planner Admin Login', {
          _key: 'page-title.advisor-admin.login',
          _comment: 'Hidden page for LearnLux Planners to login',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'page-title.advisor.admin.user-data': {
        return this.translator.translate('Member Data', {
          _key: 'page-title.advisor.admin.user-data',
          _comment: 'Hidden page for LearnLux Planners to view member information',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'calculators.debt-payoff.title': {
        return this.translator.translate('Debt Payoff Plan', {
          _key: 'calculators.debt-payoff.title',
          _comment: 'Page for members to plan for debt payoff',
          _context: 'App Routes',
        });
      }

      case 'calculators.emergency-savings.title': {
        return this.translator.translate('Emergency Savings Calculator', {
          _key: 'calculators.emergency-savings.title',
          _comment: 'Page for members to plan for emergency savings',
          _context: 'App Routes',
        });
      }

      case 'page-title.checkup.questions': {
        return this.translator.translate('Questions', {
          _key: 'page-title.checkup.questions',
          _comment: 'Pages where members answer checkup questions',
          _context: 'App Routes',
        });
      }

      case 'page-title.confirm-email': {
        return this.translator.translate('Confirm Email', {
          _key: 'page-title.confirm-email',
          _comment: 'Unused page where members confirms ownership of emails adress',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'page-title.dashboard': {
        return this.translator.translate('Dashboard', {
          _key: 'page-title.dashboard',
          _comment: 'Landing page for members after login',
          _context: 'App Routes',
        });
      }

      case 'page-title.goals.select': {
        return this.translator.translate('Select Your Goals', {
          _key: 'page-title.goals.select',
          _comment: 'Pages where member chooses their financial goals',
          _context: 'App Routes',
        });
      }

      case 'page-title.goals.slug': {
        return this.translator.translate('Goals - {goalName}', {
          ...vars,
          _key: 'page-title.goals.slug',
          _comment: 'Pages where member examines a financial goal',
          _context: 'App Routes',
        });
      }

      case 'page-title.incentive-program': {
        return this.translator.translate('Incentive Program', {
          _key: 'page-title.incentive-programe',
          _comment: 'Page for detailed incentive program information',
          _context: 'App Routes',
        });
      }

      case 'page-title.join': {
        return this.translator.translate('Create an Account', {
          _key: 'page-title.join',
          _comment: 'Page where members can signup for LearnLux',
          _context: 'App Routes',
        });
      }

      case 'page-title.lessons': {
        return this.translator.translate('Lessons', {
          _key: 'page-title.lessons',
          _comment: 'Financial education pages.',
          _context: 'App Routes',
        });
      }

      case 'page-title.lessons.details': {
        return this.translator.translate('{lessonName} Overview', {
          ...vars,
          _key: 'page-title.lessons.slug',
          _comment: 'Page describing a specific lesson.',
          _context: 'App Routes',
        });
      }

      case 'page-title.lessons.search': {
        // `{t}` is the LessonsSearchComponent ?t= query parameter
        return this.translator.translate('Search for {t}', {
          ...vars,
          _key: 'page-title.lessons.search',
          _comment: 'Lesson Search results page',
          _context: 'App Routes',
        });
      }

      case 'page-title.lessons.viewer': {
        return this.translator.translate('Reading {lessonName}', {
          ...vars,
          _key: 'page-title.lessons.viewer',
          _comment: 'Slide presentation of educational lesson',
          _context: 'App Routes',
        });
      }

      case 'page-title.link-email': {
        return this.translator.translate('Link Email to Account', {
          _key: 'page-title.link-email',
          _comment: 'Page where members can connect additional emails to their account',
          _context: 'App Routes',
        });
      }

      case 'page-title.link-external': {
        return this.translator.translate('Link Single Sign On Method to Account', {
          _key: 'page-title.link-external',
          _comment: 'Page where members can connect additional SSO logins to their account',
          _context: 'App Routes',
        });
      }

      case 'page-title.onboarding.calculating': {
        return this.translator.translate('Calculating…', {
          _key: 'page-title.calculating',
          _comment: 'Transitional page between Onboarding Interview and Dashboard when members join',
          _context: 'App Routes',
        });
      }

      case 'page-title.onboarding.start': {
        return this.translator.translate('Welcome', {
          _key: 'page-title.onboarding.start',
          _comment: 'New account setup interview.',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner': {
        return this.translator.translate('Connect with a Planner', {
          _key: 'page-title.planner',
          _comment: 'Page where members can book calls with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'advisor.schedule.details.header': {
        return this.translator.translate('Call details', {
          _key: 'advisor.schedule.details.header',
          _comment: 'Page where members select topics before booking a call with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner.external': {
        return this.translator.translate('Connect with a Planner - Confirm', {
          _key: 'page-title.planner.external',
          _comment: 'Page where members schedule their financial planner meeting through an external form',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner.profile': {
        return this.translator.translate('Profile', {
          _key: 'page-title.planner.profile',
          _comment: 'Page where members review their LearnLux profile data before booking a call with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner.request': {
        return this.translator.translate('Planner Request', {
          _key: 'page-title.planner.request',
          _comment: 'Page where members make custome requests for a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner.review': {
        return this.translator.translate('Review My Plan', {
          _key: 'page-title.planner.review',
          _comment: 'Page where members confirm sharing their data with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'page-title.planner.status': {
        return this.translator.translate('Financial Status', {
          _key: 'page-title.planner.status',
          _comment: 'Page where members review their financial data before booking a call with a LearnLux financial planner',
          _context: 'App Routes',
        });
      }

      case 'page-title.recover-email': {
        return this.translator.translate('Recover Email', {
          _key: 'page-title.recover-email',
          _comment: 'Page where members can undo account email adress change',
          _context: 'App Routes',
        });
      }

      case 'page-title.september-challenge': {
        return this.translator.translate('September Challenge', {
          _key: 'page-title.september-challenge',
          _comment: 'Page for periodic member challenges',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'page-title.style-guide': {
        return this.translator.translate('Style Guide', {
          _key: 'page-title.style-guide',
          _comment: 'Internal page',
          _context: 'App Routes',
          _tags: 'notranslate',
        });
      }

      case 'search.results.title': {
        return this.translator.translate('Results', {
          _key: 'search.results.title',
          _comment: 'Page where member reviews checkup results suggestions',
          _context: 'App Routes',
        });
      }

      case 'settings.language.select': {
        return this.translator.translate('Select your language', {
          _key: 'settings.language.select',
          _comment: 'Page where member changes their preferred language',
          _context: 'App Routes',
        });
      }

      case 'settings.logout.title': {
        return this.translator.translate('Log out?', {
          _key: 'settings.logout.title',
          _comment: 'Page where member sign out of the LearnLux application',
          _context: 'App Routes',
        });
      }

      case 'nav.link.privacy': {
        return this.translator.translate('Privacy', {
          _key: 'nav.link.privacy',
          _comment: 'Page displaying the LearnLux member privacy policy',
          _context: 'App Routes',
        });
      }

      case 'nav.link.help-support': {
        // This string is different from the raw string in the template, but I think it will
        // effectively be the same since the HTML entity will be decoded when the template is
        // rendered.
        return this.translator.translate('Help & Support', {
          _key: 'nav.link.help-support',
          _comment: 'Page where members contact the LearnLux support team',
          _context: 'App Routes',
        });
      }

      case 'text.calculators': {
        return this.translator.translate('Calculators', {
          _key: 'text.calculators',
          _comment: 'Page listing LearnLux calculator tools',
          _context: 'App Routes',
        });
      }

      default: {
        console.error('TitleTranslationService#_translateTitles', `Missing key: '${key}'`, vars);
        return undefined;
      }
    }
  }
}
