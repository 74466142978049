// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-symbols-rounded {
  display: none;
  font-size: 2.625rem;
}
@media (min-width: 768px) {
  .material-symbols-rounded {
    display: inline;
  }
}

.list-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border: 1px solid #f1f3f3;
  border-radius: 0.375rem;
  padding: 0.75rem;
  width: 100%;
}
.list-item img {
  width: 55px;
}
.list-item .name {
  flex: 1;
  color: #181b1c;
}

a {
  position: relative;
  z-index: 1;
}
a::before {
  position: absolute;
  inset: 0;
  transition: opacity 0.15s linear;
  opacity: 0;
  z-index: -1;
  background-color: #f8f9f9;
  content: "";
}
a:hover {
  text-decoration: none;
}
a:hover::before {
  opacity: 1;
}
a:hover .name {
  text-decoration: underline;
  color: #29a59a;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
