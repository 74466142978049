import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule ],
  selector: 'lux-incentive-coming-soon-widget',
  standalone: true,
  styleUrls: [ './incentive-coming-soon-widget.component.scss' ],
  templateUrl: './incentive-coming-soon-widget.component.html',
})
export class IncentiveComingSoonWidgetComponent {
  @Input({ required: true }) public earnings!: string; // formatted rewards string
  @Input() public hasEarnings!: boolean;
  @Input() public link?: string; // link to learn more
  @Input({ required: true }) public program!: string; // user friendly program name
}
