/**
 * Custom configuration for HammerJS (Touch Even Handlers). We have to overide the default options
 * for the lessons slides to work as intended. We're leveraging Hammer JS's onSwipe to change slide
 * left/right, however we want to allow swiping up and down to vertically scroll.
 */
import { Injectable } from '@angular/core';
import type { ClassProvider } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

interface HammerOverrides {
  [key: string]: { enable: boolean };
}

@Injectable({ providedIn: 'root' })
export class GestureConfig extends HammerGestureConfig {
  // Note: All three of these options (pan, pinch, and rotate) must be disabled to support swiping
  // and scrolling in-lesson because Angular PlatformBrowser enables pinch and rotate by default.
  // https://github.com/angular/angular/blob/11d2a8cc81705e4c47656e50c8259f3e029f1589/packages/platform-browser/src/dom/events/hammer_gestures.ts#L142
  public override readonly overrides: HammerOverrides = {
    pan: {
      enable: false,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
  };
}

const configProvider: ClassProvider = {
  provide: HAMMER_GESTURE_CONFIG,
  useClass: GestureConfig,
};

export const GESTURE_PROVIDERS = [ configProvider ];
