/* eslint-disable max-lines */
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { differenceInYears, isValid, parse } from 'date-fns';
import {
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { DocumentReference, QueryConstraint } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { BudgetCalculatorService } from '@app/calculators/budget-calculator.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { LessonCalculatorService } from '@app/calculators/lesson-calculator.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { RetirementCalculatorService } from '@app/calculators/retirement-calculator.service';
import type { TaxesComputed } from '@app/calculators/tax-data';
import { TaxDataService } from '@app/calculators/tax-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { DEFAULT_USER_GOALS } from '@app/goals/goals';
import type { GoalFirestore } from '@app/goals/goals';
import type { Goal, UserGoals } from '@app/goals/goals.service';

import { mogrifyAccountSummary } from './account-summary';
import type { FormUserData, UserData, UserDataFirestore } from './user';

type UserDataFirestoreDoc = DocumentReference<UserDataFirestore>;

/**
 * This block was moved from goals service to avoid circular dependencies.

 * Sort the goals by slug/UserGoals key, except 'other' which should be last.

 * Firefox and Chrome use different sort algorithms and mean that simplifying this test is more difficult
 * than it appears. So do this the verbose way.
 */

// Array Sort Return values
const A_BEFORE_B = -1;
const A_EQUAL_B = 0;
const B_BEFORE_A = 1;

const sortGoals = (a: Goal, b: Goal): number => {
  // Sort other to the last element.
  if (b.slug === 'other') {
    return A_BEFORE_B; // sort a before b
  }
  if (a.slug === 'other') {
    return B_BEFORE_A; // sort b before a
  }

  if (a.slug < b.slug) {
    return A_BEFORE_B;
  }
  if (a.slug > b.slug) {
    return B_BEFORE_A;
  }

  return A_EQUAL_B;
};

@Injectable({ providedIn: 'root' })
export class UserDataService {
  /**
   * Retrieves the UserData for the currently logged in User
   * Throws an Error if there isn't a currently logged in User.
   */
  public readonly userData$: Observable<UserData>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly budgetCalc: BudgetCalculatorService,
    private readonly lessonCalc: LessonCalculatorService,
    private readonly retirementCalc: RetirementCalculatorService,
    private readonly taxCalc: TaxDataService,
  ) {
    this.userData$ = this.authService.currentUser$.pipe(
      map((user: FirebaseUser): UserDataFirestoreDoc => this.afStore.doc<UserDataFirestore>(`users/${user.uid}`)),
      switchMap(
        (doc: UserDataFirestoreDoc): Observable<UserDataFirestore | undefined> =>
          this.afStore.docData(doc)
            .pipe(takeUntil(this.authService.isLoggedIn$.pipe(filter((isLoggedIn: boolean): isLoggedIn is false => !isLoggedIn)))),
      ),
      switchMap((rawUserData: UserDataFirestore | undefined): Observable<UserData> => {
        // If the document is undefined, then create a new object for the forms.
        return this.mogrifyFirestore(rawUserData ?? {});
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Adapted from advisor-data.service.ts
   * Fetch a specific Client's User Data by email address
   */
  public getUserDataByEmail(email: string): Observable<UserData> {
    const constraints = [ this.afStore.where('email', '==', email) ];

    return this._getDataByQuery(constraints);
  }

  /**
   * Fetch a specific Client's User Data by ID
   * If no data is found for the given ID, returns an empty UserData object.
   */
  public getUserDataById(id: string): Observable<UserData> {
    // Create a reference to the Firestore document for the user with the given ID
    const docRef = this.afStore.doc<UserDataFirestore>(`users/${id}`);

    // Fetch the document data and add the document ID to the result
    return this.afStore.docData(docRef, { idField: 'id' }).pipe(
      // Transform the raw Firestore data into a UserData object
      switchMap((rawData: UserDataFirestore | undefined): Observable<UserData> =>
        rawData ? this.mogrifyFirestore(rawData) : of({} as UserData)),
    );
  }

  /**
   * Converts a UserDataFirestore object to a UserData object.
   * Converts birthdate from FirestoreTimestamp to formatted date string.
   * Updates the age property if birthdate is set.
   * Public so it can also be used by AdvisorDataService.
   */
  public mogrifyFirestore(rawUserData: UserDataFirestore): Observable<UserData> {
    let age: number | undefined;
    let birthdate: string | undefined;
    if (rawUserData.birthdate) {
      // Convert to Javascript Date Object
      const birthdateDate = rawUserData.birthdate.toDate();
      const today = new Date();
      age = differenceInYears(today, birthdateDate);

      // Format the 'birthdate' to a string for the HTML5 datepicker
      birthdate = formatDate(birthdateDate, 'yyyy-MM-dd', 'en-US');
    }

    // Correct old values, mostly choices changes for UserInputComponent
    const gender = (
      rawUserData.gender?.[0]
        // TitleCase the old values
        ? `${rawUserData.gender[0].toUpperCase()}${rawUserData.gender.slice(1)}`
        : undefined
    ) as UserData['gender'];
    const interestinfinadvisor: UserData['interestinfinadvisor'] = rawUserData.interestinfinadvisor
      && (rawUserData.interestinfinadvisor as unknown) === 'Maybelater'
        ? 'Maybe later'
        : rawUserData.interestinfinadvisor;
    let maritalstatus = (
      rawUserData.maritalstatus?.[0]
        // TitleCase the old values
        ? `${rawUserData.maritalstatus[0].toUpperCase()}${rawUserData.maritalstatus.slice(1)}`
        : undefined
    ) as UserData['maritalstatus'];
    if ((maritalstatus as unknown) === 'Complicated') {
      maritalstatus = 'Other';
    }
    // We may need to update this data before the calculations if they need these values.

    // Add in data that's calculated from a combinations of UserData keys.
    const computed = {
      age,
      ...this.budgetCalc.fillComputedFields(rawUserData),
      ...this.lessonCalc.fillComputedFields(rawUserData, age),
      ...this.retirementCalc.fillComputedFields(rawUserData, age),
    };

    const accountsSummary = mogrifyAccountSummary(rawUserData.accountsSummary);

    // Removed from advisor-data.service, now used here in user-data.service.ts
    const goalsCol = this.afStore.collection<GoalFirestore>('goals');
    const usrGoals = { ...DEFAULT_USER_GOALS, ...rawUserData.goals };
    const userGoals$ = this.afStore.collectionData(goalsCol)
      .pipe(
        map((rawGoals: GoalFirestore[]): Goal[] => this
          .mogrifyGoals(rawGoals, usrGoals)
          .filter((goal: Goal): boolean => goal.enabled)),
      );

    return combineLatest([
      this.taxCalc.computeTaxes(computed.totalincome, computed.monthlybudget, rawUserData),
      userGoals$,
    ]).pipe(
      map(
        ([ taxData, userGoals ]: [ TaxesComputed, Goal[] ]): UserData => ({
          ...rawUserData,
          accountsSummary,
          birthdate,
          computed: { ...computed, ...taxData },
          gender,
          interestinfinadvisor,
          maritalstatus,
          userGoals,
          visitedCheckupResults: rawUserData.visitedCheckupResults?.toDate(),
        }),
      ),
    );
  }

  /**
 * This block was moved from goals service to avoid circular dependencies.

  * Update BaseGoals with enabled status from UserGoals data.
  * This was made public for use by the AdvisorDataService
  */
  public mogrifyGoals(rawGoals: GoalFirestore[], userGoals: UserGoals): Goal[] {
    // New array of goals.
    const goals: Goal[] = [];

    // Convert the GoalFirestore objects to Goals by populating the enabled flag from user data.
    for (const rawGoal of rawGoals) {
      // custom UserGoal is a string, so cast it to a boolean
      const enabled = Boolean(userGoals[rawGoal.slug]);

      // Populate the 'enabled' field with the user's status on that goal.
      // As convert the FirestoreTimestamp to a Date
      const goal: Goal = { ...rawGoal, date: rawGoal.date.toDate(), enabled };
      // I could try to do the sort in this loop, but I think it would be less clear.
      goals.push(goal);
    }

    goals.sort(sortGoals); // Mutates the array :-(

    return goals;
  }

  /**
   * Sets the UserData.
   * Changes to the userData now calls a firebase cloud function to update mailchimp. So we only
   * want to call that once per change!
   * Throws an Error if there isn't a currently logged in User.
   */
  public updateUserData(data: FormUserData): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map((user: FirebaseUser): UserDataFirestoreDoc => this.afStore.doc<UserDataFirestore>(`users/${user.uid}`)),
      switchMap(async (userDoc: UserDataFirestoreDoc): Promise<boolean> => {
        const firestoreData: UserDataFirestore = this._mogrifyUser(data);
        // Use 'set' to ensure that document is created.
        const updatePromise = this.afStore.setDoc(userDoc, firestoreData, { merge: true });

        await updatePromise;
        return true;
      }),
    );
  }

  // Adapted from advisor-data.service.ts
  private _getDataByQuery(constraints: QueryConstraint[]): Observable<UserData> {
    const dataCol = this.afStore.collection<UserDataFirestore>('users');
    const query = this.afStore.query(dataCol, ...constraints);

    return this.afStore.collectionData(query, { idField: 'id' })
      .pipe(
        switchMap((data: UserDataFirestore[]): Observable<UserData> => {
          // Assuming you want the most recent entry, popping the last element
          const rawData: UserDataFirestore | undefined = data.pop();
          if (rawData == undefined) {
            return of({} as UserData); // Return an empty object to allow obervables to complete.
          }

          return this.mogrifyFirestore(rawData);
        }),
      );
  }

  /**
   * Converts a Form's UserData object to a UserDataFirestore object.
   * Converts birthdate from a formatted date string to a FirestoreTimestamp.
   * Updates the age property if birthdate is set.
   */
  private _mogrifyUser(userData: FormUserData): UserDataFirestore {
    const firestoreData: UserDataFirestore = { ...userData, birthdate: undefined };

    if (userData.birthdate) {
      // Somehow Ryan got an invalid date into our birthday form field and caused an error.
      // https://sentry.io/organizations/learnlux-7u/issues/895489387/
      // Check that date-fns considers the date valid before we try to pass it to Firebase.
      const birthdate = parse(userData.birthdate, 'yyyy-MM-dd', new Date());
      if (isValid(birthdate)) {
        firestoreData.birthdate = FirestoreTimestamp.fromDate(birthdate);
      } else {
        console.error('Invalid birthdate', userData.birthdate);
        delete firestoreData.birthdate;
      }
    } else {
      delete firestoreData.birthdate;
    }

    if (userData.visitedCheckupResults == undefined) {
      delete firestoreData.visitedCheckupResults;
    }

    return firestoreData;
  }
}

