/**
 * These error codes don't have a webpage (any longer/still?) so you have to get them from the code:
 * https://github.com/firebase/firebase-js-sdk/blob/69ff8eb549e49de51cae11a04bce023bb6e1fc02/packages/auth/src/core/errors.ts
 * Periodically we should review this page (note this link is a snapshot of the past) and see if there
 * are new codes to translate.
 */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { TranslationModule } from '@app/translation/translation.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, TranslationModule ],
  selector: 'lux-error-message',
  standalone: true,
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {
  public readonly code$: Observable<string>;
  public readonly organization: string;

  private readonly _codeSub$: ReplaySubject<string>;

  constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    this._codeSub$ = new ReplaySubject<string>(1);

    this.code$ = this._codeSub$.pipe(
      // ICU select expressions in Transifex only support [0-9A-Za-z_] (\w) characters it seems
      map((code: string): string => code.replaceAll(/\W/gu, '_')),
    );

    this.organization = this.appConfig.organization;
  }

  @Input({ required: true }) public set code(c: string | undefined) {
    // ICU select expressions don't seem to treat an empty string as 'other', so we need to handle that.
    this._codeSub$.next(c || 'other'); // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
  }
}
