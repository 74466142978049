import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfettiService } from './confetti.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule ],
  selector: 'lux-confetti',
  standalone: true,
  styleUrls: [ './confetti.component.scss' ],
  templateUrl: './confetti.component.html',
})
export class ConfettiComponent {
  /** Optional Message to display during animation. */
  @Input() public text: string = '';

  public readonly confetti$: Observable<number>;

  constructor(private readonly service: ConfettiService) {
    this.confetti$ = this.service.schoolPride(100);
  }
}
