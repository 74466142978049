// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-marquee {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  place-items: center;
  margin-bottom: 3rem;
  border-radius: 8px;
  background-image: url("https://static.learnlux.com/images/backgrounds/background-stars-v1.1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  height: 21.25rem;
  color: #fff;
}
@media (min-width: 768px) {
  .dashboard-marquee {
    grid-template-columns: 2fr 1fr;
  }
}
.dashboard-marquee .marquee-info {
  padding: 1rem 1rem 1rem 2rem;
  width: 100%;
  text-align: left;
}
.dashboard-marquee .marquee-info p {
  padding: 1rem 0;
}
.dashboard-marquee img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.btn-primary {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  width: 10.4375rem;
  height: 3rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
}

.btn-text {
  padding-inline: 0;
  text-decoration: none;
  color: #fff;
  font-size: 0.875rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
