import { Injectable } from '@angular/core';
import type { AuthProvider } from '@angular/fire/auth';
import { from, map, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService, FirebaseOAuthProvider, FirebaseSAMLAuthProvider } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FirebaseUser, FirebaseUserCredential } from '@app/angular-fire-shims/angular-fire-auth.service';

import { AuthService } from './auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export interface ProviderLogin {
  isNewUser: boolean;
  profile?: Record<string, string>;
  user?: FirebaseUser;
}

@Injectable({ providedIn: 'root' })
export class ProviderLoginService {
  constructor(
    private readonly afAuth: AngularFireAuthService,
    private readonly authService: AuthService,
  ) {}

  /**
   * Associates EXISTING email and password credential with a NEW external provider credential Account.
   * This is intended to be used for external federated auth provider users who also have an  email
   * and password to login when provider login isn't available. (E.g. Outside of the company's network.)
   * https://firebase.google.com/docs/auth/web/account-linking
   */
  public linkProvider(authProvider: string, providerId: string): Observable<ProviderLogin> {
    const provider = this._getAuthProvider(authProvider, providerId);

    return this.authService.requireUser$.pipe(
      switchMap(async (user: FirebaseUser): Promise<FirebaseUserCredential> => this.afAuth.linkWithPopup(user, provider)),
      map((credentials: FirebaseUserCredential): ProviderLogin => this._createProviderResult(credentials)),
    );
  }

  /**
   * Uses an external federated auth provider to sign in a user.
   * Updates this.isLoggedIn to true if login is successful. isLoggedIn being true doesn't necessarily
   * mean that Firebase currently considers the user signed in. this.currentUser$ will always reflect
   * the current authenticated user from Firebase.
   * Returned Observable will contain some additional fields from the external federated auth provider
   * that we can use to setup our user record in ProviderLoginComponent
   */
  public providerLogin(authProvider: string, providerId: string): Observable<ProviderLogin> {
    const provider = this._getAuthProvider(authProvider, providerId);

    const signInPromise = this.afAuth.signInWithPopup(provider);
    return from(signInPromise).pipe(
      map((credentials: FirebaseUserCredential): ProviderLogin => this._createProviderResult(credentials)),
    );
  }

  /**
   * Redirects to an external federated auth provider to begin the sign in process.
   */
  public providerRedirect(authProvider: string, providerId: string): Observable<never> {
    const provider = this._getAuthProvider(authProvider, providerId);

    return from(this.afAuth.signInWithRedirect(provider));
  }

  /**
   * Compainion to `providerRedirect` which will handle the result of a redirect flow login.
   */
  public providerRedirectResult(): Observable<ProviderLogin> {
    const resultPromise = this.afAuth.getRedirectResult();
    return from(resultPromise).pipe(
      map((credentials: FirebaseUserCredential | null): ProviderLogin => {
        if (credentials) {
          return this._createProviderResult(credentials);
        }

        throw new Error('Unknown redirect login error');
      }),
    );
  }

  private _createProviderResult(credentials: FirebaseUserCredential): ProviderLogin {
    const additionalUserInfo = this.afAuth.getAdditionalUserInfo(credentials);
    return {
      isNewUser: additionalUserInfo?.isNewUser ?? false,
      profile: additionalUserInfo?.profile as Record<string, string> | undefined ?? undefined,
      user: credentials.user,
    };
  }

  private _getAuthProvider(authProvider: string, providerId: string): AuthProvider {
    if (authProvider === 'SAML') {
      return new FirebaseSAMLAuthProvider(providerId);
    }

    if (authProvider === 'OIDC') {
      return new FirebaseOAuthProvider(providerId);
    }

    throw new Error(`Unknown provider config ${authProvider}`);
  }
}
