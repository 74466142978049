/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { CollectionReference, DocumentReference, Query } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { mogrifyAccountSummary } from './account-summary';
import type {
  Account,
  AccountFirestore,
  ManualAccountFields,
  PlaidConnection,
  PlaidConnectionFirestore,
  Transaction,
  TransactionFirestore,
} from './user-accounts';
import { EVENTS, UserTrackingService } from './user-tracking.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export type {
  Account,
  ManualAccountFields,
  PlaidConnection,
  Transaction,
};

export type DebtPayoffManualData = Pick<ManualAccountFields, 'interestRatePercentage' | 'minimumPaymentAmount'>;

// Array Sort Return values
const A_BEFORE_B = -1;
const A_EQUAL_B = 0;
const B_BEFORE_A = 1;

const sortAccounts = (a: Account, b: Account): number => {
  // Do I want to normalize the lettercase before comparison?
  if (a.accountType < b.accountType) {
    return A_BEFORE_B;
  }
  if (a.accountType > b.accountType) {
    return B_BEFORE_A;
  }

  const aSubtype = a.accountSubtype ?? 'zzzzz';
  const bSubtype = b.accountSubtype ?? 'zzzzz';
  if (aSubtype < bSubtype) {
    return A_BEFORE_B;
  }
  if (aSubtype > bSubtype) {
    return B_BEFORE_A;
  }

  if (a.accountName < b.accountName) {
    return A_BEFORE_B;
  }
  if (a.accountName > b.accountName) {
    return B_BEFORE_A;
  }

  return A_EQUAL_B;
};

const sortConnections = (a: PlaidConnection, b: PlaidConnection): number => {
  // Do I want to normalize the lettercase before comparison?
  if (a.institutionName < b.institutionName) {
    return A_BEFORE_B;
  }
  if (a.institutionName > b.institutionName) {
    return B_BEFORE_A;
  }

  if (a.createdAt < b.createdAt) {
    return A_BEFORE_B;
  }
  if (a.createdAt > b.createdAt) {
    return B_BEFORE_A;
  }

  return A_EQUAL_B;
};

const TRANSACTION_LIMIT = 20; // Default number of transactions to fetch.

@Injectable({ providedIn: 'root' })
export class UserAccountsService {
  /** User's Accounts that are not hidden. */
  public readonly accounts$: Observable<Account[]>;

  /** User's Accounts including hidden and not. */
  public readonly allAccounts$: Observable<Account[]>;

  /** User's Accounts that are hidden. */
  public readonly hiddenAccounts$: Observable<Account[]>;

  /** Plaid Connections to financial institutions. */
  public readonly plaidConnections$: Observable<PlaidConnection[]>;

  constructor(
    private readonly afFns: AngularFireFunctionsService,
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this.allAccounts$ = this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<AccountFirestore> =>
          this.afStore.collection<AccountFirestore>(`users/${user.uid}/accounts`),
      ),
      switchMap(
        (collRef: CollectionReference<AccountFirestore>): Observable<AccountFirestore[]> =>
          this.afStore.collectionData(collRef, { idField: 'id' }), // Need the IDs
      ),
      // Format Dates
      map((rawAccounts: AccountFirestore[]): Account[] => this.mogrifyFirestore(rawAccounts)),
      // Sort Account type, subtype and then name. Mutates Array!
      tap((accounts: Account[]): void => { accounts.sort(sortAccounts); }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.accounts$ = this.allAccounts$.pipe(
      map((allAccounts: Account[]): Account[] => allAccounts.filter((acct: Account): boolean => !acct.hidden)),
    );

    this.hiddenAccounts$ = this.allAccounts$.pipe(
      map((allAccounts: Account[]): Account[] => allAccounts.filter((acct: Account): boolean => !!acct.hidden)),
    );

    this.plaidConnections$ = this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<AccountFirestore> =>
          this.afStore.collection<AccountFirestore>(`users/${user.uid}/plaid-connections`),
      ),
      switchMap(
        (collRef: CollectionReference<PlaidConnectionFirestore>): Observable<PlaidConnectionFirestore[]> =>
          this.afStore.collectionData(collRef), // IDs are in the data as itemId field
      ),
      // Format Dates
      map((rawConnections: PlaidConnectionFirestore[]): PlaidConnection[] => {
        return rawConnections.map((raw: PlaidConnectionFirestore): PlaidConnection => {
          return {
            ...raw,
            createdAt: raw.createdAt.toDate(),
            lastFailedUpdate: raw.lastFailedUpdate?.toDate(),
            lastModified: raw.lastModified.toDate(),
            lastSuccessfulUpdate: raw.lastSuccessfulUpdate?.toDate(),
          };
        });
      }),
      // Sort Account type, subtype and then name. Mutates Array!
      tap((accounts: PlaidConnection[]): void => { accounts.sort(sortConnections); }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /** Create a new manual account for the user and update account balance totals. */
  public addManualAccount(data: ManualAccountFields): Observable<void> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<AccountFirestore> =>
          this.afStore.collection<AccountFirestore>(`users/${user.uid}/accounts`),
      ),
      switchMap(async (colRef: CollectionReference<AccountFirestore>): Promise<void> => {
        const nowTs = FirestoreTimestamp.now();

        const payload: AccountFirestore = {
          ...data,
          balanceCurrency: 'USD',
          createdAt: nowTs,
          hidden: false,
          institutionId: '',
          itemId: '',
          lastModified: nowTs,
          manual: true,
        };

        await this.afStore.addDoc(colRef, payload);
        // Nested because we want the payload for the trackEvent
        this.userTrackingService.trackEvent(EVENTS.myAccountsManualAccountAdded, { category: payload.category });
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }

  /** Delete an existing manual account. */
  public deleteManualAccount(id: string): Observable<void> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<AccountFirestore> =>
          this.afStore.doc<AccountFirestore>(`users/${user.uid}/accounts/${id}`),
      ),
      switchMap(async (docRef: DocumentReference<AccountFirestore>): Promise<void> => this.afStore.deleteDoc(docRef)),
      tap((): void => {
        this.userTrackingService.trackEvent(EVENTS.myAccountsManualAccountRemoved);
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }

  /** Fetches the list of transactions for an account. Limited to the most recent. */
  public getAccountTransactions(accountId: string, limit: number = TRANSACTION_LIMIT): Observable<Transaction[]> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): Query<TransactionFirestore> => {
          const transactionsCol = this.afStore.collection<TransactionFirestore>(`users/${user.uid}/accounts/${accountId}/transactions`);
          return this.afStore.query(
            transactionsCol,
            this.afStore.orderBy('postedDate', 'desc'),
            this.afStore.limit(limit),
          );
        },
      ),
      switchMap(
        (collRef: Query<TransactionFirestore>): Observable<TransactionFirestore[]> =>
          this.afStore.collectionData(collRef, { idField: 'id' }),
      ),
      map((rawTransactions: TransactionFirestore[]): Transaction[] => rawTransactions.map(
        (rawTransaction: TransactionFirestore): Transaction => ({
          ...rawTransaction,
          createdAt: rawTransaction.createdAt.toDate(),
          lastModified: rawTransaction.lastModified.toDate(),
          postedDate: rawTransaction.postedDate.toDate(),
        }),
      )),
    );
  }

  /** Generates a name for account using the institutionName, accountName, and accountMask for URL navigation. */
  public getSafeAccountName(account: Account): string {
    const name = `${account.institutionName}${account.accountName}`.replaceAll(/[^\d\p{Letter}\p{Mark}]+/gui, '').toLowerCase();
    const cleanName = `${name.slice(0, 10)}${name.slice(-10)}`;
    const cleanMask = !account.accountMask || name.endsWith(account.accountMask) ? '' : account.accountMask;
    return `${cleanName}${cleanMask}`;
  }

  /**
   * Hide an account for the user and update account balance totals excluding hidden accounts.
   * Should never be applied to Manual Accounts!
   */
  public hideAccount(accountId: string): Observable<void> {
    return this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<AccountFirestore> =>
          this.afStore.doc<AccountFirestore>(`users/${user.uid}/accounts/${accountId}`),
      ),
      switchMap(async (ref: DocumentReference<AccountFirestore>): Promise<void> => this.afStore.updateDoc(ref, { hidden: true })),
      tap((): void => {
        // Track hide event
        this.userTrackingService.trackEvent(EVENTS.myAccountsAccountHidden);
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }

  /**
   * Converts a list of AccountFirestore objects to a list of Account objects.
   * Public so it can also be used by AdvisorDataService.
   */
  public mogrifyFirestore(rawAccounts: AccountFirestore[]): Account[] {
    return rawAccounts.map((raw: AccountFirestore): Account => {
      const summary = mogrifyAccountSummary(raw.summary);

      return {
        ...raw,
        createdAt: raw.createdAt.toDate(),
        itemLastUpdated: raw.itemLastUpdated?.toDate(),
        lastModified: raw.lastModified.toDate(),
        summary,
      };
    });
  }

  /**
   * Show (un-hide) an account for the user and update account balance totals excluding hidden accounts.
   * Should never be applied to Manual Accounts!
   */
  public showAccount(accountId: string): Observable<void> {
    return this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<AccountFirestore> =>
          this.afStore.doc<AccountFirestore>(`users/${user.uid}/accounts/${accountId}`),
      ),
      switchMap(async (ref: DocumentReference<AccountFirestore>): Promise<void> => this.afStore.updateDoc(ref, { hidden: false })),
      tap((): void => {
        // Track unhide event
        this.userTrackingService.trackEvent(EVENTS.myAccountsAccountUnhidden);
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }

  /**
   * Triggers an update for balance totals of the current user.
   * This immediately emits a value so we don't wait for the update function to execute before displaying to the user.
   */
  public updateAccountBalances(): Observable<void> {
    const plaidUpdateAccounts = this.afFns.httpsCallable<undefined, undefined>('plaidUpdateAccountBalances');
    return plaidUpdateAccounts(undefined).pipe(startWith(undefined));
  }

  /**
   * Update an existing manual account. Updates the lastModified field.
   * If the id does not exist, Firestore will throw an error.
   * If the id is for a plaid account, this may mangle the data. Which would be bad!
   */
  public updateManualAccount(accountId: string, data: Partial<ManualAccountFields>): Observable<void> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<AccountFirestore> =>
          this.afStore.doc<AccountFirestore>(`users/${user.uid}/accounts/${accountId}`),
      ),
      switchMap(async (docRef: DocumentReference<AccountFirestore>): Promise<void> => {
        const lastModified = FirestoreTimestamp.now();

        const payload: Partial<Omit<AccountFirestore, 'summary'>> = {
          ...data,
          lastModified,
        };

        return this.afStore.updateDoc(docRef, payload);
      }),
      tap((): void => {
        this.userTrackingService.trackEvent(EVENTS.myAccountsManualAccountUpdated);
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }

  /**
   * Update an existing plaid account's interest and minimum payment fields manually.
   * Updates the lastModified field also, but for Plaid accouns they use itemLastUpdated.
   * If the id does not exist, Firestore will throw an error.
   * If the data includes other fields this may corrupt the plaid data. Which would be bad!
   */
  public updatePlaidAccountData(accountId: string, data: DebtPayoffManualData): Observable<void> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<AccountFirestore> =>
          this.afStore.doc<AccountFirestore>(`users/${user.uid}/accounts/${accountId}`),
      ),
      switchMap(async (docRef: DocumentReference<AccountFirestore>): Promise<void> => {
        const lastModified = FirestoreTimestamp.now();

        const payload: Partial<Omit<AccountFirestore, 'summary'>> = {
          ...data,
          lastModified,
        };

        return this.afStore.updateDoc(docRef, payload);
      }),
      tap((): void => {
        this.userTrackingService.trackEvent(EVENTS.myAccountsPlaidAccountDataUpdated);
      }),
      switchMap((): Observable<void> => this.updateAccountBalances()),
    );
  }
}
