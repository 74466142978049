import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FirebaseError } from '@angular/fire/app';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  of,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { AuthConfig, SelfSignupConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { DirtyErrorsPipe } from '@app/pipes/dirty-errors/dirty-errors.pipe';
import { InvalidFormPipe } from '@app/pipes/invalid-form/invalid-form.pipe';
import { AutofocusDirective } from '@app/stuff/autofocus/autofocus.directive';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { AuthService } from '../../auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { SessionListenerService } from '../../../session-listener.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EmailPassLoginService } from '../../email-pass-login.service';

interface FormValues {
  email: string;
  password: string;
}

interface ViewModelSubject {
  errorCode: string;
  showForm: boolean;
}

export interface ViewModel extends ViewModelSubject {
  signupEnabled: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutofocusDirective,
    AutofocusDirective,
    CommonModule,
    DirtyErrorsPipe,
    ErrorMessageComponent,
    InvalidFormPipe,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-email-login',
  standalone: true,
  templateUrl: './email-login.component.html',
})
export class EmailLoginComponent {
  public readonly authForm: FormGroup;
  public readonly emailCntrl: FormControl;
  public readonly passCntrl: FormControl;
  public readonly vm$: Observable<ViewModel>;

  private readonly _signupEnabled$: Observable<boolean>;
  private readonly _submitSub$: Subject<FormValues>;
  private readonly _vmSub$: BehaviorSubject<ViewModelSubject>;

  constructor(
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    private readonly emailLoginService: EmailPassLoginService,
    private readonly router: Router,
    private readonly sessionListenerService: SessionListenerService,
  ) {
    this._submitSub$ = new Subject<FormValues>();
    this._vmSub$ = new BehaviorSubject<ViewModelSubject>({
      errorCode: '',
      showForm: true,
    });

    this._submitSub$.pipe(
      // login is created from a promise, so it will only ever emit once.
      switchMap(
        ({ email, password }: FormValues): Observable<boolean> => this.emailLoginService.login(email, password)
          .pipe(
            catchError((err: unknown): Observable<never> => {
              if (err instanceof Error) {
                this._handleError(err);
              }
              return EMPTY;
            }),
            switchMap((): Observable<boolean> => this.clientConfig.authConfig$.pipe(
              take(1),
              switchMap((authConfig: AuthConfig): Observable<boolean> => {
                if (authConfig.sessionTimeout.enabled) {
                  return this.sessionListenerService.sessionIdsSynced$.pipe(
                    filter((sessionIdsSynced: boolean): boolean => sessionIdsSynced),
                    take(1),
                    map((): boolean => true),
                  );
                }
                return of(true);
              }),
            )),
          ),
      ),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => { console.error('EmailLoginComponent#subscribe#error', err); },
      next: (sessionIdsSynced: boolean | undefined): void => {
        if (sessionIdsSynced) {
          this._handleSuccess();
        } else {
          this._handleFailure();
        }
      },
    });

    this._signupEnabled$ = this.clientConfig.selfSignupConfig$.pipe(
      map((config: SelfSignupConfig): boolean => config.enabled),
    );

    this.emailCntrl = new FormControl('', [ Validators.required, Validators.email ]);
    this.passCntrl = new FormControl('', Validators.required);
    this.authForm = new FormGroup({
      email: this.emailCntrl,
      password: this.passCntrl,
    });

    this.vm$ = combineLatest([
      this._signupEnabled$,
      this._vmSub$,
    ]).pipe(
      map(([ signupEnabled, { errorCode, showForm } ]: [ boolean, ViewModelSubject ]): ViewModel => ({
        errorCode,
        showForm,
        signupEnabled,
      })),
    );
  }

  public onSubmit(): void {
    if (this.authForm.invalid) {
      throw new Error('Form Invalid');
    }

    this._vmSub$.next({
      errorCode: '',
      showForm: false, // Hide form to prevent multiple submission.
    });

    const { email, password } = this.authForm.value as FormValues;

    this._submitSub$.next({ email, password });
  }

  private _handleError(err: Error | FirebaseError): void {
    console.warn('EmailLoginComponent#handleError', err);

    // If the error is that no user exists, and signup is enabled, let's recommend to the user that
    // they should create an account.
    this._vmSub$.next({
      errorCode: 'code' in err ? err.code : 'other',
      showForm: true,
    });
  }

  private _handleFailure(): void {
    console.warn('EmailLoginComponent#handleFailure', 'Unknown Error');
    this._vmSub$.next({
      errorCode: 'other',
      showForm: true,
    });
  }

  private _handleSuccess(): void {
    // Firebase auth.signInWithEmailAndPassword returns a UserCredentials object that has nullable
    // properties, so it is possible for this login to not error, but also not login, possibly.
    // Redirect the user

    this.router.navigateByUrl(this.authService.redirectUrl)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err: unknown): void => {
        console.error('EmailLoginComponent#handleSuccess#navigateByUrl', err);
      });
  }
}
