import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { map } from 'rxjs';
import type { Observable } from 'rxjs';

import { AccountsHeaderComponent } from '@app/accounts/accounts-header/accounts-header.component';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { LinkModalComponent } from '@app/auth/link/link-modal/link-modal.component';
import { BudgetChartComponent } from '@app/budget/budget-chart/budget-chart.component';
import { GoalsPreviewComponent } from '@app/goals/goals-preview/goals-preview.component';
import { FifthThirdIncentiveWidgetComponent } from '@app/incentive-program/fifth-third-incentive-widget/fifth-third-incentive-widget.component'; // eslint-disable-line @stylistic/max-len
import { MrCooperIncentiveWidgetComponent } from '@app/incentive-program/mr-cooper-incentive-widget/mr-cooper-incentive-widget.component';
import { AskQuestionButtonsComponent } from '@app/stuff/ask-question/ask-question-buttons/ask-question-buttons.component';
import { BannerComponent } from '@app/stuff/banner/banner.component';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { SuggestionsListComponent } from '@app/suggestions/suggestions-list/suggestions-list.component';
import { TranslationModule } from '@app/translation/translation.module';
import { ScrollerService } from '@app/utilities/scroller.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { MarqueeComponent } from './marquee/marquee.component';
import { ProfileProgressService } from './profile-progress.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ProfileProgress } from './profile-progress.service';

export interface ViewModel {
  organization: string;
  profileProgress: ProfileProgress;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AccountsHeaderComponent,
    AskQuestionButtonsComponent,
    LinkModalComponent,
    BannerComponent,
    BudgetChartComponent,
    CommonModule,
    DashboardMenuComponent,
    FifthThirdIncentiveWidgetComponent,
    GoalsPreviewComponent,
    MarqueeComponent,
    MrCooperIncentiveWidgetComponent,
    RouterModule,
    SpinnerComponent,
    SuggestionsListComponent,
    TranslationModule,
  ],
  selector: 'lux-dashboard',
  standalone: true,
  styleUrls: [ './dashboard.component.scss' ],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly profileProgress: ProfileProgressService,
    private readonly router: Router,
    private readonly scroller: ScrollerService,
  ) {
    this.vm$ = this.profileProgress.progress$.pipe(
      map((progress: ProfileProgress): ViewModel => this._getViewModel(progress)),
      this.scroller.scrollAfterRender({ polling: true }),
    );
  }

  private _getViewModel(progress: ProfileProgress): ViewModel {
    // Redirect from /dashboard to /lessons if the core digital features are disabled.
    if (progress.steps.budget === 'disabled' && progress.steps.checkup === 'disabled' && progress.steps.goals === 'disabled') {
      this.router.navigateByUrl('/lessons', { replaceUrl: true })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err: unknown): void => {
          console.error('DashboardComponent#getViewModel#LessonRedirect', err);
        });
    }

    return {
      // This needs to be used by reference here so the tests pass. In practice this value never changes at run time.
      organization: this.appConfig.organization,
      profileProgress: progress,
    };
  }
}
