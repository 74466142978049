import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import type { Observable } from 'rxjs';

import type { RpcConfigOrUndef } from '@app/client-config/client-config';
import { ClientConfigService } from '@app/client-config/client-config.service';

export const rpcEnabledGuard = (): Observable<boolean> => {
  const clientConfig = inject(ClientConfigService);

  return clientConfig.rpcConfig$.pipe(
    take(1), // RouterGuards that return observables must complete!
    // Routes that return false will fall through to the PageNotFound wildcard ('**') route.
    map((config: RpcConfigOrUndef): boolean => config?.enabled ?? false),
  );
};
