import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  combineLatest,
  map,
  ReplaySubject,
  switchMap,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { GoalsBadgeComponent } from '@app/goals/goals-badge/goals-badge.component';
import { RelativeDateComponent } from '@app/stuff/relative-date/relative-date.component';
import { TranslationModule } from '@app/translation/translation.module';
import { UserProgressService } from '@app/users/user-progress.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { UserProgress } from '@app/users/user-progress.service';

import type { Lesson } from '../lessons';
import { ProgressButtonComponent } from '../progress-button/progress-button.component';
import { LessonsService } from '../services/lessons.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export interface ViewModel {
  hasCompletedLesson: boolean;
  lesson: Lesson;
  progress?: UserProgress;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    GoalsBadgeComponent,
    ProgressButtonComponent,
    RelativeDateComponent,
    RouterModule,
    TranslationModule,
  ],
  selector: 'lux-lesson-list-item',
  standalone: true,
  styleUrls: [ './list-item.component.scss' ],
  templateUrl: './list-item.component.html',
})
export class LessonsListItemComponent {
  /** (Optional) Show item number bullet in the list. */
  @Input() public index?: number;
  /** Open link in a new tab? */
  @Input() public newTab: boolean = false;
  public readonly vm$: Observable<ViewModel | undefined>;

  private readonly _lessonSlugSub$: ReplaySubject<string>;

  constructor(
    private readonly lessonsService: LessonsService,
    private readonly progressService: UserProgressService,
  ) {
    this._lessonSlugSub$ = new ReplaySubject<string>(1);

    this.vm$ = this._lessonSlugSub$.pipe(
      switchMap((slug: string): Observable<ViewModel | undefined> => {
        return combineLatest([
          this.lessonsService.getLessonBySlug(slug).pipe(
            // TODO: ROB 20231214 - LessonsListItemComponent needs to filter out hidden and inactive lessons
            tap((lesson: Lesson): void => {
              if (lesson.replacementSlug) {
                this._lessonSlugSub$.next(lesson.replacementSlug);
              }
            }),
            map((lesson: Lesson): Lesson | undefined => {
              if (lesson.hidden) {
                return undefined;
              }
              return lesson;
            }),
          ),
          this.progressService.getLessonProgress(slug),
        ]).pipe(
          map(([ lesson, progress ]: [ Lesson | undefined, UserProgress | undefined ]): ViewModel | undefined => {
            if (lesson == undefined) {
              return undefined;
            }

            const hasCompletedLesson = progress ? progress.maxSlide >= progress.totalSlides : false;

            return { hasCompletedLesson, lesson, progress };
          }),
        );
      }),
    );
  }

  /**
   * Unique key for a lesson.
   * Subject gets initialized by the slug `@Input`, but if that lesson has a replacement then we emit
   * the replacementSlug so that both getLessonBySlug and getLessonProgress are reading the same slug.
   */
  @Input({ required: true }) public set slug(s: string) {
    this._lessonSlugSub$.next(s);
  }
}
