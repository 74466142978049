// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  margin-bottom: 1rem;
  border: 1px solid #f1f3f3;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 1.25rem;
}
.card .card-title {
  padding: 1rem;
}
.card.coming-soon .card-title {
  margin-bottom: 1rem;
  border-bottom: 1px solid #d6dbdc;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
