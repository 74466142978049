import { inject } from '@angular/core';

import { APP_CONFIG } from '../app-config/app-config';

export type OrgEnabledGuard = () => boolean;

export const organizationGuardFactory = (org: string): OrgEnabledGuard => {
  return (): boolean => {
    const appConfig = inject(APP_CONFIG);

    return appConfig.organization === org;
  };
};
