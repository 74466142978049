import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { DefaultCurrencyPipe, isValue, strToNumber } from '@app/pipes/default-currency/default-currency.pipe';

@Pipe({ name: 'luxAccountingCurrency', standalone: true })
export class AccountingCurrencyPipe implements PipeTransform {
  constructor(private readonly defaultCurrency: DefaultCurrencyPipe) {}

  public transform(value: number | string | null | undefined): string | null {
    if (!isValue(value)) {
      return null; // eslint-disable-line unicorn/no-null
    }

    const num = strToNumber(value);

    if (num < 0) {
      const absoluteValue = this.defaultCurrency.transform(Math.abs(num));
      return absoluteValue == undefined ? '' : `(${absoluteValue})`;
    }

    return this.defaultCurrency.transform(num);
  }
}
