import { CommonModule } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';  // eslint-disable-line @typescript-eslint/consistent-type-imports
import { interval, Subject, takeUntil } from 'rxjs';

import { TranslationModule } from '@app/translation/translation.module';

type ModalAction = 'sessionExpired' | 'stayLoggedIn';

@Component({
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [ CommonModule, TranslationModule ],
  selector: 'lux-timeout-modal',
  standalone: true,
  styleUrls: [ './timeout-modal.component.scss' ],
  templateUrl: './timeout-modal.component.html',
})
export class TimeoutModalComponent implements OnInit {
  @Input() public countdownDuration: number = 120;

  public countdown: string = '';
  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(
    public readonly activeModal: NgbActiveModal,
  ) {}

  // Hide the modal and stop the countdown
  public closeModal(modalAction: ModalAction): void {
    this._destroy$.next(); // Signal to all subscriptions to stop
    this._destroy$.complete(); // Complete the subject to prevent future emissions

    // Close modal and indicate stay logged in
    this.activeModal.close(modalAction);
  }

  public ngOnInit(): void {
    this.startCountdown();
  }

  // Show the modal with countdown timer
  public startCountdown(): void {
    let remainingTime = this.countdownDuration;

    this.countdown = this._formatTime(remainingTime);

    interval(1000).pipe(
      takeUntil(this._destroy$),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Countdown error:', err);
      },
      next: (): void => {
        remainingTime--;
        this.countdown = this._formatTime(remainingTime);

        // Auto logout if the countdown reaches zero
        if (remainingTime <= 0) {
          this.closeModal('sessionExpired');
        }
      },
    });
  }

  // Format countdown as mm:ss
  private _formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}
