import { InjectionToken } from '@angular/core';
import { tx } from '@transifex/native';
// For some reason this import is undefined during tests. Not sure why.
// import type { TxNative } from '@transifex/native';

export type TxNative = typeof tx;

export const TX = new InjectionToken<TxNative>(
  'tx',
  {
    factory: (): TxNative => tx,
    providedIn: 'root',
  },
);
