import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  catchError,
  EMPTY,
  Subject,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';

import { LocaleService } from '../locale/locale.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { LanguageConfig } from '../locale/locale.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, TrackByPropertyDirective, TranslationModule ],
  selector: 'lux-language-picker-footer',
  standalone: true,
  styleUrls: [ './language-picker-footer.component.scss' ],
  templateUrl: './language-picker-footer.component.html',
})
export class LanguagePickerFooterComponent {
  public readonly vm$: Observable<LanguageConfig>;

  private readonly _selectedLocaleSub$: Subject<string>;

  constructor(private readonly localeService: LocaleService) {
    this._selectedLocaleSub$ = new Subject<string>();

    // So long as this element exists in the view it will immediately setCurrentLocale in Transifex
    // and then wait for the currentUser$ to emit to update the language UserPreference
    this._selectedLocaleSub$.pipe(
      switchMap((locale: string): Observable<void> => this.localeService.setLocale(locale).pipe(
        catchError((err: unknown): Observable<never> => {
          console.error('LanguagePickerFooterComponent#_updateLocale$', err);
          return EMPTY;
        }),
      )),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('LanguagePickerFooterComponent#_updateLocale$.subscribe', err);
      },
    });

    this.vm$ = this.localeService.languages$;
  }

  /**
   * Sets the locale based on the users selection and
   * auth status. If the user is not logged in, change
   * the local locale as well
   * @param locale - Locale to set
   */
  public setLocale(locale: string): void {
    this._selectedLocaleSub$.next(locale);
  }
}
