import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { map, startWith } from 'rxjs';
import type { Observable } from 'rxjs';

@Pipe({ name: 'luxInvalidForm', standalone: true })
export class InvalidFormPipe implements PipeTransform {
  public transform(frm: AbstractControl): Observable<boolean> {
    return frm.statusChanges.pipe(
      startWith<string>(frm.status),
      map((status: string): boolean => status === 'INVALID'),
    );
  }
}
