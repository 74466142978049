import { InjectionToken } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

export type SentryType = typeof Sentry;

export type SentryScope = Sentry.Scope;

export const SENTRY = new InjectionToken<SentryType>(
  'sentry',
  {
    factory: (): SentryType => Sentry,
    providedIn: 'root',
  },
);
