import type { IncentiveConfig } from '@app/client-config/client-config';

/**
 * @returns the configured Incentive Program ID from configuration based on the current date.
 */
export const getProgramId = (config: IncentiveConfig | undefined): string | undefined => {
  if (config) {
    const now = new Date();
    if (now >= config.startAt && config.endAt >= now) {
      return config.programId;
    }
  }
  return undefined;
};
