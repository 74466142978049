import { DOCUMENT, ViewportScroller } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { Inject, Injectable, NgZone } from '@angular/core'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ActivatedRoute } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { tap } from 'rxjs';
import type { MonoTypeOperatorFunction } from 'rxjs';

import { tapOnce } from './rxjs-tap-once';

/**
 * Smooth scrolling is accomplished by setting `scroll-behavior: smooth;` on the `<html>` element in
 * styles.scss.
 * Safari supports: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior#browser_compatibility
 */
@Injectable({ providedIn: 'root' })
export class ScrollerService {
  /**
   * Keep track of the current amount of available scroll and wait for it to be stable for a bit
   * before scrolling.
   */
  private _prevHeight: number = 0;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly ngZone: NgZone,
    private readonly route: ActivatedRoute,
    private readonly viewportScroller: ViewportScroller,
  ) {}

  /**
   * After the component has rendered the observable used in the page `ngIf | async` then scroll to
   * the hash/fragment from the current page's URL if it exists.
   */
  public scrollAfterRender<T>({ multiple, polling }: { multiple?: boolean; polling?: boolean } = {}): MonoTypeOperatorFunction<T> {
    this._prevHeight = this.document.body.scrollHeight;

    const checkFn = polling
      ? this._pollScrollHeight
      : (): void => {
        setTimeout(this._doScroll, 0); // Wait for rendering to finish
      };

    const tapFn = (): void => {
      this.ngZone.runOutsideAngular(checkFn);
    };

    if (multiple) {
      return tap<T>(tapFn);
    }

    return tapOnce<T>(tapFn);
  }

  /** Do a smooth scroll to the identifier in the page. */
  private readonly _doScroll = (): void => {
    // Could subscribe to this.route.fragment here, but we don't actually care about changes, we
    // just want the first/current hash when obs emits the first time.
    const hash = this.route.snapshot.fragment;

    if (hash) {
      this.viewportScroller.scrollToAnchor(hash);
    }
  };

  /**
   * Scroll to the anchor, but also do a check half a second after the scroll to see if the height
   * changed again and we need to scroll again. Rob thinks that the scrolling will actually cause
   * the next check to succeed without a 500ms delay, but I'd rather wait and play it safe.
   */
  private readonly _doScrollWithFinalCheck = (): void => {
    this._doScroll();

    // Firefox sometimes seems to not wait long enough for the height to settle.
    // So do one last long wait to see if there was a change in height after the first scrolling.
    setTimeout(
      (): void => {
        const height = this._getScrollHeight();
        if (this._prevHeight !== height) {
          this._doScroll();
        }
      },
      500, // .5 seconds
    );
  };

  /** Based on https://javascript.info/size-and-scroll-window#width-height-of-the-document */
  private readonly _getScrollHeight = (): number => {
    return Math.max(
      this.document.body.scrollHeight,
      this.document.body.offsetHeight,
      this.document.body.clientHeight,
      this.document.documentElement.scrollHeight,
      this.document.documentElement.offsetHeight,
      this.document.documentElement.clientHeight,
    );
  };

  /** Poll for a stable ScrollHeight before scrolling. */
  private readonly _pollScrollHeight = (): void => {
    // Only do something if we have a fragment to scroll to!
    if (this.route.snapshot.fragment) {
      setTimeout(
        (): void => {
          const height = this._getScrollHeight();
          if (this._prevHeight === height) {
            this._doScrollWithFinalCheck();
          } else {
            this._prevHeight = height;
            this._pollScrollHeight(); // Recurse until the scroll height is stable for 200ms
          }
        },
        200, // Check for rendering to finish every .2 seconds
      );
    }
  };
}
