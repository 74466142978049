import { Injectable } from '@angular/core';
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  ReplaySubject,
  retry,
  Subject,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { CollectionReference, DocumentReference } from '@app/angular-fire-shims/angular-firestore.service';
// Note: Specifically commented as a reminder, so it won't be added back to this Service.
// import { AuthService } from '@app/auth/auth.service';

export interface Action {
  createdAt: FirestoreTimestamp;
  description: string;
  location: string;
  uid: string | null; // User ID (Firestore doesn't support undefined)
}

interface ActionWithCollection {
  action: Action;
  collection: CollectionReference<Action>;
}

export interface Session {
  createdAt: FirestoreTimestamp;
  lastModified: FirestoreTimestamp;
  uid: string | null; // User ID (Firestore doesn't support undefined)
}

interface SessionWithUid {
  session: DocumentReference<Session>;
  uid: string;
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private readonly _actionsColSub$: ReplaySubject<CollectionReference<Action>>;
  private readonly _locationSub$: ReplaySubject<string>;
  private readonly _sessionDocSub$: ReplaySubject<DocumentReference<Session>>;
  private readonly _trackSub$: Subject<Action['description']>;
  private readonly _uid$: Observable<Action['uid']>; // Also Session['uid']

  constructor(
    private readonly afAuth: AngularFireAuthService,
    private readonly afStore: AngularFirestoreService,
    // private readonly authService: AuthService, // Don't use AuthService so we can log inside of AuthService
  ) {
    this._actionsColSub$ = new ReplaySubject<CollectionReference<Action>>(1);
    this._locationSub$ = new ReplaySubject<string>(1);
    this._sessionDocSub$ = new ReplaySubject<DocumentReference<Session>>(1);
    this._trackSub$ = new Subject<string>();

    this._uid$ = this._trackUser();

    this._trackActions();
    this._createSession();
    this._sessionUpdates();
  }

  /**
   * Attempting to listen for NavigationEnd router events outside of the root component will cause
   * LoggerService to miss the initial navigation. So we are changing this Service to accept a location
   * from AppComponent.
   */
  public setLocation(url: string): void {
    this._locationSub$.next(url);
  }

  /** Record an action in the session's action collection. */
  public track(description: string): void {
    this._trackSub$.next(description);
  }

  /**
   * Adds a new document to the logging database for tracking this session with the app.
   * Any pending actions logging will wait until this completes and then store all of the actions.
   */
  private _createSession(): void {
    const logCollection = this.afStore.collection<Session>('logging');
    const nowTs = FirestoreTimestamp.now();

    // Because this comes from a promise it will only happen once and we don't need to unsubscribe.
    this._uid$.pipe(
      first(), // Only create the session once.
      mergeMap(async (uid: string | null): Promise<DocumentReference<Session>> => {
        return this.afStore.addDoc(logCollection, {
          createdAt: nowTs,
          lastModified: nowTs,
          uid,
        });
      }),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Error creating logging session', err);
      },
      next: (docRef: DocumentReference<Session>): void => {
        this._sessionDocSub$.next(docRef);
        // Tell any pending updates to the session/actions that they can proceed.
        const actionsCol = this.afStore.collection<Action>(docRef.path, 'actions');
        this._actionsColSub$.next(actionsCol);
      },
    });
  }

  /** Updates the session lastModified field once every 50 seconds when there is activity. */
  private _sessionUpdates(): void {
    // This is a singleton service in Angular so it will exist once for the entire app and we don't
    // need to unsubscribe.
    this._trackSub$.pipe(
      // Only update the session last modified once every 50 seconds.
      debounceTime(50_000),
      mergeMap((): Observable<DocumentReference<Session>> => this._sessionDocSub$),
      mergeMap(async (session: DocumentReference<Session>): Promise<void> => {
        const lastModified = FirestoreTimestamp.now();
        return this.afStore.updateDoc(session, { lastModified });
      }),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Error updating session lastModified', err);
      },
    });
  }

  /**
   * Watch for tracking events, then gather the information to create an Action record and add
   * that to Session's actions collection. Safe to call even before the session has been created.
   */
  private _trackActions(): void {
    // This is a singleton service in Angular so it will exist once for the entire app and we don't
    // need to unsubscribe.
    this._trackSub$.pipe(
      // This will wait for _actionsCol$ to be ready before continuing.
      // 20190825 - Chris helped me figure out how to do this without multiple subscribes using mergeMap
      mergeMap((description: string): Observable<ActionWithCollection> => {
        // Record current time in case of delayed initialization.
        const createdAt = FirestoreTimestamp.now();
        return combineLatest([ this._actionsColSub$, this._locationSub$, this._uid$ ]).pipe(
          map(([ collection, location, uid ]: [ CollectionReference<Action>, string, string | null ]): ActionWithCollection => {
            // eslint-disable-next-line @stylistic/object-curly-newline
            const action: Action = { createdAt, description, location, uid };
            return { action, collection };
          }),
          first(), // Only perform this update once per _track$ emission.
        );
      }),
      mergeMap(async ({ action, collection }: ActionWithCollection): Promise<DocumentReference<Action>> =>
        this.afStore.addDoc(collection, action)),
      // When changing the users email, then all queries seem to re-emit. This results in the errors like:
      //    Failed to record action FirebaseError: Document already exists:
      //    projects/new-balance-14fda/databases/(default)/documents/logging/N5bdJR7U9B7IlGFqA1fY/actions/usppHNBbcGiZeNrSYPw5
      // Retrying once on error seems to resolve this issue.
      retry(1),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Failed to record action', err);
      },
    });
  }

  /**
   * Observes the currentUser and emits the uid for tracking.
   * Subscribes to changes to the uid to update the session uid property.
   * This function should only be called once!
   */
  private _trackUser(): Observable<string | null> {
    // Firestore doesn't support undefined fields
    const uid$ = this.afAuth.authState$.pipe(
      map((usr: FirebaseUser | null): string | null => usr ? usr.uid : null), // eslint-disable-line unicorn/no-null
    );

    // This is a singleton service in Angular so it will exist once for the entire app and we don't
    // need to unsubscribe.
    uid$.pipe(
      // Don't update the session when the user logs out, but do update if the uid changes.
      filter((uid: string | null): uid is string => uid != undefined),
      // This will wait for _sessionDoc$ to be ready before continuing.
      // 20190825 - Chris helped me figure out how to do this without multiple subscribes using mergeMap
      mergeMap((uid: string): Observable<SessionWithUid> => this._sessionDocSub$.pipe(
        map((session: DocumentReference<Session>): SessionWithUid => ({ session, uid })),
      )),
      mergeMap(async ({ session, uid }: SessionWithUid): Promise<void> => this.afStore.updateDoc(session, { uid })),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Failed to update LoggerService session uid', err);
      },
    });

    return uid$;
  }
}
