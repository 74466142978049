/**
 * In template translation component.
 *
 * Most `@Inputs` map to `@transifex/native` ITranslationParams.
 * The addition of `@Input() sanitize` identifies HTML `str` content and enables HTML escaping
 * for vars. Otherwise the `str` is interpolated directly into the template using standard Angular.
 */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import type { OnChanges } from '@angular/core';
import type { ITranslateParams } from '@transifex/native';
import { combineLatest, map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationService } from './translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, // eslint-disable-line @angular-eslint/use-component-view-encapsulation
  imports: [ CommonModule ],
  selector: 'T', // eslint-disable-line @angular-eslint/component-selector
  standalone: true,
  template:
// eslint-disable-next-line @angular-eslint/component-max-inline-declarations
`<ng-container *ngIf="translatedStr$ | async as translatedStr">
  <ng-container *ngIf="!sanitize">{{ translatedStr }}</ng-container>
  <span *ngIf="sanitize" [innerHTML]="translatedStr"></span>
</ng-container>`,
})
export class TComponent implements OnChanges {
  @Input() public charlimit: number = 0;
  @Input() public comment: string = '';
  @Input() public context: string = '';
  // Because the translationKey sometimes comes from the database and old documents might not have it we are allowing undefined.
  @Input() public key?: string;
  /** Set when str contains HTML so it is inserted using [innerHTML], also HTML escapes strings in vars. */
  @Input() public sanitize: boolean = false;
  @Input({ required: true }) public str!: string;
  @Input() public tags: string = '';
  @Input() public vars: Record<string, unknown> = {};

  public readonly translatedStr$: Observable<string>;

  private readonly _translateParamsSub$: ReplaySubject<ITranslateParams>;

  // protected service so that ut.component can extend this class
  constructor(protected readonly service: TranslationService) {
    this._translateParamsSub$ = new ReplaySubject<ITranslateParams>(1);

    this.translatedStr$ = combineLatest([ this._translateParamsSub$, this.service.updateTranslations$ ]).pipe(
      map(([ params, _ ]: [ ITranslateParams, unknown ]): string => this.service.translate(this.str, params)),
    );
  }

  public ngOnChanges(): void {
    this._translateParamsSub$.next({
      _key: this.key,
      _charlimit: this.charlimit,
      _comment: this.comment,
      _context: this.context,
      // When using innerHTML with safeHtmlPipe because of sanitize we must always escape ICU
      // expression variables that might contain HTML content. Otherwise Angular would handle escaping.
      // Probably would be better to use https://angular.io/api/platform-browser/DomSanitizer#sanitize
      // but I am not 100% what that does and how it works.
      _escapeVars: this.sanitize,
      _tags: this.tags,
      ...this.vars,
    });
  }
}
