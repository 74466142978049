import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslationModule } from '@app/translation/translation.module';

import { GraphicComponent } from './graphic/graphic.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GraphicComponent,
    RouterModule,
    TranslationModule,
  ],
  selector: 'lux-not-found-graphic',
  standalone: true,
  styleUrls: [ './page-not-found.component.scss' ],
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {}
