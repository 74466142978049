import { Inject, Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAnalyticsService } from '@app/angular-fire-shims/angular-fire-analytics.service';
import { WINDOW } from '@app/window.provider';

import type { TrackMetadata, VendorsWindow } from './user-monitor.service';

export * from './user-monitor';

@Injectable({ providedIn: 'root' })
export class UserTrackingService {
  constructor(
    private readonly analytics: AngularFireAnalyticsService,
    @Inject(WINDOW) private readonly window: VendorsWindow,
  ) {}

  /**
   * Manually log an event with our various tracking and analytics tools.
   * - eventName: String from user-monitor.ts
   * - options: In Pendo, property names must use lowercase letters, numbers, or underscores only.
   * Note: We don't have anything here that prevents tracking calls before `window.pendo.initialize`
   * is called in `UserMonitorService#_tellPendo`.
   */
  public trackEvent(eventName: string, options: TrackMetadata = {}): void {
    if (!eventName) {
      throw new Error('UserTrackingService#trackEvent missing eventName');
    }

    // https://github.com/angular/angularfire/blob/master/docs/compat/analytics/getting-started.md#usage
    this.analytics.logEvent(eventName, options);

    // https://support.pendo.io/hc/en-us/articles/360032294291-Track-Events-Configuration
    if (this.window.pendo) {
      this.window.pendo.track(eventName, options);
    }

    // Note: We could also send these events to Hubspot, however, we need to upgrade the subscription
    // to 'Marketing Hub Enterprise'
  }
}
