import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lux-right-dots',
  standalone: true,
  styleUrls: [ './dots.component.scss' ],
  templateUrl: './dots.component.html',
})

export class DotsRightComponent {}
