import { Inject, Injectable } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { AdvisorConfig, RpcConfigOrUndef } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { HubSpotService } from '@app/hub-spot/hub-spot.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { HubSpotIdentity } from '@app/hub-spot/hub-spot.service';
import { SENTRY } from '@app/sentry.provider';
import type { SentryScope, SentryType } from '@app/sentry.provider';
import { TranslationService } from '@app/translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { getCountry } from '@app/utilities/locale';
import { distinctUntilKeysChanged } from '@app/utilities/rxjs-distinct-until-keys-changed';
import { WINDOW } from '@app/window.provider';

import type { UserData } from './user';
import { UserDataService } from './user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export interface Identity {
  id: string;

  chatEnabled: boolean;
  email?: string;
  firstname: string;
  interestinfinadvisor: string;
  lastname: string;
  locale: string;
  preferredLocale: string;
  rpcAdvisorEnabled: boolean;
}

export interface TrackMetadata {
  [key: string]: boolean | number | string | undefined; // Only the basic JSON types are expected to work
}

interface WinExt {
  FS?: { // eslint-disable-line @typescript-eslint/naming-convention
    identify: (id: string, userVars?: TrackMetadata) => void;
  };
  pendo?: {
    initialize: (userVars?: Record<string, TrackMetadata>) => void;
    track: (eventName: string, properties: TrackMetadata) => void;
  };
}
export type VendorsWindow = Window & WinExt;

@Injectable({ providedIn: 'root' })
export class UserMonitorService {
  public readonly countryCode: string;
  public readonly monitor$: Observable<Identity>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    private readonly hubspotService: HubSpotService,
    @Inject(SENTRY) private readonly sentry: SentryType,
    private readonly userDataService: UserDataService,
    private readonly translationService: TranslationService,
    @Inject(WINDOW) private readonly window: VendorsWindow,
  ) {
    this.countryCode = getCountry(this.appConfig.locale);
    this.monitor$ = combineLatest([
      this.authService.currentUser$, // Only identify users to the trackers when logged in.
      this.clientConfig.advisorConfig$.pipe(
        // For some reason this emits several times on logout.
        map((config: AdvisorConfig): boolean => config.chatEnabled),
        distinctUntilChanged(),
      ),
      this.translationService.updateTranslations$,
      this.userDataService.userData$.pipe(
        // Ensure that we're only updating chat, when we have updated data.
        distinctUntilKeysChanged([ 'firstname', 'lastname', 'interestinfinadvisor' ]),
      ),
      this.clientConfig.rpcConfig$.pipe(
        map((config: RpcConfigOrUndef): boolean => !!config?.enabled),
      ),
    ]).pipe(
      map(
        ([ user, chatEnabled, locale, data, rpcAdvisorEnabled ]: [ FirebaseUser, boolean, string, UserData, boolean ]): Identity => ({
          id: user.uid, // Unique User ID

          chatEnabled,
          email: user.email ?? undefined, // Authenticated user's email or undefined (instead of null)
          firstname: data.firstname ?? '',
          interestinfinadvisor: data.interestinfinadvisor ?? '',
          lastname: data.lastname ?? '',
          locale,
          preferredLocale: locale.toLowerCase(), // HubSpot requires lower case
          rpcAdvisorEnabled,
        }),
      ),
      tap((data: Identity): void => {
        this._tellSentry(data);
        this._tellFullstory(data);
        this._tellHubSpot(data);
        this._tellPendo(data);
      }),
    );
  }

  private _tellFullstory({ id, email, firstname, lastname }: Identity): void {
    const displayName = firstname || lastname ? `${firstname} ${lastname}` : email;

    // Identify user to FullStory.
    if (this.window.FS) {
      this.window.FS.identify(id, {
        displayName,
        email,
      });
    }
  }

  private _tellHubSpot({
    id,

    chatEnabled,
    email,
    firstname,
    interestinfinadvisor,
    lastname,
    locale,
    preferredLocale,
    rpcAdvisorEnabled,
  }: Identity): void {
    // Identify user to HubSpot
    const hubspotUser: HubSpotIdentity = {
      company: this.appConfig.organization, // Company Name
      country: this.countryCode,
      email,
      firstname,
      interestinfinadvisor,
      lastname,
      preferredLocale,
      userid: id,
      website: this.authService.appDomain,
    };

    // After the user is authenticated tell HubSpot who they are..
    this.hubspotService.identify(hubspotUser);

    // Load the HubSpot chat widget after user is authenticated.
    if ((chatEnabled || rpcAdvisorEnabled) && locale === 'en-US') {
      this.hubspotService.loadWidget();
    } else {
      this.hubspotService.removeWidget();
    }
  }

  private _tellPendo({ id, email, firstname, lastname }: Identity): void {
    const displayName = firstname || lastname ? `${firstname} ${lastname}` : '';

    if (this.window.pendo) {
      this.window.pendo.initialize({
        account: { id: this.appConfig.organization },
        visitor: {
          id,

          country: this.countryCode,
          email,
          full_name: displayName, // eslint-disable-line @typescript-eslint/naming-convention
        },
      });
    }
  }

  private _tellSentry({ id, email }: Identity): void {
    // Tell tracking tools who this user is:
    this.sentry.configureScope((scope: SentryScope): SentryScope => scope.setUser({ id, email }));
  }
}
