import { Injectable } from '@angular/core';
import { FV, PMT, PV } from '@formulajs/formulajs';

import type { UserData, UserDataFirestore } from '@app/users/user';

// Base ASSUMPTIONS for projection
export interface AssumptionsData {
  readonly rateOfIncomeAndExpenseGrowth: number;
  readonly rateOfInflation: number;
  readonly returnOnInvestementAdjustedForInflation: number;
  readonly returnOnInvestementAfterRetirement: number;
  readonly returnOnInvestementBeforeRetirement: number;
}

// One year (row) of generated results.
export interface ResultsYear {
  age: number;
  balanceEnd: number;
  balanceStart: number;
  income: number;
  interest: number;
  isRetired: boolean;
  savings: number;
}

// Overall results.
export interface ResultsData {
  readonly annualIncomeAtRetirement: number;
  readonly retirementAmountNeeded: number;
  readonly retirementAmountRemaining: number;
  readonly savingMonthly: number;
  readonly savingYearly: number;
  readonly yearsAfterRetirement: number;
}

export interface RetirementComputed {
  readonly retirementResults: ResultsData;
  readonly retirementYearly: ResultsYear[];
}

interface UserInputData {
  readonly ageCurrent: number;
  readonly ageMax: number;
  readonly ageRetirement: number;
  readonly annualIncomeCurrent: number;
  readonly expensesPercentAfterRetirement: number;
  readonly savingsCurrent: number;
}

// Hard coded ASSUMPTIONS. Maybe some day we'll make these user-editable for powerusers....
export const ASSUMPTIONS: AssumptionsData = {
  rateOfIncomeAndExpenseGrowth: 0.025,
  rateOfInflation: 0.025,
  returnOnInvestementAdjustedForInflation: (1.05 / 1.02) - 1, // 0.02941176470588247
  returnOnInvestementAfterRetirement: 0.05,
  returnOnInvestementBeforeRetirement: 0.07,
} as const;

const MONTHS_PER_YEAR = 12;

@Injectable({ providedIn: 'root' })
export class RetirementCalculatorService {
  /**
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _buildProjection(data: UserInputData): ResultsData {
    // No. of years left for retirement
    const yearsBeforeRetirement = data.ageRetirement - data.ageCurrent;

    // No. of years of retirement
    const yearsAfterRetirement = data.ageMax - data.ageRetirement;

    // Estimated income at retirement age
    let annualIncomeAtRetirement = FV(
      ASSUMPTIONS.rateOfIncomeAndExpenseGrowth,
      yearsBeforeRetirement,
      0,
      -data.annualIncomeCurrent,
    );

    // Reduce by expenses
    annualIncomeAtRetirement = (data.expensesPercentAfterRetirement * annualIncomeAtRetirement) / 100;

    // Retirement Amout Needed
    const retirementAmountNeeded = PV(
      ASSUMPTIONS.returnOnInvestementAdjustedForInflation,
      yearsAfterRetirement,
      -annualIncomeAtRetirement,
      undefined,
      1,
    );

    // Retirement Savings (excl. FV of savings corpus as on date)
    let retirementAmountRemaining = retirementAmountNeeded - (
      data.savingsCurrent * Math.pow(ASSUMPTIONS.returnOnInvestementBeforeRetirement + 1, yearsBeforeRetirement)
    );

    // Prevent from going negative
    retirementAmountRemaining = Math.max(retirementAmountRemaining, 0);

    const negatedRetirementAmountRemaining = -retirementAmountRemaining;

    // Monthly saving
    const savingMonthly = PMT(
      ASSUMPTIONS.returnOnInvestementBeforeRetirement / MONTHS_PER_YEAR,
      yearsBeforeRetirement * MONTHS_PER_YEAR,
      0,
      negatedRetirementAmountRemaining,
    ) || 0; // Convert -0 to 0

    // Yearly Saving (not the same as savingMonthly * 12)
    const savingYearly = PMT(
      ASSUMPTIONS.returnOnInvestementBeforeRetirement,
      yearsBeforeRetirement,
      0,
      negatedRetirementAmountRemaining,
    ) || 0; // Convert -0 to 0

    const resultsData: ResultsData = {
      annualIncomeAtRetirement,
      retirementAmountNeeded,
      retirementAmountRemaining,
      savingMonthly,
      savingYearly,
      yearsAfterRetirement,
    };

    return resultsData;
  }

  /**
   * For testing this is a public method, but it should be considered private to this class in use.
   */
  public _buildProjectionYears(resultsData: ResultsData, data: UserInputData): ResultsYear[] {
    const years: ResultsYear[] = [];

    for (let i = data.ageCurrent; i < data.ageMax; i++) {
      // Initilize
      const year: ResultsYear = {
        age: i,
        balanceEnd: 0,
        balanceStart: data.savingsCurrent,
        income: data.annualIncomeCurrent,
        interest: 0,
        isRetired: i >= data.ageRetirement,
        savings: resultsData.savingYearly,
      };

      // Last Year
      const lastYear = years.at(-1);

      // Update Based on Last Year
      if (lastYear) {
        year.balanceStart = lastYear.balanceEnd;
        year.income = lastYear.income * (ASSUMPTIONS.rateOfIncomeAndExpenseGrowth + 1);
        if (!lastYear.isRetired && year.isRetired) {
          year.income = (year.income * data.expensesPercentAfterRetirement) / 100;
        }

        year.interest = year.isRetired
          ? (year.balanceStart - lastYear.balanceStart) * ASSUMPTIONS.returnOnInvestementAfterRetirement
          : year.balanceStart * ASSUMPTIONS.returnOnInvestementBeforeRetirement;
      }

      if (year.isRetired) {
        // After Retirement
        year.interest = (year.balanceStart - year.income) * ASSUMPTIONS.returnOnInvestementAfterRetirement;
        year.savings = 0;
        const balanceEnd = year.balanceStart + year.interest - year.income;
        year.balanceEnd = balanceEnd > 0 ? balanceEnd : 0;
      } else {
        // Before Retirement
        year.interest = year.balanceStart * ASSUMPTIONS.returnOnInvestementBeforeRetirement;
        year.balanceEnd = year.balanceStart + year.interest + year.savings;
      }

      years.push(year);
    }

    return years;
  }

  /** Performs all of the calculation on the user's data to populate the computed data. */
  public fillComputedFields(userData: UserDataFirestore, age?: number): RetirementComputed {
    const data: UserInputData = {
      ageCurrent: age ?? 30,
      ageMax: userData.ageexpectancy ?? 78,
      ageRetirement: userData.ageretire ?? 65,
      // A zero annualincome makes things difficult. Ideally we won't call this calculator for real without a valid annualincome.
      annualIncomeCurrent: userData.annualincome ?? 12_000,
      expensesPercentAfterRetirement: userData.retirementincomepercentage ?? 80,
      savingsCurrent: userData.retirementsavingscurrent ?? 0,
    };

    const retirementResults = this._buildProjection(data);

    return {
      retirementResults,
      retirementYearly: this._buildProjectionYears(retirementResults, data),
    };
  }

  /**
   * Has the user provided valid data to generate a reasonable retirement projection?
   * If no error message, the inputs can be considered valid.
   */
  public getErrorMessage(userData: UserData): string {
    let errorMsg = '';

    // This method is terrible code and needs to be re-written.
    /* eslint-disable @typescript-eslint/strict-boolean-expressions */
    const missingValues: boolean = !userData.computed.age
      || !userData.ageretire
      || !userData.ageexpectancy
      || !userData.annualincome
      || !userData.retirementincomepercentage;
    /* eslint-enable @typescript-eslint/strict-boolean-expressions */

    if (missingValues) {
      errorMsg = 'Review your information in the goal details below to create a retirement projection.';
    }

    const age = userData.computed.age ?? 0; // less than undefined ageExpectancy and ageretire
    const ageExpectancy = userData.ageexpectancy ?? 2; // greater than undefined age and ageretire
    const ageRetire = userData.ageretire ?? 1; // greater than undefined age, but less than ageexpectancy
    if (age >= ageRetire) {
      errorMsg = 'Please select a retirement age that is in the future.';
    } else if (userData.annualincome === 0) {
      errorMsg = 'Please provide an annual income. Your current income is used to estimate expenses.';
    } else if (age >= ageExpectancy) {
      errorMsg = 'Please select a life expectancy that is in the future.';
    } else if (ageRetire >= ageExpectancy) {
      errorMsg = 'Please select a retirement age that is less than your life expectancy.';
    }

    return errorMsg;
  }
}
