import type { FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';

// Which goals has the user selected?
export interface UserGoals {
  readonly car: boolean;
  readonly childsmarriage: boolean;
  readonly creditcarddebt: boolean;
  readonly creditscore: boolean;
  readonly custom?: string;
  readonly debt: boolean;
  readonly education: boolean;
  readonly emergency: boolean;
  readonly house: boolean;
  readonly investing: boolean;
  readonly mortgage: boolean;
  readonly other: boolean;
  readonly retirement: boolean;
  readonly savings: boolean;
  readonly studentdebt: boolean;
  readonly vacation: boolean;
}

export const DEFAULT_USER_GOALS: UserGoals = {
  car: false,
  childsmarriage: false,
  creditcarddebt: false,
  creditscore: false,
  custom: undefined,
  debt: false,
  education: false,
  emergency: false,
  house: false,
  investing: false,
  mortgage: false,
  other: false,
  retirement: false,
  savings: false,
  studentdebt: false,
  vacation: false,
} as const;

// Default representation of a goal
interface GoalBase {
  readonly checkupItems: string[]; // Checkup suggestions use goals to show suggested lessons
  readonly date: Date | FirestoreTimestamp;
  readonly description: string;
  readonly icon: string;
  readonly lessons: string[]; // Creates the lesson plan on the goals page.
  readonly name: string;
  readonly note: string; // Just flavor text for the /goals pages.
  readonly slug: keyof UserGoals;
  readonly translationKeys?: {
    readonly description: string;
    readonly name: string;
    readonly note: string;
  };
}

export interface GoalFirestore extends GoalBase {
  readonly date: FirestoreTimestamp;
}

// Includes the user's status on that goal, since we almost always want that data at the same time.
export interface Goal extends GoalBase {
  readonly date: Date;
  readonly enabled: boolean;
}
