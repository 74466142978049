import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { OnChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationModule } from '@app/translation/translation.module';
import type { UserProgress } from '@app/users/user-progress.service';

import type { Lesson } from '../lessons';

type Buttons = 'continue' | 'restart' | 'start';

interface ViewModel {
  button: Buttons;
  currentSlide?: number;
  lessonSlug: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, RouterModule, TranslationModule ],
  selector: 'lux-progress-button',
  standalone: true,
  styleUrls: [ './progress-button.component.scss' ],
  templateUrl: './progress-button.component.html',
})
export class ProgressButtonComponent implements OnChanges {
  @Input({ required: true }) public lesson!: Lesson;
  @Input() public newTab: boolean = false;
  /** Users without progress for this lesson will provide undefined. */
  @Input({ required: true }) public progress!: UserProgress | undefined;
  public readonly vm$: Observable<ViewModel>;

  private readonly _vmSub$: ReplaySubject<ViewModel>;

  constructor() {
    this._vmSub$ = new ReplaySubject<ViewModel>(1);
    this.vm$ = this._vmSub$.asObservable();
  }

  public ngOnChanges(): void {
    const currentSlide = this.progress?.maxSlide;
    const totalSlide = this.progress?.totalSlides ?? 0;

    let button: Buttons = 'start';
    if (currentSlide != undefined) {
      button = currentSlide < totalSlide ? 'continue' : 'restart';
    }

    this._vmSub$.next({
      button,
      currentSlide,
      lessonSlug: this.lesson.slug,
    });
  }
}
