import { Injectable } from '@angular/core';
import { endOfYear, startOfYear } from 'date-fns';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig, IncentiveConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import type { HistoricalSummary, UserHistorical } from '@app/users/user-historicals.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserHistoricalService } from '@app/users/user-historicals.service';
import { EVENTS } from '@app/users/user-monitor';
import { getProgramId } from '@app/utilities/incentive-programs';

export interface IncentiveProgramProgress {
  readonly completedLessonSlugs: string[];
  readonly hasAnsweredCheckup: boolean;
  readonly hasVisitedCheckupResults: boolean;
  readonly isBudgetComplete: boolean;
  readonly isDebtPayoffCalculatorComplete: boolean;
  readonly isEmergencySavingsCalculatorComplete: boolean;
  readonly isRetirementCalculatorComplete: boolean;
  readonly isSavingsGoalCalculatorComplete: boolean;
  readonly lessonsCompleted: number;
}

@Injectable({ providedIn: 'root' })
export class IncentiveProgramService {
  /**
   * Fetches the custom incentive program configuration from the Features config, if speficied.
   * Otherwise generates the default yearly incentive configuration.
   */
  public readonly incentiveConfig$: Observable<IncentiveConfig>;

  /**
   * Calculates the current incentive progress from the current user historicals data.
   */
  public readonly incentiveProgramProgress$: Observable<IncentiveProgramProgress>;

  /**
   * If specified fetches the program ID of historical program being summerized from the features
   * config. Otherwise it will emit undefined if there is no program to summerize.
   */
  public readonly incentiveSummaryProgramId$: Observable<string | undefined>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly userHistoricalService: UserHistoricalService,
  ) {
    this.incentiveConfig$ = this.clientConfig.featuresConfig$.pipe(
      map((config: FeaturesConfig): IncentiveConfig => {
        const { incentiveConfig } = config;

        if (incentiveConfig == undefined) {
          const now = new Date();

          // TODO: ROB 20240102 - UserHistoricalsService has a similar default program config. Align them.
          return {
            endAt: endOfYear(now),
            programId: `${now.getFullYear()}`,
            startAt: startOfYear(now),
          };
        }

        return incentiveConfig;
      }),
    );

    this.incentiveProgramProgress$ = this.userHistoricalService.currentHistorical$.pipe(
      map((historical: UserHistorical | undefined): IncentiveProgramProgress => this._calculateProgress(historical)),
    );

    this.incentiveSummaryProgramId$ = this.clientConfig.featuresConfig$.pipe(
      map((config: FeaturesConfig): string | undefined => getProgramId(config.incentiveSummary)),
    );
  }

  public getIncentiveProgramProgress(programId: string): Observable<IncentiveProgramProgress> {
    return this.userHistoricalService.getHistoricalForProgramId(programId).pipe(
      map((historical: UserHistorical | undefined): IncentiveProgramProgress => {
        return this._calculateProgress(historical);
      }),
    );
  }

  private _calculateProgress(historical: UserHistorical | undefined): IncentiveProgramProgress {
    if (historical) {
      const { summary } = historical;
      const {
        'Budget_BudgetProgress_Completed__isbudgetcomplete-true': budgetProgressCompleted = 0,
        'Calculator_DebtPayoff__Visited-complete': calculatorDebtPayoffCompleted = 0,
        Calculator_EmergencySavings__hasAnsweredMinimalFields: calculatorEmergencySavingsCompleted = 0,
        'Calculator_Retirement__Visited-complete': calculatorRetirementCompleted = 0,
        Calculator_SavingsGoal_Completed: calculatorSavingsGoalCompleted = 0,
        Checkup_CheckupResults_Viewed: checkupResults = 0,
        Lessons_LessonProgress_Completed: lessonsCompleted = 0,
      } = summary;

      const completedLessonSlugs = Object.keys(summary)
        .filter((key: string): boolean => key.startsWith(`${EVENTS.lessonsLessonProgressCompleted}__`))
        .map((key: string): string => key.split('__')[1] ?? '');

      return {
        completedLessonSlugs,
        hasAnsweredCheckup: hasAnsweredCheckup(summary),
        hasVisitedCheckupResults: Boolean(checkupResults),
        isBudgetComplete: budgetProgressCompleted > 0,
        isDebtPayoffCalculatorComplete: calculatorDebtPayoffCompleted > 0,
        isEmergencySavingsCalculatorComplete: calculatorEmergencySavingsCompleted > 0,
        isRetirementCalculatorComplete: calculatorRetirementCompleted > 0,
        isSavingsGoalCalculatorComplete: calculatorSavingsGoalCompleted > 0,
        lessonsCompleted,
      };
    }

    return {
      completedLessonSlugs: [],
      hasAnsweredCheckup: false,
      hasVisitedCheckupResults: false,
      isBudgetComplete: false,
      isDebtPayoffCalculatorComplete: false,
      isEmergencySavingsCalculatorComplete: false,
      isRetirementCalculatorComplete: false,
      isSavingsGoalCalculatorComplete: false,
      lessonsCompleted: 0,
    };
  }
}

/**
 * Filters the HistoricalSummary for `unanswered` fields
 * which corresponds to the amount of checkup questions answered
 * Less than or equal to 3 means the user has answered the required amount of at least 14 out of 17 questions
 */
const hasAnsweredCheckup = (summary: HistoricalSummary): boolean =>
  Object.keys(summary)
    .filter((key: string): boolean => key.startsWith('Checkup_CheckupResults_Viewed__unanswered-'))
    .map((key: string): number => Number(key.split('__unanswered-')[1]))
    .some((k: number): boolean => k <= 3);
