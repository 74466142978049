/**
 * Rounds monetary values (dollar$.¢ents) to whole number cents integer.
 * This method *must* be used before doing monetary math!
 *
 * https://stackoverflow.com/a/18358056
 * http://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export const dollarsToCents = (num: number): number => {
  // This is a method of rounding to 2 decimal places that works for 1.005.
  // Math.round(1.005 * 100) / 100 = 1 # Incorrect
  const cleaned = Number(num); // First makes sure that if `num` has exponents they are expanded.
  const cents = Number(`${cleaned}e+2`); // Converts to cents with possible decimals.
  return Math.round(cents); // Rounds fractional cents so we end up with an integer.
};

/**
 * Reverses dollarsToCents to create a two decimal representation of dollar$.¢ents.
 * `cents` *must* be a whole integer value!
 */
export const formatDollars = (cents: number): number => {
  const rounded = Math.round(cents); // Rounds fractional cents so we end up with an integer.
  return Number(`${rounded}e-2`); // Converts to dollars with only two decimals for cents.
};

/**
 * Rounds monetary values to two decimal places for USD currency.
 *
 * https://stackoverflow.com/a/18358056
 * http://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export const roundToCents = (num: number): number => {
  // This is a method of rounding to 2 decimal places that works for 1.005.
  // Math.round(1.005 * 100) / 100 = 1 # Incorrect
  const cleaned = Number(num); // First makes sure that if num has exponents they are expanded.
  const cents = Number(`${cleaned}e+2`); // Converts to cents with possible decimals.
  const rounded = Math.round(cents); // Rounds fractional cents so we end up with an integer.
  return Number(`${rounded}e-2`); // Converts back to dollars with only two decimals for cents.
};
