import type { FactoryProvider } from '@angular/core';

import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { APP_CONFIG, AppConfig } from '@app/app-config/app-config';

import { CMSLessonsDataService } from './services/lessons-cms-data.service';
import { LessonsDataService } from './services/lessons-data.service';
import { FirestoreLessonsDataService } from './services/lessons-firestore-data.service';

const lessonsDataServiceFactory = (
  appConfig: AppConfig,
  afStore: AngularFirestoreService,
  afFns: AngularFireFunctionsService,
): LessonsDataService =>
  appConfig.useCMS
    ? new CMSLessonsDataService(appConfig, afFns)
    : new FirestoreLessonsDataService(afStore);

const lessonsDataServiceProvider: FactoryProvider = {
  provide: LessonsDataService,
  useFactory: lessonsDataServiceFactory,
  deps: [ APP_CONFIG, AngularFirestoreService, AngularFireFunctionsService ],
};

export const LESSONS_DATA_SERVICE_PROVIDER = [ lessonsDataServiceProvider ];
