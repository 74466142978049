// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicator-assets::before {
  margin-right: 0.25rem;
  border-radius: 0.375rem;
  background-color: #29a59a;
  padding: 0 0.25rem;
  content: "";
}

.indicator-liabilities::before {
  margin-right: 0.25rem;
  border-radius: 0.375rem;
  background-color: #eac63f;
  padding: 0 0.25rem;
  content: "";
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
