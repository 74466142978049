import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FirebaseError } from '@angular/fire/app';
import { Router } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { AuthConfig, ProviderConfig, SelfSignupConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

import { AuthService } from '../../auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { SessionListenerService } from '../../../session-listener.service';
import { ProviderDataService } from '../../provider-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ProviderLoginService } from '../../provider-login.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ProviderLogin } from '../../provider-login.service';
import { ProviderStatusService } from '../../provider-status.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

export interface ViewModel {
  readonly errorCode: string;
  readonly providers: readonly ProviderConfig[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ErrorMessageComponent,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-provider-login',
  standalone: true,
  templateUrl: './provider-login.component.html',
})
export class ProviderLoginComponent {
  public readonly vm$: Observable<ViewModel>;

  private readonly _errorCodeSub$: BehaviorSubject<string>;
  private readonly _submitSub$: Subject<ProviderConfig>;

  constructor(
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    private readonly providerData: ProviderDataService,
    private readonly providerLoginService: ProviderLoginService,
    private readonly providerStatusService: ProviderStatusService,
    private readonly router: Router,
    private readonly sessionListenerService: SessionListenerService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this._errorCodeSub$ = new BehaviorSubject<string>('');
    this._submitSub$ = new Subject<ProviderConfig>();

    // If everything is setup correctly then this component should never be loaded on an app where the providers config
    // is empty. However, if it is then the combinedLatest below will prevent the view from loading and result in
    // an spinner that never ends.
    const providers$ = this.clientConfig.selfSignupConfig$.pipe(
      map((config: SelfSignupConfig): readonly ProviderConfig[] => config.providers),
      filter((providers: readonly ProviderConfig[]): boolean => providers.length > 0),
    );

    this._submitSub$.pipe(
      switchMap(
        (config: ProviderConfig): Observable<[ ProviderLogin, boolean ]> =>
          this.providerLoginService.providerLogin(config.authProvider, config.providerId).pipe(
            catchError((err: unknown): Observable<never> => {
              if (err instanceof Error) {
                this._handleError(err);
              }
              return EMPTY;
            }),
            switchMap((result: ProviderLogin): Observable<ProviderLogin> => this.providerData.handleProviderData(result)),
            tap((result: ProviderLogin): void => {
              if (result.isNewUser) {
                this.userTrackingService.trackEvent(EVENTS.authUserCreated, { sso: true });
              }
            }),
            map((result: ProviderLogin): [ ProviderLogin, boolean ] => [ result, !!config.emailLinkingEnabled ]),
          ),
      ),
      switchMap(
        ([ result, emailLinkingEnabled ]: [ ProviderLogin, boolean ]): Observable<[ProviderLogin, boolean, readonly ProviderConfig[]]> =>
          this.clientConfig.authConfig$.pipe(
            take(1),
            switchMap((authConfig: AuthConfig): Observable<[ ProviderLogin, boolean, readonly ProviderConfig[] ]> => {
              if (authConfig.sessionTimeout.enabled) {
                return this.sessionListenerService.sessionIdsSynced$.pipe(
                  filter((sessionIdsSynced: boolean): boolean => sessionIdsSynced),
                  take(1),
                  switchMap((): Observable<[ ProviderLogin, boolean, readonly ProviderConfig[] ]> =>
                    this.providerStatusService.unlinkedProviders$.pipe(
                      map((unlinkedProviders: readonly ProviderConfig[]): [ ProviderLogin, boolean, readonly ProviderConfig[] ] =>
                        [ result, emailLinkingEnabled, unlinkedProviders ]),
                    )),
                );
              }
              return this.providerStatusService.unlinkedProviders$.pipe(
                map((unlinkedProviders: readonly ProviderConfig[]): [ ProviderLogin, boolean, readonly ProviderConfig[] ] =>
                  [ result, emailLinkingEnabled, unlinkedProviders ]),
              );
            }),
          ),
      ),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('ProviderLoginComponent#subscribe#error', err);
      },
      next: ([ result, emailLinkingEnabled, unlinkedProviders ]: [ ProviderLogin, boolean, readonly ProviderConfig[] ]): void => {
        this._handleSuccess(result, emailLinkingEnabled, unlinkedProviders);
      },
    });

    this.vm$ = combineLatest([ this._errorCodeSub$, providers$ ]).pipe(
      map(([ errorCode, providers ]: [ string, readonly ProviderConfig[] ]): ViewModel => ({ errorCode, providers })),
    );
  }

  public openPopup(config: ProviderConfig): void {
    this._errorCodeSub$.next('');

    // providerLogin is created from a promise, so it will only ever emit once.
    this._submitSub$.next(config);
  }

  private _handleError(err: Error | FirebaseError): void {
    this._errorCodeSub$.next('code' in err ? err.code : err.message);
    console.error('ProviderLoginComponent#_handleError', err);
  }

  private _handleSuccess(result: ProviderLogin, emailLinkingEnabled: boolean, unlinkedProviders: readonly ProviderConfig[]): void {
    let defaultNextUrl;
    // If there are more unlinked SSO providers, give the option to link them
    if (unlinkedProviders.length > 0) {
      defaultNextUrl = '/link-external';
      this.providerStatusService.incrementIndex();
    } else {
      defaultNextUrl = emailLinkingEnabled ? '/link-email' : '/start';
    }
    const url = result.isNewUser ? defaultNextUrl : this.authService.redirectUrl;

    // Redirect the user
    this.router.navigateByUrl(url)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err: unknown): void => {
        console.error('ProviderLoginComponent#_handleSuccess#navigateByUrl', err);
      });
  }
}
