import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { LanguagePickerFooterComponent } from '@app/language/language-picker-footer/language-picker-footer.component';
import { TranslationModule } from '@app/translation/translation.module';

import { LogoComponent } from '../logo/logo.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LanguagePickerFooterComponent,
    LogoComponent,
    RouterModule,
    TranslationModule,
  ],
  selector: 'lux-footer',
  standalone: true,
  styleUrls: [ './footer.component.scss' ],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  public readonly isLoggedIn$: Observable<boolean>;
  public readonly organization: string;
  public readonly today: Date = new Date();

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly authService: AuthService,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.organization = this.appConfig.organization;
  }
}
