import { Injectable } from '@angular/core';
import type { ChartData as ChartjsData, ChartType, TooltipItem } from 'chart.js';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { DefaultCurrencyPipe } from '@app/pipes/default-currency/default-currency.pipe';

export interface ChartValue {
  amount: number;
  color: string;
  name: string;
}

export type ChartData = ChartjsData<ChartType, number[], string>;

export type TTItem = TooltipItem<ChartType>;

@Injectable({ providedIn: 'root' })
export class ChartDataService {
  constructor(private readonly currencyPipe: DefaultCurrencyPipe) {}

  // This is a property with an arrow function value so it can be more easily used as the callback for ChartOptions
  public chartLabelCallback = (context: TTItem): string => {
    const amount = context.raw as number;
    const label = context.dataset.label ?? context.label;

    // Use the luxCurrency pipe so our number display is consistent.
    return `${label}: ${this.currencyPipe.transform(amount) ?? amount}`;
  };

  public chartTicksCallback = (value: number | string): string | null => {
    // Use the luxCurrency pipe so our number display is consistent.
    return this.currencyPipe.transform(value);
  };

  public updateChart(values: ChartValue[]): ChartData {
    const chartLabels: string[] = [];
    const chartData: number[] = [];
    const chartColors: string[] = [];

    for (const val of values) {
      // Accounts NetWorthChartComponent might have negative numbers and we want to skip them
      // userData.computed.monthlynet might be negative and we want to skip that one too.
      if (val.amount > 0) {
        chartLabels.push(val.name);
        chartData.push(val.amount);
        chartColors.push(val.color);
      }
    }
    return {
      datasets: [
        {
          backgroundColor: chartColors,
          data: chartData,
          hoverBackgroundColor: chartColors,
        },
      ],
      labels: chartLabels,
    };
  }
}
