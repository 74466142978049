import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject } from '@angular/core'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]', // eslint-disable-line @angular-eslint/directive-selector
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly el: ElementRef<HTMLElement>,
  ) {}

  public ngAfterViewInit(): void {
    // Note that this message in the console:
    // "Autofocus processing was blocked because a document already has a focused element."
    // is caused by the HTML attribute `autofocus` not this code. I think.
    if (this.document.activeElement !== this.el.nativeElement) {
      this.el.nativeElement.focus();
    }
  }
}
