// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  CommonModule,
  DOCUMENT,
  NgOptimizedImage,
  ViewportScroller,
} from '@angular/common';
import { // eslint-disable-line @typescript-eslint/consistent-type-imports
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  RendererStyleFlags2,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { Event } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  merge,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type {
  AdvisorConfig,
  FeaturesConfig,
  RpcConfigOrUndef,
  SelfSignupConfig,
} from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { DataConsentComponent } from '@app/stuff/data-consent/data-consent.component';
import { TranslationModule } from '@app/translation/translation.module';
import type { UserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { distinctUntilKeysChanged } from '@app/utilities/rxjs-distinct-until-keys-changed';
import { tapOnce } from '@app/utilities/rxjs-tap-once';

import { LogoComponent } from '../logo/logo.component';

interface UserFields {
  firstname?: string;
  isChampion?: boolean;
}

type CombinedValues = [ AdvisorConfig, boolean, boolean, boolean, FeaturesConfig, boolean, UserFields ];

export interface ViewModel extends UserFields {
  advisorEnabled: boolean;
  advisorMethod: 'external' | 'learnlux';
  budgetEnabled: boolean; // Computed from featuresConfig
  dashboardEnabled: boolean; // Computed from featuresConfig
  featuresConfig: FeaturesConfig;
  isCollapsed: boolean;
  isLoggedIn: boolean;
  rpcAdvisorEnabled: boolean;
  signupEnabled: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DataConsentComponent,
    LogoComponent,
    NgbCollapseModule,
    NgbDropdownModule,
    NgOptimizedImage,
    RouterModule,
    TranslationModule,
  ],
  selector: 'lux-header',
  standalone: true,
  styleUrls: [ './header.component.scss' ],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public readonly customLuxLogo?: string;
  public readonly logo?: string;
  public readonly organization: string;
  public readonly vm$: Observable<ViewModel>;

  private readonly _collapsedSub$: BehaviorSubject<boolean>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly hostEl: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly scroller: ViewportScroller,
    private readonly userDataService: UserDataService,
  ) {
    this._collapsedSub$ = new BehaviorSubject<boolean>(true);
    this.organization = this.appConfig.organization;
    this.logo = this.appConfig.clientLogo;
    this.customLuxLogo = this.appConfig.customLuxLogo;

    const userFields$: Observable<UserFields> = this.authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn: boolean): Observable<UserFields> => {
        if (isLoggedIn) {
          return this.userDataService.userData$.pipe(
            map((userData: UserData): UserFields => ({
              firstname: userData.firstname,
              isChampion: userData.isChampion,
            })),
            startWith({
              firstname: undefined,
              isChampion: undefined,
            } as UserFields),
            distinctUntilKeysChanged([ 'firstname', 'isChampion' ]),
          );
        }
        // Causes obervable to emit if user is not logged in.
        return of({ firstname: undefined, isChampion: undefined });
      }),
    );

    this.vm$ = combineLatest([
      this.clientConfig.advisorConfig$,
      merge(
        // Ok to subscribe to router.events outside the root component because on the initial navigation
        // _collapsed$ is initialized to collapsed which is what we want.
        this.router.events.pipe(
          filter((event: Event): boolean => event instanceof NavigationStart),
          map((): boolean => true),
        ),
        this._collapsedSub$,
      ),
      this.authService.isLoggedIn$.pipe(filter((result?: boolean): result is boolean => result != undefined)),
      this.clientConfig.selfSignupConfig$.pipe(map((config: SelfSignupConfig): boolean => config.enabled)),
      this.clientConfig.featuresConfig$,
      this.clientConfig.rpcConfig$.pipe(map((config: RpcConfigOrUndef): boolean => !!config?.enabled)),
      userFields$,
    ]).pipe(
      map(
        (
          [ advisorConfig, isCollapsed, isLoggedIn, signupEnabled, featuresConfig, rpcAdvisorEnabled, userFields ]: CombinedValues,
        ): ViewModel => {
          const advisorMethod = advisorConfig.typeform
            && !advisorConfig.typeform.connectEnabled
            && !advisorConfig.specialRequest
            ? 'external'
            : 'learnlux';
          const budgetEnabled = featuresConfig.budget !== 'disabled';
          return {
            advisorEnabled: advisorConfig.enabled,
            advisorMethod,
            budgetEnabled,
            dashboardEnabled: budgetEnabled || featuresConfig.checkupEnabled || featuresConfig.goalsEnabled,
            featuresConfig,
            firstname: userFields.firstname,
            isChampion: userFields.isChampion,
            isCollapsed,
            isLoggedIn,
            rpcAdvisorEnabled,
            signupEnabled,
          };
        },
      ),
      tapOnce((): void => {
        // On the inital rendering of the header we need to wait a tick for the real height to be
        // detected instead of 50px
        setTimeout(
          (): void => {
            this.setScrollOffset();
          },
          0,
        );
      }),
    );
  }

  @HostListener('window:resize')
  public setScrollOffset(): void {
    const offset: [ number, number ] = [ 0, 0 ];
    const styles = getComputedStyle(this.hostEl.nativeElement);

    let resetDisplay = false;
    // If app loads on the LessonViewerComponent view then the header is display: none;
    // so we need to make it visible for a moment to calculate the height.
    if (styles.display === 'none') {
      this.hostEl.nativeElement.style.display = 'block';
      resetDisplay = true;
    }

    const rect = this.hostEl.nativeElement.getBoundingClientRect();

    // Only set the offset when media-breakpoint-up('lg') applies in the app.component.scss
    if (rect.width >= 992) {
      offset[1] = Math.ceil(rect.height);
    }

    // Remove our forced display of the header now that we have calculated the height.
    if (resetDisplay) {
      this.hostEl.nativeElement.style.display = '';
    }

    this.renderer.setStyle(this.document.documentElement, '--lux-topnav-height', `${offset[1]}px`, RendererStyleFlags2.DashCase);
    this.scroller.setOffset(offset);
  }

  public collapseMenu(collapsed: boolean): void {
    this._collapsedSub$.next(collapsed);
  }
}
