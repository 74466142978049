// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lux-mr-cooper-incentive-widget-wrapper {
  border: 1px solid #d6dbdc;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 1.75rem;
}

.points {
  text-align: end;
  color: #475053;
  font-size: 0.75rem;
}

.info {
  color: #475053;
  font-size: 0.75rem;
}

.section-header {
  align-items: baseline;
}

.material-symbols-rounded {
  margin-right: 0.2rem;
  padding-left: 10px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

img {
  padding-right: 4px;
  width: 32px;
}

a {
  color: inherit;
}

h6 {
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

.divider {
  margin: 0 auto;
  margin-bottom: 8px;
  border-bottom: 2px solid;
  width: 91%;
}
.divider.bronze {
  border-bottom-color: #b37700;
}
.divider.silver {
  border-bottom-color: #9faaae;
}
.divider.gold {
  border-bottom-color: #eac63f;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
