/**
 * Name: SuggestionsListComponent
 * Description: Take a list of suggestions, and returns a list of
 *   these suggestion organized by their status.
 */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { tap } from 'rxjs';
import type { Observable } from 'rxjs';

import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserSuggestionsService } from '@app/users/user-suggestions.service';
import type { UserSuggestion } from '@app/users/user-suggestions.service';

import { SuggestionPreviewComponent } from '../suggestion-preview/suggestion-preview.component';

const prioritySort = (a: UserSuggestion, b: UserSuggestion): number => a.priority - b.priority;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    SuggestionPreviewComponent,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-suggestions-list',
  standalone: true,
  styleUrls: [ './suggestions-list.component.scss' ],
  templateUrl: './suggestions-list.component.html',
})
export class SuggestionsListComponent {
  @Input() public showMore: boolean = true;

  public readonly suggestions$: Observable<UserSuggestion[]>;

  constructor(private readonly suggestionsService: UserSuggestionsService) {
    this.suggestions$ = this.suggestionsService.incompleteSuggestions$.pipe(
      // Sort the incomplete suggestions by tier (priority). Mutates Array!
      tap((suggestions: UserSuggestion[]): void => { suggestions.sort(prioritySort); }),
    );
  }
}
