import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lux-bouncer',
  standalone: true,
  styleUrls: [ './bouncer.component.scss' ],
  templateUrl: './bouncer.component.html',
})
export class BouncerComponent {}
