import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig } from '../client-config/client-config';
import { ClientConfigService } from '../client-config/client-config.service';

// Only exported for testing
export type EnabledGuard = () => Observable<boolean>;
type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
// Only exported for testing
export type EnabledFlags = KeysMatching<FeaturesConfig, boolean>;

export const featureEnabledGuardFactory = (flag: EnabledFlags): EnabledGuard => {
  return (): Observable<boolean> => {
    const clientConfig = inject(ClientConfigService);

    return clientConfig.featuresConfig$.pipe(
      take(1), // RouterGuards that return observables must complete!
      // Routes that return false will fall through to the PageNotFound wildcard ('**') route.
      map((config: FeaturesConfig): boolean => config[flag]),
    );
  };
};
