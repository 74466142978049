// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  max-width: 39.5rem;
}

::ng-deep .modal-content {
  align-items: center;
  justify-content: center;
}

.display-1 {
  font-weight: 500;
}

img {
  display: none;
}
@media (min-width: 992px) {
  img {
    display: inline-block;
    margin-bottom: 1rem;
    width: 44px;
  }
}

p,
label {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  p,
  label {
    font-size: 1.125rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
