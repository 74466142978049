import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import type { Observable } from 'rxjs';

import BF from '@app/budget/budget-fields.json';
import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
// Using the Firestore data here because calculators should operate on the unMogrified user data.
import type { UserData, UserDataFirestore } from '@app/users/user';
import { dollarsToCents, formatDollars } from '@app/utilities/round-to-cents';

type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

interface BudgetField {
  readonly category: string;
  readonly generic: boolean;
  readonly key: string;
  readonly name: string;
  readonly plaidPersonalFinanceCategory: {
    readonly detailed: string;
    readonly primary: string;
  };
  readonly us: boolean;
}
export type UserDataNumberKeys = KeysMatching<UserData, number | undefined>;

interface BudgetCategories {
  debt: () => void;
  expenses: () => void;
  income: () => void;
  insurance: () => void;
  investments: () => void;
  retirement: () => void;
  savings: () => void;
}

export interface BudgetComputed {
  monthlybudget: number;
  totalincome: number;
  totalmonthlydebt: number;
  totalmonthlyexpenses: number;
  totalmonthlyinsurance: number;
  totalmonthlyinvestments: number;
  totalmonthlyretirement: number;
  totalmonthlysavings: number;
}

export const BUDGET_FIELDS = BF as readonly BudgetField[];

@Injectable({ providedIn: 'root' })
export class BudgetCalculatorService {
  public budgetType: FeaturesConfig['budget'] = 'us'; // default to US budget

  private readonly _budgetTypeSub$: Observable<FeaturesConfig>;

  constructor(private readonly clientConfig: ClientConfigService) {
    this._budgetTypeSub$ = this.clientConfig.featuresConfig$.pipe(
      tap((featuresConfig: FeaturesConfig): void => {
        this.budgetType = featuresConfig.budget;
      }),
    );

    // TODO: ROB 20231217 - Make this Service Observable based.
    this._budgetTypeSub$.pipe(takeUntilDestroyed()).subscribe({
      error: (err: unknown): void => {
        console.error('BudgetCalculatorService#budgetType$', err);
      },
    });
  }

  /** Performs all of the calculation on the user's data to populate the computed data. */
  public fillComputedFields(userData: UserDataFirestore): BudgetComputed {
    // Initialize data. Ensure that returned values are never undefined.
    const computedCents: BudgetComputed = {
      monthlybudget: 0,
      totalincome: 0,
      totalmonthlydebt: 0,
      totalmonthlyexpenses: 0,
      totalmonthlyinsurance: 0,
      totalmonthlyinvestments: 0,
      totalmonthlyretirement: 0,
      totalmonthlysavings: 0,
    };

    for (const field of BUDGET_FIELDS) {
      if ((this.budgetType === 'us' && field.us) || (this.budgetType === 'generic' && field.generic)) {
        const fieldKey = field.key as UserDataNumberKeys;
        const value = userData[fieldKey] ?? 0;

        const categories: BudgetCategories = {
          debt: (): void => {
            computedCents.totalmonthlydebt += dollarsToCents(value);
          },
          expenses: (): void => {
            computedCents.totalmonthlyexpenses += dollarsToCents(value);
          },
          income: (): void => {
            // income represents a pretax value on US budget
            // income represents a post-tax value on generic budget
            const cents = dollarsToCents(value);
            computedCents.totalincome += field.key === 'monthlyincomeaftertaxes' ? cents * 12 : cents;
          },
          insurance: (): void => {
            computedCents.totalmonthlyinsurance += dollarsToCents(value);
          },
          investments: (): void => {
            computedCents.totalmonthlyinvestments += dollarsToCents(value);
          },
          retirement: (): void => {
            computedCents.totalmonthlyretirement += dollarsToCents(value);
          },
          savings: (): void => {
            computedCents.totalmonthlysavings += dollarsToCents(value);
          },
        };
        const category = field.category as keyof typeof categories;
        const add = categories[category];
        if (typeof add === 'function') {
          add();
        } else {
          console.error('BudgetCaculatorService#fillComputedFields', field.category);
        }
      }
    }

    computedCents.monthlybudget = computedCents.totalmonthlydebt + computedCents.totalmonthlyexpenses + computedCents.totalmonthlysavings;

    if (this.budgetType === 'us') {
      computedCents.monthlybudget += computedCents.totalmonthlyinsurance + computedCents.totalmonthlyinvestments
      + computedCents.totalmonthlyretirement;
    }

    return Object.fromEntries(
      Object.entries(computedCents).map(
        ([ key, cents ]: [ string, number ]): [ string, number ] => [ key, formatDollars(cents) ],
      ),
    ) as unknown as BudgetComputed;
  }
}
