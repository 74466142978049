// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lux-fifth-third-incentive-widget-wrapper {
  border: 1px solid #d6dbdc;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  text-align: center;
}
.lux-fifth-third-incentive-widget-wrapper:first-child {
  margin-bottom: 1rem;
}

.info {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #475053;
  font-size: 0.875rem;
}

.prev-earnings {
  color: #29a59a;
  font-weight: 600;
}

.learn-more {
  font-weight: 600;
}

.earnings {
  color: #b37700;
  font-size: 0.875rem;
  font-weight: 600;
}
.earnings.complete {
  font-weight: 600;
}

hr {
  margin: 0.875rem 1rem;
}

a {
  color: #29a59a;
}
a:hover {
  text-decoration: underline;
}

.goal-text {
  flex: 1;
  padding-left: 12px;
  text-align: start;
  color: #181b1c;
}
.goal-text .goal-info {
  margin-bottom: 0.5rem;
}
.goal-text .goal-progress {
  margin-bottom: 0;
  font-weight: 600;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
}

.goal {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
}
.goal .goal-progress {
  color: #29a59a;
  font-size: 0.875rem;
}
.goal .goal-info {
  font-size: 0.875rem;
}
.goal:hover {
  background: #f1f3f3;
  text-decoration: none;
}

.chart {
  max-width: 3.75rem;
}

.money {
  margin-left: 0.5rem;
  border-left: 1px solid #9faaae;
  padding-left: 0.5rem;
  color: #b37700;
  font-weight: 600;
}

.material-symbols-rounded {
  margin-right: 5px;
  border-radius: 50%;
  background-color: #ffea9d;
  color: #eac63f;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
