import { defer, tap } from 'rxjs';
import type { MonoTypeOperatorFunction, Observable } from 'rxjs';

/**
 * Wait for the observable to be subscribed to, and then only perform the tap the first time for
 * each subscription.
 * Based on:
 * - https://indepth.dev/create-a-taponce-custom-rxjs-operator/
 * - https://medium.com/javascript-everyday/rxjs-taponce-operator-yet-another-solution-435f88e2484a
 */
export const tapOnce = <T>(fn: (value: T) => void): MonoTypeOperatorFunction<T> => {
  return (source: Observable<T>): Observable<T> =>
    defer((): Observable<T> => {
      let first = true;
      return source.pipe(
        tap<T>((payload: T): void => {
          if (first) {
            fn(payload);
          }
          first = false;
        }),
      );
    });
};
