import { Injectable } from '@angular/core';
import { // eslint-disable-line @typescript-eslint/consistent-type-imports -- Firestore is Angular Injected
  addDoc,
  collection,
  collectionData,
  collectionSnapshots,
  deleteDoc,
  doc,
  docData,
  Firestore,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FieldPath,
  OrderByDirection,
  PartialWithFieldValue,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  SetOptions,
  SnapshotOptions,
  Transaction,
  TransactionOptions,
  UpdateData,
  WhereFilterOp,
  WithFieldValue,
} from '@angular/fire/firestore';
import type { Observable } from 'rxjs';

export { Timestamp as FirestoreTimestamp } from '@angular/fire/firestore';
export type {
  CollectionReference,
  DocumentReference,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  Transaction,
};

@Injectable({ providedIn: 'root' })
export class AngularFirestoreService {
  public readonly orderByOrder: QueryOrderByConstraint;

  constructor(private readonly firestore: Firestore) {
    this.orderByOrder = this.orderBy('order');
  }

  public async addDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: CollectionReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>,
  ): Promise<DocumentReference<AppModelType, DbModelType>> {
    return addDoc<AppModelType, DbModelType>(reference, data);
  }

  public collection<AppModelType, DbModelType extends DocumentData = DocumentData>(
    path: string,
    ...pathSegments: string[]
  ): CollectionReference<AppModelType, DbModelType> {
    // Note that this method could accept a CollectionReference or DocumentReference in addton to Firestore,
    // but we are not supporting this at this time, manually merge the paths becaues we do that so rarely.
    return collection(this.firestore, path, ...pathSegments) as CollectionReference<AppModelType, DbModelType>;
  }

  public collectionData<T = DocumentData, U extends string = never>(
    aQuery: Query<T>,
    // Neither AngularFire nor rxfire expose this :-(
    options?: SnapshotOptions & {
      idField?: keyof NonNullable<T> & (U | keyof T);
    },
  ): Observable<Array<NonNullable<T> | T & { [K in U]: string; }>> {
    return collectionData<T, U>(aQuery, options);
  }

  public collectionSnapshots<T = DocumentData>(aQuery: Query<T>): Observable<Array<QueryDocumentSnapshot<T>>> {
    return collectionSnapshots<T>(aQuery);
  }

  public async deleteDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
  ): Promise<void> {
    return deleteDoc(reference);
  }

  public doc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    path: string,
    ...pathSegments: string[]
  ): DocumentReference<AppModelType, DbModelType> {
    // Note that this method could accept a CollectionReference or DocumentReference in addton to Firestore,
    // but we are not supporting this at this time, manually merge the paths becaues we do that so rarely.
    return doc(this.firestore, path, ...pathSegments) as DocumentReference<AppModelType, DbModelType>;
  }

  public docData<T = DocumentData, R extends T = T>(
    reference: DocumentReference<T>,
    // Neither AngularFire nor rxfire expose this :-(
    options?: SnapshotOptions & {
      idField?: keyof R;
    },
  ): Observable<R | T | undefined> {
    // docData<T = DocumentData>(reference: DocumentReference<T>, options?: DataOptions): Observable<T | undefined> {
    return docData<T, R>(reference, options);
  }

  public limit(maximum: number): QueryLimitConstraint {
    return limit(maximum);
  }

  public orderBy(fieldPath: FieldPath | string, directionStr?: OrderByDirection): QueryOrderByConstraint {
    return orderBy(fieldPath, directionStr);
  }

  // There is a QueryCompositeFilterConstraint parameter version of this that we haven't yet needed.
  public query<AppModelType, DbModelType extends DocumentData = DocumentData>(
    aQuery: Query<AppModelType, DbModelType>,
    ...constraints: QueryConstraint[]
  ): Query<AppModelType, DbModelType> {
    return query<AppModelType, DbModelType>(aQuery, ...constraints);
  }

  public async runTransaction<T>(updateTransaction: (transaction: Transaction) => Promise<T>, options?: TransactionOptions): Promise<T> {
    return runTransaction<T>(this.firestore, updateTransaction, options);
  }

  // https://github.com/firebase/firebase-js-sdk/blob/00737a1abd469f3deb041d8ff482165cc16bc34e/packages/firestore/src/api/reference_impl.ts#L247
  public async setDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>,
  ): Promise<void>;
  public async setDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    partialData: PartialWithFieldValue<AppModelType>,
    options: SetOptions,
  ): Promise<void>;
  public async setDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: PartialWithFieldValue<AppModelType>,
    options?: SetOptions,
  ): Promise<void> {
    // @ts-expect-error method signature from @firebase/firestore/dist/index.d.ts is poor (SetOptions | undefined).
    return setDoc<AppModelType, DbModelType>(reference, data, options);
  }

  // Skipping this signature as we don't use it and it makes things complicated to type.
  // public async updateDoc<T = DocumentData>(
  //   reference: DocumentReference<T>,
  //   field: FieldPath | string,
  //   value: unknown,
  //   ...moreFieldsAndValues: unknown[]
  // ): Promise<void>;
  public async updateDoc<AppModelType, DbModelType extends DocumentData = DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>,
  ): Promise<void> {
    return updateDoc<AppModelType, DbModelType>(reference, data);
  }

  public where(fieldPath: FieldPath | string, opStr: WhereFilterOp, value: unknown): QueryFieldFilterConstraint {
    return where(fieldPath, opStr, value);
  }
}
