import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';

import { GoalsService } from '../goals.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { Goal } from '../goals.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-goals-tab',
  standalone: true,
  styleUrls: [ './goals-tab.component.scss' ],
  templateUrl: './goals-tab.component.html',
})
export class GoalsTabComponent {
  /** Which of the user's selected goals is relevant to the provided checkupItem. */
  public readonly goals$: Observable<Goal[]>;

  private readonly _checkupItemSlugSub$: ReplaySubject<string>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly goalsService: GoalsService,
  ) {
    this._checkupItemSlugSub$ = new ReplaySubject<string>(1);

    this.goals$ = combineLatest([
      this.clientConfig.featuresConfig$.pipe(map((config: FeaturesConfig): boolean => config.goalsEnabled)),
      this._checkupItemSlugSub$,
      this.goalsService.goals$,
    ]).pipe(
      map(([ goalsEnabled, slug, goals ]: [ boolean, string, Goal[] ]): Goal[] => {
        if (!goalsEnabled) {
          return [];
        }

        const filterBy = (goal: Goal): boolean => {
          // If the user has selected this goal and if the checkupItem is relevant to it.
          const a = goal.enabled && goal.checkupItems.includes(slug);

          // If the goal is not "Other"
          const b = goal.slug !== 'other';

          return a && b;
        };

        return goals.filter(filterBy);
      }),
    );
  }

  /** The checkupItem slug to filter goals by. */
  @Input({ required: true }) public set checkupItemSlug(slug: string) {
    this._checkupItemSlugSub$.next(slug);
  }
}
