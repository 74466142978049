import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { computePasswordStrength } from '@app/utilities/password-complexity';

/**
 * Usage:
 * ```
 *   new FormGroup(
 *    {
 *      password1: new FormControl('', [ Validators.required, Validators.minLength(num) ]),
 *      password2: new FormControl('', [ Validators.required, Validators.minLength(num) ]),
 *    },
 *    { validators: passwordsMatch('password1', 'password2') },
 *  );
 * ```
 */
export const passwordsMatch = (password: string, confirm: string): ValidatorFn => {
  // control: AbstractControl is required by the type ValidatorFn.
  // It might be more clear if the function parameters was (formCntrl: FormGroup)
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordCntrl = control.get(password);
    const confirmCntrl = control.get(confirm);
    if (!passwordCntrl) {
      throw new Error(`Cannot find FormControl named '${password}'.`);
    }
    if (!confirmCntrl) {
      throw new Error(`Cannot find FormControl named '${confirm}'.`);
    }
    return passwordCntrl.value === confirmCntrl.value ? null : { passwordsmatch: true }; // eslint-disable-line unicorn/no-null
  };
};

/**
 * Strength:
 *  - Weak:   `str < 45`
 *  - Okay:   `45 <= str < 65`
 *  - Strong: `65 <= str`
 * Usage: `new FormControl('', [ Validators.required, Validators.minLength(num), passwordStrength(45) ]),`
 */
export const passwordStrength = (minStrength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    // Like Validators.email, rely on Validators.required to check for blank passwords.
    if (control.value == undefined || control.value === '') {
      return null; // eslint-disable-line unicorn/no-null
    }

    if (typeof control.value !== 'string') {
      throw new TypeError(`Invalid Control Value Type: '${typeof control.value}'`);
    }
    const strength = computePasswordStrength(control.value);
    return strength < minStrength ? { passwordstrength: strength } : null; // eslint-disable-line unicorn/no-null
  };
};
