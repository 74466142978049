/** Retrieves Lesson and LessonTags from the cms via firebase functions */
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
import type { AppConfig } from '@app/app-config/app-config';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { Slides } from '@app/presentation/slides/slides';
import { getCountry } from '@app/utilities/locale';

import type { Lesson, LessonTags } from '../lessons';
import type { LessonsDataService } from './lessons-data.service';

@Injectable({ providedIn: 'root' })
export class CMSLessonsDataService implements LessonsDataService {
  public readonly lessons$: Observable<Lesson[]>;
  public readonly lessonTags$: Observable<LessonTags[]>;
  private readonly _countryCode: string;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly afFns: AngularFireFunctionsService,
  ) {
    this._countryCode = getCountry(this.appConfig.locale);

    this.lessonTags$ = this.getCategories().pipe(
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.lessons$ = this.lessonTags$.pipe(
      map((categories: LessonTags[]): Lesson[] => {
        return this._getUniqueLessons(this._getLessonsFromCategories(categories));
      }),
    );
  }

  /**
   * Fetches the categories from the CMS.
   *
   * The CMS returns the categories sorted by order.
   * @returns Array of categories sorted by order with lessons populated
   */
  public getCategories(): Observable<LessonTags[]> {
    const callable = this.afFns.httpsCallable<{ country: string }, LessonTags[]>('getCategoriesData');
    return callable({ country: this._countryCode });
  }

  /**
   * Fetch slides for a lesson given the lesson id.
   * The CMS returns the slides sorted by order.
   * @param id - The id of the lesson to fetch slides for
   * @returns The slides for the lesson sorted by order
   */
  public getSlidesForLessonById(id: string): Observable<Slides[]> {
    const callable = this.afFns.httpsCallable<{ id: string }, Slides[]>('getSlidesByLessonId');
    return callable({ id });
  }

  /**
   * Creates a lesson for a replaced lesson. That would otherwise not be in the list of lessons.
   *
   * @param lesson - The lesson that replaces this lesson slug
   * @returns the 'replaced' lesson
   */
  private _createReplacedLesson(lesson: Lesson): Lesson {
    if (!lesson.replaces) {
      // Should never happen
      throw new Error(`Lesson ${lesson.id} does not replace another lesson`);
    }

    return {
      ...lesson,
      id: `${lesson.id}-replaced`,
      active: false,
      replacementSlug: lesson.slug,
      slug: lesson.replaces,
    };
  }

  /**
   * Flattens all lessons from the categories into a single array of lessons.
   * Replaced lessons are also included in the array as a copy of their replacement.
   *
   * Assumptions:
   * CMS does not return the replaced lesson with the categories. Only the replacement.
   * A lesson can only be replaced by one other lesson per client.
   *
   * @param categories - The categories to get lessons from
   * @returns A list of all lessons (including replaced lessons) from the categories
   */
  private _getLessonsFromCategories(categories: LessonTags[]): Lesson[] {
    return categories.flatMap((category: LessonTags): Lesson[] =>
      category.lessons.flatMap((lesson: Lesson): Lesson[] => {
        const newLesson = this._normalizeLesson(lesson);
        return newLesson.replaces
        ? [ newLesson, this._createReplacedLesson(newLesson) ] // Add a lesson for the replaced lesson
        : [ newLesson ];
      }));
  }

  /**
   * Filters a list of lessons to only include unique ids.
   * @param lessons - The unfiltered list of lessons
   * @returns A list of lessons with unique ids
   */
  private _getUniqueLessons(lessons: Lesson[]): Lesson[] {
    const set = new Set();
    return lessons.filter((lesson: Lesson): boolean => {
      if (set.has(lesson.id)) {
        return false;
      }
      set.add(lesson.id);
      return true;
    });
  }

  /**
   * Removes INTL from lesson locale and replaces it with the country code.
   *
   * @param lesson - the lesson to normalize
   * @returns the lesson with the locale replacing INTL with the country code
   */
  private _normalizeLesson(lesson: Lesson): Lesson {
    return {
      ...lesson,
      locale: lesson.locale.replace('INTL', this._countryCode),
    };
  }
}
