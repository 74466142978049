/**
 * This is a really outdated implementation of a password complexity calculator.
 * Better would be to use something modern like: https://zxcvbn-ts.github.io/zxcvbn/
 */
const alphaRe = /[A-Za-z]/u;
const middleCharsRe = /^(?<start>[A-Z]+|[a-z]+|\d+|[^\dA-Za-z]+)(?:.*?)(?<end>[A-Z]+|[a-z]+|\d+|[^\dA-Za-z]+)$/u;
const numericRe = /\d/u;

interface CharPosMap {
  [char: string]: number[];
}

/**
 * Calculates increment deduction based on proximity to identical characters. Deduction is incremented
 * each time a new match is discovered. Deduction amount is based on total password length divided by
 * the difference of distance between currently selected match.
 */
const computeRepeatCharScore = (charPosMap: CharPosMap, length: number): number => {
  let nRepeat = 0;
  let repCharPenalty = 0;

  for (const positions of Object.values(charPosMap)) {
    for (const i1 of positions) {
      let repeatInc = 0;

      for (const i2 of positions) {
        if (i1 !== i2) {
          repeatInc += Math.abs(length / (i2 - i1));
        }
      }

      if (repeatInc > 0) {
        nRepeat++;
        repCharPenalty += repeatInc;
        const nUnqChar = length - nRepeat;
        if (nUnqChar) {
          repCharPenalty /= nUnqChar;
        }
        repCharPenalty = Math.ceil(repCharPenalty);
      }
    }
  }

  return repCharPenalty;
};

/**
 * Search the str for sequences of substrings of seqStr 3 characters long (forward or reversed)
 * Returns the number of instances of such sequences found.
 */
const getSequentialCount = (str: string, seqStr: string): number => {
  let count = 0;
  const maxIdx = seqStr.length - 3;
  const othersRe = new RegExp(`[^${seqStr}]`, 'gu');
  const filteredStr = str.replace(othersRe, '');
  for (let i = 0; i <= maxIdx; i++) {
    const fwd = seqStr.slice(i, i + 3);
    const bwd = [ ...fwd ].reverse().join('');
    if (filteredStr.includes(fwd) || filteredStr.includes(bwd)) {
      count++;
    }
  }
  return count;
};

/**
 * Strengths:
 *  - Very Weak:         `str < 20`
 *  - Weak:        `20 <= str < 40`
 *  - Good:        `40 <= str < 60`
 *  - Strong:      `60 <= str < 80`
 *  - Very Strong: `80 <= str`
 * Based on:
 *  - https://www.uic.edu/apps/strong-password/
 *  - https://github.com/lechuckroh/password-meter/blob/master/functions.ts
 */
export const computePasswordStrength = (password: string): number => {
  const charPosMap: CharPosMap = {};
  const chars = [ ...password ];
  const nChars = chars.length;

  // Count the number of symbol characters that aren't part of the first and last consecutive groups
  // 'abcdTheMiddle123'.match(middleCharsRe) == [, 'abcd', 'TheMiddle', '123']
  const middleCharsMatch = middleCharsRe.exec(password);
  // const middleChars: string = middleCharsMatch ? middleCharsMatch[2] : '';
  const middleStartIndx = middleCharsMatch?.groups?.['start'] ? middleCharsMatch.groups['start'].length : 0;
  const middleEndIndx = nChars - (middleCharsMatch?.groups?.['end'] ? middleCharsMatch.groups['end'].length : 0);

  let iPrevAlpha = 0;
  let iPrevNum = 0;
  let nAlpha = 0;
  let nConsecAlpha = 0;
  let nConsecNumber = 0;
  let nMiddleNumSym = 0;
  let nNumber = 0;
  let nSymbol = 0;

  // Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches
  for (const [ i, char ] of chars.entries()) {
    if (alphaRe.test(char)) {
      if (iPrevAlpha + 1 === i) {
        nConsecAlpha++;
      }

      iPrevAlpha = i;
      nAlpha++;
    } else if (numericRe.test(char)) {
      if (iPrevNum + 1 === i) {
        nConsecNumber++;
      }

      iPrevNum = i;
      nNumber++;
      if (i >= middleStartIndx && i < middleEndIndx) {
        nMiddleNumSym++;
      }
    } else { // It is a symbol character (which would include non ASCII letters)
      nSymbol++;
      if (i >= middleStartIndx && i < middleEndIndx) {
        nMiddleNumSym++;
      }
    }

    // Makes `noUncheckedIndexedAccess` happy to use the `map` temporary variable
    const map = charPosMap[char] ??= []; // eslint-disable-line no-multi-assign, @typescript-eslint/no-unnecessary-condition
    map.push(i);
  }

  // For testing this is useful to compare to the values calculated from the source implementations
  // console.log(
  //   'Number of characters', nChars * 4, '\n',
  //   'Letters', nAlpha * 2, '\n',
  //   'Numbers', nNumber * 4, '\n',
  //   'Symbols', nSymbol * 6, '\n',
  //   'Middle Numbers or Symbols', nMiddleNumSym * 2, '\n',
  //   'Letters Only', - (nNumber + nSymbol ? 0 : nAlpha), '\n',
  //   'Numbers Only', - (nAlpha + nSymbol ? 0 : nNumber), '\n',
  //   'Repeat Characters', - computeRepeatCharScore(charPosMap, nChars), '\n',
  //   'Consecutive letters', - nConsecAlpha * 2, '\n',
  //   'Consecutive numbers', - nConsecNumber * 2, '\n',
  //   'Sequential letters (3 + )', - getSequentialCount(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz') * 3, '\n',
  //   'Sequential keyboard letters (3 + )', - getSequentialCount(password.toLowerCase(), 'qwertyuiopasdfghjklzxcvbnm') * 3, '\n',
  //   'Sequential numbers', - getSequentialCount(password, '01234567890') * 3, '\n',
  //   'Sequential symbols', - getSequentialCount(password, '~!@#$%^&*()_+{}|[]\\:";\'<>?,./') * 3, '\n',
  // );

  /* eslint-disable no-implicit-coercion, @stylistic/space-unary-ops, @stylistic/operator-linebreak */
  const ret =
    // Bonuses
    // Number of characters
    + nChars * 4
    // Letters
    + nAlpha * 2
    // Numbers
    + nNumber * 4
    // Symbols
    + nSymbol * 6
    // Middle Numbers or Symbols
    + nMiddleNumSym * 2
    // Penalties
    // Letters Only
    - (nNumber + nSymbol ? 0 : nAlpha)
    // Numbers Only
    - (nAlpha + nSymbol ? 0 : nNumber)
    // Repeat Characters
    - computeRepeatCharScore(charPosMap, nChars)
    // Consecutive letters
    - nConsecAlpha * 2
    // Consecutive numbers
    - nConsecNumber * 2
    // Sequential letters
    - getSequentialCount(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz') * 3
    // Sequential keyboard letters
    - getSequentialCount(password.toLowerCase(), 'qwertyuiopasdfghjklzxcvbnm') * 3
    // Sequential numbers
    - getSequentialCount(password, '01234567890') * 3
    // Sequential symbols
    - getSequentialCount(password, String.raw`~!@#$%^&*()_+{}|\[\]\\:";'<>?,./`) * 3
    ; // eslint-disable-line @stylistic/semi-style
  /* eslint-enable no-implicit-coercion, @stylistic/space-unary-ops, @stylistic/operator-linebreak */

  return ret;
};
