/**
 * Dynamically add a `<script>` tag to the DOM.
 * This bypasses many security features, but vendor scripts that we need to decide which
 * to load at run time already have that failing.
 *
 * Additionally, since this is a Service it cannot use Angular's Renderer(2) which is not
 * provided to Services. But since these scripts are expected to be completely outside of
 * Angular's purview I don't think that matters.
 */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { first, fromEvent } from 'rxjs';
import type { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScriptsService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public loadJsScript(src: string): Observable<Event> {
    const scriptEl = this.document.createElement('script');
    scriptEl.src = src;
    // There might be a race condition here between load event and observable subscribe.
    // Setting up the Observable before attaching to the DOM I think will make it more likely to
    // have the event.
    const obs$ = fromEvent(scriptEl, 'load').pipe(first());
    this.document.body.append(scriptEl);
    return obs$;
  }
}
