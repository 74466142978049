import { Injectable } from '@angular/core';
import {
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService, FirebaseEmailAuthProvider } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FirebaseUser, FirebaseUserCredential } from '@app/angular-fire-shims/angular-fire-auth.service';

import { AuthService } from './auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

@Injectable({ providedIn: 'root' })
export class EmailPassLoginService {
  constructor(
    private readonly afAuth: AngularFireAuthService,
    private readonly authService: AuthService,
  ) {}

  /**
   * User must be currently authenticated to updateEmail.
   * Firebase requires a "recent" authentication by the user in order to change the email. So this
   * function will use the password to re-authenticate the currentUser and then updateEmail
   * with the newEmail.
   * https://firebase.google.com/docs/auth/web/manage-users#set_a_users_email_address
   */
  public changeEmail(newEmail: string, password: string): Observable<boolean> {
    const validEmail = !newEmail;
    const validPassword = !password;
    if (validEmail || validPassword) {
      console.error('EmailPassLoginService#changeEmail', 'blank new email:', validEmail, 'blank password:', validPassword);
      return of(false);
    }

    return this.authService.requireUser$.pipe(
      // Because we are calling _reauthenticateUser within this pipe we need to make sure only the
      // first time causes the subscribe to emit.
      switchMap((user: FirebaseUser): Observable<FirebaseUser> => this._reauthenticateUser(user, password)),
      switchMap((user: FirebaseUser): Observable<boolean> => this.authService.setEmail(user, newEmail)),
    );
  }

  /**
   * User must be currently authenticated to changePassword because the changePassword form doesn't
   * include the email address.
   * Firebase requires a "recent" authentication by the user in order to change the password. So this
   * function will use the oldPassword to re-authenticate the currentUser and then updatePassword
   * with the newPassword.
   * https://firebase.google.com/docs/auth/web/manage-users#set_a_users_password
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    if (!newPassword || !oldPassword) {
      console.error('EmailPassLoginService#changePassword', 'blank new password:', !newPassword, 'blank old password:', !oldPassword);
      return of(false);
    }

    return this.authService.requireUser$.pipe(
      switchMap((user: FirebaseUser): Observable<FirebaseUser> => this._reauthenticateUser(user, oldPassword)),
      switchMap(async (user: FirebaseUser): Promise<boolean> => {
        await this.afAuth.updatePassword(user, newPassword);
        return true;
      }),
    );
  }

  /**
   * Associates a NEW email and password credential with the current user. This is intended to be used
   * for external federated auth provider users to also have an email and password to login when
   * provider login isn't available. (E.g. Outside of the company's network.)
   * https://firebase.google.com/docs/auth/web/account-linking
   */
  public linkEmailAndPassword(email: string, password: string): Observable<boolean> {
    const credential = FirebaseEmailAuthProvider.credential(email, password);

    return this.authService.requireUser$.pipe(
      switchMap(async (user: FirebaseUser): Promise<FirebaseUserCredential> => this.afAuth.linkWithCredential(user, credential)),
      switchMap((userCreds: FirebaseUserCredential): Observable<boolean> => this.sendVerificationEmail(userCreds.user)),
    );
  }

  /**
   * Uses Firebase auth to sign in a user with email & password.
   * Updates this.isLoggedIn to true if login is successful. isLoggedIn being true doesn't necessarily
   * mean that Firebase currently considers the user signed in. this.currentUser$ will always reflect
   * the current authenticated user from Firebase.
   */
  public login(email: string, password: string): Observable<boolean> {
    if (!email || !password) {
      console.error('EmailPassLoginService#login', 'blank email:', !email, 'blank password:', !password);
      return of(false);
    }

    const signInPromise = this.afAuth.signInWithEmailAndPassword(email, password);
    return from(signInPromise).pipe(map((): boolean => true));
  }

  /**
   * Creates a new Firebase user from email and password. The user will be logged in.
   * Updates this.isLoggedIn to true if successful. isLoggedIn being true doesn't necessarily mean
   * that Firebase currently considers the user signed in. this.currentUser$ will always reflect
   * the current authenticated user from Firebase.
   */
  public register(email: string, password: string): Observable<boolean> {
    if (!email || !password) {
      console.error('EmailPassLoginService#register', 'blank email:', !email, 'blank password:', !password);
      return of(false);
    }

    const createUserPromise = this.afAuth.createUserWithEmailAndPassword(email, password);
    return from(createUserPromise).pipe(
      switchMap((result: FirebaseUserCredential): Observable<boolean> => this.sendVerificationEmail(result.user)),
    );
  }

  /**
   * Causes Firebase to send the password reset email to a user by email address.
   * We specifically set the destination URL after password reset to the login page for the current
   * domain name. This allows us to support multiple client domains.
   */
  public sendPasswordResetEmail(email: string): Observable<boolean> {
    if (!email) {
      console.error('EmailPassLoginService#sendPasswordResetEmail', 'blank email');
      return of(false);
    }

    // Add continue URL for after reseting password.
    const actionCodeSettings = {
      url: `https://${this.authService.appDomain}/login`,
    };

    const promise = this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
    return from(promise).pipe(map((): boolean => true));
  }

  /** Causes Firebase to send the password verification email to a user. */
  public sendVerificationEmail(user: FirebaseUser): Observable<boolean> {
    // Add continue URL for after verifying email.
    const actionCodeSettings = {
      url: `https://${this.authService.appDomain}/`,
    };

    const sendEmailPromise = this.afAuth.sendEmailVerification(user, actionCodeSettings);
    return from(sendEmailPromise).pipe(map((): boolean => true));
  }

  /**
   * Some security-sensitive actions—such as deleting an account, setting a primary email address,
   * and changing a password—require that the user has recently signed in.
   * *Note:* This method is _only_ for Email & Password credentialed Users!
   * https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
   */
  private _reauthenticateUser(user: FirebaseUser, password: string): Observable<FirebaseUser> {
    // Some SSO SAML (Aaron's) Users may not have an email.
    const { email }: { email: string | null } = user;
    if (!email) {
      throw new Error('User is missing an e-mail. Unable to authenticate.');
    }

    // Ensure that the user has a recent authentication before attempting security-sensitive actions.
    const authCredential = FirebaseEmailAuthProvider.credential(email, password);
    const signInPromise = this.afAuth.reauthenticateWithCredential(user, authCredential);

    // Return observable from the promise.
    return from(signInPromise).pipe(
      map((credentials: FirebaseUserCredential): FirebaseUser => credentials.user),
    );
  }
}
