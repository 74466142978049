import { CommonModule } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  map,
  Subject,
  takeUntil,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { AppConfig } from '@app/app-config/app-config';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { FeaturesConfig, SelfSignupConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { CountryDropdownComponent } from '../country-dropdown/country-dropdown.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { ProviderLoginComponent } from './provider-login/provider-login.component';

export interface ViewModel {
  emailLoginEnabled: boolean;
  hasProvider: boolean;
  loginMessage?: string;
  numProviders: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CountryDropdownComponent,
    EmailLoginComponent,
    ProviderLoginComponent,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-login',
  standalone: true,
  styleUrls: [ './auth-login.component.scss' ],
  templateUrl: './auth-login.component.html',
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  public readonly config$: Observable<ViewModel>;
  public emailLoginDropdown: boolean = false;
  public readonly logo?: string;
  public readonly organization: string;
  public signedOutReason: string | undefined = undefined;
  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly clientConfig: ClientConfigService,
    private readonly route: ActivatedRoute,
  ) {
    this.logo = this.appConfig.clientLogo;
    this.organization = this.appConfig.organization;

    this.config$ = combineLatest([
      this.clientConfig.featuresConfig$.pipe(map((config: FeaturesConfig): boolean => config.emailLoginEnabled)),
      this.clientConfig.selfSignupConfig$,
    ]).pipe(
      map(([ emailLoginEnabled, config ]: [ boolean, SelfSignupConfig ]): ViewModel => {
        // include email as one of the providers for the sake of this count
        const count = config.providers.length + (emailLoginEnabled ? 1 : 0);
        return {
          emailLoginEnabled,
          hasProvider: config.providers.length > 0,
          loginMessage: config.loginMessage,
          numProviders: count,
        };
      }),
    );
  }

  public ngOnDestroy(): void {
    // Emit a value to complete the observable
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this._destroy$),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Error in AuthLoginComponent:', err);
      },
      next: (params: { [key: string]: string }): void => {
        this.signedOutReason = params['signedOutReason'] ?? undefined;
      },
    });
  }

  public openEmailLoginDropdown(): void {
    this.emailLoginDropdown = true;
  }
}
