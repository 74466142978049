import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';

import { GoalsService } from '../goals.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { Goal } from '../goals.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-goals-badge',
  standalone: true,
  styleUrls: [ './goals-badge.component.scss' ],
  templateUrl: './goals-badge.component.html',
})
export class GoalsBadgeComponent {
  // Which of the user's selected goals is relevant to the provided lesson.
  public readonly goals$: Observable<Goal[]>;

  private readonly _lessonSlugSub$: ReplaySubject<string>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly goalsService: GoalsService,
  ) {
    this._lessonSlugSub$ = new ReplaySubject<string>(1);

    this.goals$ = combineLatest([
      this.clientConfig.featuresConfig$.pipe(map((config: FeaturesConfig): boolean => config.goalsEnabled)),
      this.goalsService.goals$,
      this._lessonSlugSub$,
    ]).pipe(
      map(([ goalsEnabled, allGoals, slug ]: [ boolean, Goal[], string ]): Goal[] => {
        if (!goalsEnabled) {
          return [];
        }

        const filterBy = (goal: Goal): boolean => {
          // If the user has selected this goal and if the lesson is relevant to it.
          const a = goal.enabled && goal.lessons.includes(slug);

          // Goal is not 'other'
          const b = goal.slug !== 'other';

          return a && b;
        };

        return allGoals.filter(filterBy);
      }),
    );
  }

  // The provided lesson slug.
  @Input({ required: true }) public set lessonSlug(slug: string) {
    this._lessonSlugSub$.next(slug);
  }
}
