// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lux-profile-progress {
  border: 1px solid #f1f3f3;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem 0.5rem;
  counter-reset: step;
}
.lux-profile-progress header {
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1px solid #f1f3f3;
  text-align: center;
}
.lux-profile-progress header svg {
  display: block;
  margin: 0 auto;
  margin-bottom: 6px;
  width: 100px;
  height: 100px;
}
.lux-profile-progress header path {
  stroke-linecap: round;
  stroke-width: 3;
  fill: none;
}
.lux-profile-progress header h3 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.lux-profile-progress header .progress-bar {
  stroke: #d6dbdc;
}
.lux-profile-progress header .progress-complete {
  stroke: #29a59a;
  stroke-dasharray: 233;
}
.lux-profile-progress header .display-3 {
  position: absolute;
  top: 33px;
  width: 100%;
}
.lux-profile-progress .active {
  border: 2px solid #29a59a;
  border-radius: 0.375rem;
}
.lux-profile-progress .line-item {
  position: relative;
  padding: 0.625rem 1rem 0.625rem 3rem;
}
.lux-profile-progress .line-item::after {
  position: absolute;
  top: 8px;
  left: 10px;
  border: 1px solid #d6dbdc;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  content: "";
}
.lux-profile-progress .line-item.done::before {
  position: absolute;
  top: -12px;
  left: 6px;
  z-index: 1;
  color: #29a59a;
  font-family: "Material Symbols Rounded";
  font-size: 2.675rem;
  content: "done";
}
.lux-profile-progress .advisor-item::after {
  content: normal;
}
.lux-profile-progress .step-count::before {
  content: counter(step) ". ";
  counter-increment: step;
}
.lux-profile-progress .name {
  font-weight: 600;
}
.lux-profile-progress .description {
  color: #76868b;
  font-size: 0.75rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
