// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-outline-primary {
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-gray-100);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: var(--bs-primary);
  box-shadow: none;
  background-image: var(--lux-goal-icon);
  background-position: center left 6px;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 0.4375rem 0.6875rem 0.4375rem 31px;
  font-size: 0.75rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
