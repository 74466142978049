/**
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
import { CommonModule, Location } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FirebaseError } from '@angular/fire/app';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  from,
  map,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService } from '@app/angular-fire-shims/angular-fire-auth.service';
import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { DirtyErrorsPipe } from '@app/pipes/dirty-errors/dirty-errors.pipe';
import { InvalidFormPipe } from '@app/pipes/invalid-form/invalid-form.pipe';
import { AutofocusDirective } from '@app/stuff/autofocus/autofocus.directive';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { getActionState } from '../action-state';
import type { AuthActionState } from '../action-state';
import { PasswordStrengthComponent } from '../password-strength/password-strength.component';
import { passwordsMatch, passwordStrength } from '../validators/password.validator';

interface ActionCodeData {
  readonly actionCode: string;
  readonly email: string;
  readonly errorCode: string;
  readonly validCode: boolean;
}

interface FormValues {
  readonly password1: string;
  readonly password2: string;
}

interface SubmitValues {
  readonly actionCode: string;
  readonly newPassword: string;
}

interface ViewModelSubject {
  readonly errorCode: string;
  readonly showForm: boolean;
  readonly successMessage: boolean;
}

export interface ViewModel extends ActionCodeData, ViewModelSubject {}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutofocusDirective,
    CommonModule,
    DirtyErrorsPipe,
    ErrorMessageComponent,
    InvalidFormPipe,
    PasswordStrengthComponent,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  public readonly authForm: FormGroup;
  public loginUrl: string = '/login';
  public readonly minPasswordLen: number = 8;
  public readonly pass1Cntrl: FormControl;
  public readonly pass2Cntrl: FormControl;
  public readonly vm$: Observable<ViewModel>;

  private readonly _submitSub$: Subject<SubmitValues>;
  private readonly _vmSub$: BehaviorSubject<ViewModelSubject>;

  constructor(
    private readonly afAuth: AngularFireAuthService,
    private readonly location: Location,
    private readonly router: Router,
  ) {
    this._submitSub$ = new Subject<SubmitValues>();

    this._submitSub$.pipe(
      switchMap(({ actionCode, newPassword }: SubmitValues): Observable<void> =>
        from(this.afAuth.confirmPasswordReset(actionCode, newPassword)).pipe(
          catchError((err: unknown): Observable<never> => {
            if (err instanceof Error) {
              this._handleError(err);
            }
            return EMPTY;
          }),
        )),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('PasswordComponent#subscribe#error', err);
      },
      next: (): void => {
        this._handleSuccess();
      },
    });

    this._vmSub$ = new BehaviorSubject<ViewModelSubject>({
      errorCode: '',
      showForm: true,
      successMessage: false,
    });

    this.pass1Cntrl = new FormControl('', [ Validators.required, Validators.minLength(this.minPasswordLen), passwordStrength(45) ]);
    this.pass2Cntrl = new FormControl('', [ Validators.required, Validators.minLength(this.minPasswordLen) ]);
    this.authForm = new FormGroup(
      {
        password1: this.pass1Cntrl,
        password2: this.pass2Cntrl,
      },
      { validators: passwordsMatch('password1', 'password2') },
    );

    this.vm$ = combineLatest([
      this._verifyCode(),
      this._vmSub$,
    ]).pipe(map(([ actionData, vmSub ]: [ ActionCodeData, ViewModelSubject ]): ViewModel => ({
      ...actionData,
      ...vmSub,
      errorCode: vmSub.errorCode || actionData.errorCode, // Merge these two fields into one by priority.
    })));
  }

  public onSubmit(actionCode: string): void {
    this._vmSub$.next({
      errorCode: '', // Hide any existing error message.
      showForm: false, // Hide form to prevent multiple submission.
      successMessage: false, // Remove the success message for new submit.
    });

    const { password1, password2 } = this.authForm.value as FormValues;

    if (password1 !== password2) {
      throw new Error('Passwords must match.');
    }

    this._submitSub$.next({ actionCode, newPassword: password1 });
  }

  private _getActionState(): AuthActionState | undefined {
    const maybeState = this.location.getState();
    try {
      return getActionState(maybeState);
    } catch (stateErr) {
      console.error('ResetPasswordComponent#ActionCode error', stateErr);
      // actionCode is missing, go to default route
      this.router.navigateByUrl('/', { replaceUrl: true })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err: unknown): void => {
          console.error('ResetPasswordComponent#handleInviteError', err);
        });
    }
    return undefined;
  }

  private _handleError(err: Error | FirebaseError): void {
    this._vmSub$.next({
      errorCode: 'code' in err ? err.code : err.message,
      showForm: true,
      successMessage: false,
    });
  }

  private _handleSuccess(): void {
    this._vmSub$.next({
      errorCode: '',
      showForm: true,
      successMessage: true,
    });
  }

  private _verifyCode(): Observable<ActionCodeData> {
    const state = this._getActionState();
    if (state == undefined) {
      return EMPTY;
    }
    const { actionCode, nextUrl } = state;
    this.loginUrl = nextUrl;

    return from(this.afAuth.verifyPasswordResetCode(actionCode)).pipe(
      map((email: string): ActionCodeData => ({
        actionCode,
        email,
        errorCode: '',
        validCode: true,
      })),
      catchError((err: unknown): Observable<ActionCodeData> => {
        // Possible errors:
        // auth/expired-action-code
        // auth/invalid-action-code
        // auth/user-disabled
        // auth/user-not-found
        console.error('ResetPasswordComponent#AngularFireAuth#verifyPasswordResetCode Error', err);
        if (err instanceof Error) {
          const someErr = err as Error | FirebaseError;
          return of({
            actionCode,
            email: '',
            errorCode: 'code' in someErr ? someErr.code : someErr.message,
            validCode: false,
          });
        }
        throw new Error('Unknown Error Occurred.');
      }),
    );
  }
}
