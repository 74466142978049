/**
 * Sets the NgForOf#ngForTrackBy TrackByFunction to return a property of item. This is only to be
 * used on object types.
 * Example use:
 *   `*ngFor="let detailed of item.detailedCategories; luxTrackByProperty:'plaidCategory'"`
 * Note that `luxTrackByProperty` must be last thing in the `*ngFor`.
 */
import { NgForOf } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { Directive, Host, Input } from '@angular/core';

@Directive({
  // This selector must start with `ngFor` because it is used in a *ngFor structural directive
  // binding. Somehow, for some reason, Angular prefixes the selector with `ngFor`, but on use it is
  // `luxTrackByProperty:'thePropertyName'`
  selector: '[ngForLuxTrackByProperty]', // eslint-disable-line @angular-eslint/directive-selector
  standalone: true,
})
export class TrackByPropertyDirective<T extends object> {
  @Input({ required: true }) public ngForLuxTrackByProperty!: keyof T;

  constructor(@Host() private readonly ngForOfDir: NgForOf<T>) {
    this.ngForOfDir.ngForTrackBy = (_: number, item: T): T[keyof T] => item[this.ngForLuxTrackByProperty];
  }
}
