/* eslint-disable @typescript-eslint/no-floating-promises, max-classes-per-file */
import { Inject, Injectable, NgZone } from '@angular/core'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  animationFrameScheduler,
  interval,
  take,
  tap,
  timer,
} from 'rxjs';
import type {
  Observable,
  SchedulerAction,
  SchedulerLike,
  Subscription,
} from 'rxjs';

import { CONFETTI } from './confetti.provider';
import type { ConfettiType } from './confetti.provider';

@Injectable({ providedIn: 'root' })
export class ConfettiService {
  /**
   * Default colors from canvas-confetti
   * https://github.com/catdad/canvas-confetti/blob/80ad2360470ba4b9e546bb5c06ac4d60c9460566/src/confetti.js#L182-L190
   */
  private readonly _colors: readonly string[] = [
    '#ef5454',
    '#eac63f',
    '#22b8a0',
    '#26a38b',
    '#f1a615',
    '#17b1c8',
    '#ff3666',
  ] as const;

  private readonly _runOutsideAngularScheduler: LeaveZoneScheduler;

  /** This should line up with the $z-indexes['confetti'] value in the SCSS variables abstract. */
  private readonly _zIndex: number = 5000;

  constructor(
    @Inject(CONFETTI) private readonly confetti: ConfettiType,
    private readonly ngZone: NgZone,
  ) {
    // I believe that by marrying the rxjs animationFrameScheduler and NgZone.runOutsideAngular that
    // our confetti should have less impact on the responsivness of our app. But I haven't really
    // tested that.
    this._runOutsideAngularScheduler = new LeaveZoneScheduler(this.ngZone, animationFrameScheduler);
  }

  /** https://www.kirilv.com/canvas-confetti/#realistic */
  public cannon(): void {
    this._fire(0.25, { spread: 26, startVelocity: 55 });
    this._fire(0.2, { spread: 60 });
    this._fire(0.35, { decay: 0.91, scalar: 0.8, spread: 100 });
    this._fire(0.1, { decay: 0.92, scalar: 1.2, spread: 120, startVelocity: 25 }); // eslint-disable-line @stylistic/object-curly-newline
    this._fire(0.1, { spread: 120, startVelocity: 45 });
  }

  /**
   * 15 seconds of confetti with a `total` of 60 (60 * 250 ms == 15 seconds)
   * https://www.kirilv.com/canvas-confetti/#fireworks
   */
  public fireworks(total: number): Observable<number> {
    const defaults: confetti.Options = {
      spread: 360,
      startVelocity: 30,
      ticks: 60,
      zIndex: this._zIndex,
    };

    // Possibly don't need this schedular here, but it doesn't seem to hurt anything.
    return timer(0, 250, this._runOutsideAngularScheduler).pipe(
      take(total),
      tap((cnt: number): void => {
        const particleCount = ((total - cnt) / total) * 50;
        const optionsLeft: confetti.Options = {
          ...defaults,
          origin: { x: this._randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          particleCount,
        };
        const optionsRight: confetti.Options = {
          ...defaults,
          origin: { x: this._randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          particleCount,
        };

        this.confetti(optionsLeft);
        this.confetti(optionsRight);
      }),
    );
  }

  /**
   * Hard to say what `duration` means here... number of pieces of confetti * 4 I guess.
   * This will run as fast ast animationFrameScheduler/requestAnimationFrame allows. Hopefully without
   * performance impacts to the app.
   * https://www.kirilv.com/canvas-confetti/#continuous
   */
  public schoolPride(duration: number): Observable<number> {
    return interval(0, this._runOutsideAngularScheduler).pipe(
      take(duration),
      tap((cnt: number): void => {
        // Because this only generates 2 particles per call, need to walk through the colors array to get a variety
        const colors: string[] = [
          this._colors[cnt % this._colors.length] as string,
          this._colors[(cnt + 1) % this._colors.length] as string,
        ];

        // 50% squares, 45% circles, 5% stars -- per Rebecca.
        const shapes: confetti.Shape[] = [
          'square',
          'circle',
          'star',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
          'circle',
          'square',
        ];

        this.confetti({
          angle: 60,
          colors,
          origin: { x: 0 },
          particleCount: 2,
          shapes,
          spread: 55,
          zIndex: this._zIndex,
        });
        this.confetti({
          angle: 120,
          colors,
          origin: { x: 1 },
          particleCount: 2,
          shapes,
          spread: 55,
          zIndex: this._zIndex,
        });
      }),
    );
  }

  /**
   * Hard to say what `duration` means here... number of snow flakes I guess.
   * This will run as fast ast animationFrameScheduler/requestAnimationFrame allows. Hopefully without
   * performance impacts to the app.
   * https://www.kirilv.com/canvas-confetti/#snow
   */
  public snow(duration: number): Observable<number> {
    let skew = 1;

    return interval(0, this._runOutsideAngularScheduler).pipe(
      take(duration),
      tap((cnt: number): void => {
        const ticks = Math.max(200, ((duration - cnt) / duration) * 500);
        skew = Math.max(0.8, skew - 0.001);

        this.confetti({
          // Because this only generates 1 particle per call, need to walk through the colors array to get a variety
          colors: [ this._colors[cnt % this._colors.length] as string ],
          drift: this._randomInRange(-0.4, 0.4),
          gravity: this._randomInRange(0.4, 0.6),
          origin: {
            x: Math.random(),
            // since particles fall down, skew start toward the top
            y: (Math.random() * skew) - 0.2,
          },
          particleCount: 1,
          scalar: this._randomInRange(0.4, 1),
          startVelocity: 0,
          ticks,
          zIndex: this._zIndex,
        });
      }),
    );
  }

  /** Used by this.cannon, but moved to the outerscope for better linting. */
  private readonly _fire = (particleRatio: number, opts: confetti.Options): void => {
    const count = 200;
    const defaults: confetti.Options = { origin: { y: 0.7 }, zIndex: this._zIndex };

    const options: confetti.Options = {
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    };

    this.confetti(options);
  };

  private readonly _randomInRange = (min: number, max: number): number => Math.random() * (max - min + min);
}

/**
 * I'm not really 100% sure that this is doing what we want, nor when we want. The idea is that these
 * confetti animations shouldn't trigger any Angular change detection because they are pure visuals.
 * https://github.com/ftischler/ngx-rxjs-zone-scheduler/blob/92791df6377ca76ffeb0cacd28fdd2b337d34425/projects/ngx-rxjs-zone-scheduler/src/lib/rx-ng-zone-scheduler.ts#L15
 */
class LeaveZoneScheduler implements SchedulerLike {
  constructor(
    private readonly zone: NgZone,
    private readonly scheduler: SchedulerLike,
  ) {}

  public now(): number {
    return this.scheduler.now();
  }

  public schedule<T>(work: (this: SchedulerAction<T>, state?: T) => void, delay: number, state?: T): Subscription {
    return this.zone.runOutsideAngular((): Subscription => this.scheduler.schedule(work, delay, state));
  }
}
