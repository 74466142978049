// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  border-bottom: 1px solid #d6dbdc;
  background-color: #fff;
}
@media (min-width: 992px) {
  header {
    padding-top: 2rem;
  }
}

.navbar-toggler-icon .material-symbols-rounded {
  font-size: 28px;
}

.navbar-brand {
  flex: 1;
  transform: scale(0.7);
  text-align: center;
}
@media (min-width: 992px) {
  .navbar-brand {
    transform: scale(1);
    padding-left: 1rem;
    text-align: start;
  }
}
.navbar-brand .logo-client,
.navbar-brand .lux-custom-logo {
  display: none;
}
@media (min-width: 576px) {
  .navbar-brand .logo-client,
  .navbar-brand .lux-custom-logo {
    display: initial;
    margin-left: 1rem;
    border-left: 1px solid #d6dbdc;
    padding-left: 1rem;
    width: auto;
    height: 34px;
  }
}
.navbar-brand .primary {
  border-left: none;
}
.navbar-brand .badge {
  opacity: 0.6;
  margin-left: 0.375rem;
  background-color: #181b1c;
  vertical-align: bottom;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.navbar-collapse {
  flex-grow: 0;
}

@media (max-width: 991.98px) {
  .navbar-nav {
    margin-top: 1rem;
    padding-left: 1rem;
  }
  .nav-link {
    font-size: 1.125rem;
  }
}
.active {
  text-decoration: underline;
}
.active .material-symbols-rounded {
  color: rgba(var(--bs-emphasis-color-rgb), 1);
}

.dropdown-toggle {
  --bs-nav-link-font-size: 1.5rem;
  display: none;
  border: none;
  background: transparent;
}
@media (min-width: 992px) {
  .dropdown-toggle {
    display: inline-block;
  }
}
.dropdown-toggle::after {
  content: unset;
}
.dropdown-toggle:hover,
.dropdown-toggle .active {
  font-weight: 500;
}

.dropdown-menu {
  width: 13.75rem;
}
@media (max-width: 991.98px) {
  .dropdown-menu {
    display: block;
    margin-top: 1rem;
    border: none;
    border-top: 1px solid #d6dbdc;
    border-radius: 0;
    padding-top: 1rem;
    width: auto;
  }
}
.dropdown-menu .section-break::before {
  display: list-item;
  margin: 1rem auto;
  background-color: #d6dbdc;
  height: 1px;
  content: "";
}
@media (min-width: 992px) {
  .dropdown-menu .section-break::before {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .is-champion {
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-item.nav-link {
    padding-left: 2rem;
  }
}
.dropdown-item.active:hover, .dropdown-item.active:focus, .dropdown-item:active:hover, .dropdown-item:active:focus {
  background-color: var(--bs-tertiary-bg);
}

:host-context(.onboarding) {
  position: static;
}
:host-context(.onboarding) header {
  border-bottom: 0;
}
:host-context(.onboarding) .navbar-brand {
  pointer-events: none;
}
:host-context(.onboarding) .navbar-toggler,
:host-context(.onboarding) .navbar-nav {
  display: none;
}

.champion {
  margin-bottom: 12px;
  background-color: #2a5a61;
  padding: 0.5rem;
  text-align: center;
}
.champion a {
  color: #fff;
}
.champion img {
  display: block;
  margin: 0 auto;
  width: 2.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
