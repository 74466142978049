/// <reference types="@angular/localize" />
import { mergeApplicationConfig } from '@angular/core';
import type { ApplicationRef } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';

import { APP_CONFIG } from './app/app-config/app-config';
import type { AppConfig } from './app/app-config/app-config';
import { AppComponent } from './app/app.component';
import { applicationConfig } from './app/app.config';
import { environment } from './environments/environment';

const version = 'v2.4';
const subDomain = window.location.hostname.split('.').shift() as string;
const configUrl = `/assets/config/config.${subDomain.toLowerCase()}-${version}.json`;

fetch(configUrl)
  .then(async (res: Response): Promise<AppConfig> => {
    if (!res.ok) {
      throw new Error(`Fetch Config ${res.status}: ${res.statusText} ${configUrl}`);
    }

    try {
      return await res.json() as AppConfig;
    } catch (err) {
      if (err instanceof Error) {
        throw new TypeError(`Config JSON parse error ${configUrl}: ${err.message}`);
      }
      throw err;
    }
  })
  .then(async (config: AppConfig): Promise<ApplicationRef> => {
    return bootstrapApplication(
      AppComponent,
      mergeApplicationConfig(
        { providers: [ { provide: APP_CONFIG, useValue: config } ] },
        applicationConfig,
      ),
    );
  })
  .catch((err: unknown): void => {
    console.error('main#fetch', configUrl, err);
    if (environment.production) { // eslint-disable-line @typescript-eslint/no-unnecessary-condition
      Sentry.captureException(err);
      // If the config has an error, then send them to the learnlux.com. App might be deleted.
      window.location.replace('https://learnlux.com/');
    }
  });
