import { Injectable } from '@angular/core';
import {
  combineLatest,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
import type { QueryDocumentSnapshot } from '@app/angular-fire-shims/angular-firestore.service';

import { TIER } from './checkup-items';
import type {
  CheckupItem,
  CheckupItemCategory,
  CheckupItemCategoryFirestore,
  CheckupItemFirestore,
} from './checkup-items';

export type { CheckupItem, CheckupItemCategory };

@Injectable({ providedIn: 'root' })
export class CheckupService {
  /** A list of checkup item categories sorted by tier. */
  public readonly categories$: Observable<CheckupItemCategory[]>;

  /** A list of all Checkup Items sorted by tier and order with their lessons loaded. */
  public readonly checkupItems$: Observable<CheckupItem[]>;

  constructor(private readonly afStore: AngularFirestoreService) {
    const checkupCollection = this.afStore.collection<CheckupItemCategoryFirestore>('checkup');
    const query = this.afStore.query(checkupCollection, this.afStore.orderBy('tier'));
    this.categories$ = this.afStore.collectionData(query).pipe(
      map(
        (rawCategories: CheckupItemCategoryFirestore[]): CheckupItemCategory[] => rawCategories.map(
          (rawCategory: CheckupItemCategoryFirestore): CheckupItemCategory => ({ ...rawCategory, date: rawCategory.date.toDate() }),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.checkupItems$ = this.afStore.collectionSnapshots(query).pipe(
      switchMap(
        (actions: Array<QueryDocumentSnapshot<CheckupItemCategoryFirestore>>): Observable<CheckupItem[]> => this._getCheckupItems(actions),
      ),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /** Get a list of Checkup Items (including lessons) within a particular Category tier. */
  public getCategoryItemsByTier(tier: number): Observable<CheckupItem[]> {
    if (tier < TIER.min || tier > TIER.max) {
      throw new Error(`tier is out of range ${TIER.min}..${TIER.max}.`);
    }

    const categoryQuestions = this.afStore.collection<CheckupItemFirestore>(`checkup/tier-${tier}/questions`);
    const query = this.afStore.query(categoryQuestions, this.afStore.orderByOrder);
    return this.afStore
      .collectionData(query) // No need for ids
      .pipe(
        map(
          (rawCheckupItems: CheckupItemFirestore[]): CheckupItem[] => rawCheckupItems.map(
            (rawItem: CheckupItemFirestore): CheckupItem => ({ ...rawItem, date: rawItem.date.toDate(), tier }),
          ),
        ),
      );
  }

  /** Get a specific checkup item by key. */
  public getCheckupItemByKey(key: string): Observable<CheckupItem> {
    return this.checkupItems$.pipe(
      map((checkupItems: CheckupItem[]): CheckupItem => {
        const [ item, ...duplicates ]: CheckupItem[] = checkupItems.filter((i: CheckupItem): boolean => i.key === key);

        if (duplicates.length > 0) {
          throw new Error(`Duplicate '${key}' CheckupItem found.`);
        }

        if (item == undefined) {
          throw new Error(`CheckupItem '${key}' not found.`);
        }

        return item;
      }),
    );
  }

  private _getCheckupItems(snapshots: Array<QueryDocumentSnapshot<CheckupItemCategoryFirestore>>): Observable<CheckupItem[]> {
    const obs$: Array<Observable<CheckupItem[]>> = [];
    for (const snapshot of snapshots) {
      const { ref }: QueryDocumentSnapshot<CheckupItemCategoryFirestore> = snapshot;
      const checkupItemsCol = this.afStore.collection<CheckupItemFirestore>(ref.path, 'questions');
      const query = this.afStore.query(checkupItemsCol, this.afStore.orderByOrder);
      const tier = snapshot.get('tier') as number | undefined;
      if (tier == undefined || tier < TIER.min || tier > TIER.max) {
        throw new Error(`Checkup Question tier invalid: '${tier ?? 'undefined'}'`);
      }
      obs$.push(
        this.afStore
          .collectionData(query)
          .pipe(
            map(
              (rawCheckupItems: CheckupItemFirestore[]): CheckupItem[] => rawCheckupItems.map(
                (rawItem: CheckupItemFirestore): CheckupItem => ({ ...rawItem, date: rawItem.date.toDate(), tier }),
              ),
            ),
          ),
      );
    }

    return combineLatest(obs$).pipe(
      // Because the `checkupCollection` is sorted by tier, these items will be sorted by tier then order.
      map((checkupItemsByCategory: CheckupItem[][]): CheckupItem[] => {
        return checkupItemsByCategory.flat();
      }),
    );
  }
}
