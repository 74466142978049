import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { OnChanges } from '@angular/core';

import { TranslationModule } from '@app/translation/translation.module';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, TranslationModule ],
  interpolation: [ '#', '#' ], // Changing this makes the ICU expressions cleaner
  selector: 'lux-relative-date',
  standalone: true,
  templateUrl: './relative-date.component.html',
})
export class RelativeDateComponent implements OnChanges {
  /** Fallback format when outside of the relative date formatting range. */
  @Input() public dateFormat: string = 'short'; // https://angular.io/api/common/DatePipe#pre-defined-format-options
  @Input({ required: true }) public theDate!: Date;

  /** Number of hours or days relative to now. */
  public count!: number;

  /** Direct of the date: past or future */
  public direction!: string;

  /** Type of date to display: ss, mm, hh, dd, or other */
  public relative!: string;

  public ngOnChanges(): void {
    const now = Date.now();
    const timestamp = this.theDate.getTime();
    const difference = Math.abs(now - timestamp);

    this.direction = timestamp <= now ? 'past' : 'future';

    if (difference < MINUTE) {
      this.relative = 'ss';
    } else if (difference < HOUR) {
      this.relative = 'mm';
      this.count = Math.max(Math.round(difference / MINUTE), 1);
    } else if (difference < DAY) {
      this.relative = 'hh';
      this.count = Math.max(Math.round(difference / HOUR), 1);
    } else if (difference < 10 * DAY) {
      this.relative = 'dd';
      this.count = Math.max(Math.round(difference / DAY), 1);

    // After 10 days display the date
    } else {
      this.relative = 'other';
    }
  }
}
