import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailDomains = (allowedDomains: readonly string[]): ValidatorFn => {
  const cleanDomains: string[] = [];

  for (const domain of allowedDomains) {
    let safeDomain = domain;

    // Ensure that all the allowed domains start with the @ symbol.
    if (!domain.startsWith('@')) {
      safeDomain = `@${safeDomain}`;
    }

    // Report poorly configured allowed domain so that Sentry will alert us.
    if (domain.includes('.')) {
      cleanDomains.push(safeDomain);
    } else {
      console.error(`Allowed email domain '${domain}' is invalid`);
    }
  }

  if (cleanDomains.length === 0) {
    console.error('No allowed email domains configured.');
  }

  return (control: AbstractControl): ValidationErrors | null => {
    // Like Validators.email, rely on Validators.required to check for blank emails.
    if (control.value == undefined || control.value === '') {
      return null; // eslint-disable-line unicorn/no-null
    }

    if (typeof control.value !== 'string') {
      throw new TypeError(`Invalid Control Value Type: '${typeof control.value}'`);
    }

    const parts = control.value.split('@');
    if (parts.length === 2 && parts[0] && parts[1]) {
      const domain = `@${parts.pop() as string}`; // The above if ensures this is a string
      if (cleanDomains.includes(domain)) {
        return null; // eslint-disable-line unicorn/no-null
      }
    }
    return { forbiddenDomain: { value: control.value } };
  };
};
