// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  margin-bottom: 2rem;
  border-bottom: 1px solid #9faaae;
  padding-bottom: 1rem;
}

.btn-text {
  --bs-btn-font-size: .875rem;
  --bs-btn-disabled-color: var(--bs-white);
  margin-inline-end: 1.5rem;
  padding: 0;
  font-weight: 700;
}

.selected {
  text-decoration: underline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
