import { inject } from '@angular/core';
import { Router } from '@angular/router';
import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { map, take } from 'rxjs';
import type { Observable } from 'rxjs';

import { AuthService } from '../auth.service';

export const activateNoAuthGuard = (route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // null and falsey strings should result in using the AuthService.redirectUrl field.
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const nextUrl = route.queryParamMap.get('next') || authService.redirectUrl;

  // Loosely based on https://github.com/angular/angularfire/blob/5.4.2/src/auth-guard/auth-guard.ts
  return authService.isLoggedIn$.pipe(
    take(1), // RouterGuards that return observables must complete!
    map((loggedIn: boolean): UrlTree | boolean => {
      if (loggedIn) {
        return router.parseUrl(nextUrl);
      }
      return true;
    }),
  );
};
