import { Injectable } from '@angular/core';
import { combineLatest, map, shareReplay } from 'rxjs';
import type { Observable } from 'rxjs';

import type { AdvisorConfig, FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import type { UserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

interface StepStatus {
  readonly accounts: string;
  readonly advisor: string;
  readonly budget: string;
  readonly checkup: string;
  readonly goals: string;
  readonly profile: string;
}

export interface ProfileProgress {
  readonly advisorConfig: AdvisorConfig;
  readonly currentStep: string;
  readonly featuresConfig: FeaturesConfig;
  readonly firstname?: string;
  readonly percentProgress: number;
  readonly skippedSteps?: string[];
  readonly steps: StepStatus;
}

@Injectable({ providedIn: 'root' })
export class ProfileProgressService {
  public readonly progress$: Observable<ProfileProgress>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly userDataService: UserDataService,
  ) {
    this.progress$ = combineLatest([
      this.clientConfig.advisorConfig$,
      this.clientConfig.featuresConfig$,
      this.userDataService.userData$,
    ]).pipe(
      map(([ advisorConfig, featuresConfig, userData ]: [ AdvisorConfig, FeaturesConfig, UserData ]): ProfileProgress => {
        const accounts = featuresConfig.accountsEnabled ? this._computeAccountsProgress(userData) : 'disabled';
        const budget = featuresConfig.budget === 'disabled' ? 'disabled' : this._computeBudgetProgress(userData);
        const checkup = featuresConfig.checkupEnabled ? this._computeCheckupProgress(userData) : 'disabled';
        const goals = featuresConfig.goalsEnabled ? this._computeGoalsProgress(userData) : 'disabled';
        const profile = this._computeProfileProgress(userData);
        const advisor = this._computeAdvisorProgress(userData, advisorConfig);

        const currentStep = this._computeCurrentStep(profile, checkup, budget, goals, accounts, advisor);
        const percentProgress = this._computePercentProgress(accounts, advisor, budget, checkup, goals, profile);

        return {
          advisorConfig,
          currentStep,
          featuresConfig,
          firstname: userData.firstname,
          percentProgress,
          skippedSteps: userData.skippedProgressSteps ?? [],
          steps: {
            accounts: currentStep === 'accounts' ? 'active' : accounts,
            advisor: currentStep === 'advisor' ? 'active' : advisor,
            budget: currentStep === 'budget' ? 'active' : budget,
            checkup: currentStep === 'checkup' ? 'active' : checkup,
            goals: currentStep === 'goals' ? 'active' : goals,
            profile: currentStep === 'profile' ? 'active' : profile,
          },
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Step 5. Accounts
   * Require: the accountsSummary object on UserData
   */
  private _computeAccountsProgress(userData: UserData): string {
    if (userData.skippedProgressSteps?.includes('accounts')) {
      return 'done';
    }
    return userData.accountsSummary == undefined ? 'inprogress' : 'done';
  }

  /**
   * Step 6. Advisor
   * Require: scheduledPlannerCall and skippedProgressSteps on UserData
   */
  private _computeAdvisorProgress(userData: UserData, advisorConfig: AdvisorConfig): string {
    if (
      userData.scheduledPlannerCall
      || !advisorConfig.referenceEnabled
      || userData.skippedProgressSteps?.includes('advisor')
    ) {
      return 'done';
    }
    return 'inprogress';
  }

  /**
   * Step 3. Budget
   * Requires:
   *  - Sum of incomes are greater than 0
   *  - Sum of the expenses in monthly budget is greater than 0
   */
  private _computeBudgetProgress(userData: UserData): string {
    const isBudgetComplete = userData.isbudgetcomplete;
    return isBudgetComplete ? 'done' : 'inprogress';
  }

  /**
   *Step 2. Checkup
   * Requires: At least 1 checkup item marked as done.
   */
  private _computeCheckupProgress(userData: UserData): string {
    return userData.checkup && Object.values(userData.checkup).includes('done') ? 'done' : 'inprogress';
  }

  /**
   * Find the first inprogress step in order. If none are inprogress then default to lessons.
   * Steps: 1. Profile, 2. Checkup, 3. Budget, 4. Goals, 5. Accounts, 6. Planner (Advisor), 7. Lessons
   */
  private _computeCurrentStep(profile: string, checkup: string, budget: string, goals: string, accounts: string, advisor: string): string {
    if (profile === 'inprogress') {
      return 'profile';
    }

    if (checkup === 'inprogress') {
      return 'checkup';
    }

    if (budget === 'inprogress') {
      return 'budget';
    }

    if (goals === 'inprogress') {
      return 'goals';
    }

    if (accounts === 'inprogress') {
      return 'accounts';
    }

    if (advisor === 'inprogress') {
      return 'advisor';
    }

    return 'lessons';
  }

  /**
   * Step 4. Goals
   * Require: At least 1 selected goal.
   */
  private _computeGoalsProgress(userData: UserData): string {
    return userData.goals && Object.values(userData.goals).some(Boolean) ? 'done' : 'inprogress';
  }

  /**
   * Count all the active steps and the completed steps.
   * @returns Percent of steps completed.
   */
  private _computePercentProgress(...steps: string[]): number {
    let numSteps = 0;
    let numCompleted = 0;
    for (const status of steps) {
      if (status !== 'disabled') {
        numSteps += 1;
      }

      if (status === 'done') {
        numCompleted += 1;
      }
    }

    return numCompleted / numSteps;
  }

  /**
   * Step 1. Profile
   * Requires: firstname (we don't care about last name mostly), birthdate, maritalstatus, haschildren
   */
  private _computeProfileProgress(userData: UserData): string {
    const significantFields: Array<keyof UserData> = [ 'firstname', 'birthdate', 'maritalstatus', 'haschildren' ];
    const profileCnt = significantFields
      .map((field: keyof UserData): number => Number(Boolean(userData[field])))
      .reduce((cnt: number, val: number): number => cnt + val, 0);

    return profileCnt === significantFields.length ? 'done' : 'inprogress';
  }
}
