import { Injectable } from '@angular/core';
import { // eslint-disable-line @typescript-eslint/consistent-type-imports -- Auth is Angular Injected
  applyActionCode,
  Auth,
  authState,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAdditionalUserInfo,
  getRedirectResult,
  linkWithCredential,
  linkWithPopup,
  OAuthProvider,
  reauthenticateWithCredential,
  SAMLAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateEmail,
  updatePassword,
  verifyBeforeUpdateEmail,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import type {
  ActionCodeInfo,
  ActionCodeSettings,
  AdditionalUserInfo,
  AuthCredential,
  AuthProvider,
  PopupRedirectResolver,
  User,
  UserCredential,
} from '@angular/fire/auth';
import type { Observable } from 'rxjs';

export type {
  ActionCodeInfo as FirebaseActionCodeInfo,
  User as FirebaseUser,
  UserCredential as FirebaseUserCredential,
};

export {
  EmailAuthProvider as FirebaseEmailAuthProvider,
  OAuthProvider as FirebaseOAuthProvider,
  SAMLAuthProvider as FirebaseSAMLAuthProvider,
};

@Injectable({ providedIn: 'root' })
export class AngularFireAuthService {
  public readonly authState$: Observable<User | null>;

  constructor(private readonly auth: Auth) {
    this.authState$ = authState(this.auth);
  }

  public async applyActionCode(oobCode: string): Promise<void> {
    return applyActionCode(this.auth, oobCode);
  }

  public async checkActionCode(oobCode: string): Promise<ActionCodeInfo> {
    return checkActionCode(this.auth, oobCode);
  }

  public async confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  public async createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  public getAdditionalUserInfo(credential: UserCredential): AdditionalUserInfo | null {
    return getAdditionalUserInfo(credential);
  }

  public async getRedirectResult(resolver?: PopupRedirectResolver): Promise<UserCredential | null> {
    return getRedirectResult(this.auth, resolver);
  }

  public async linkWithCredential(user: User, credential: AuthCredential): Promise<UserCredential> {
    return linkWithCredential(user, credential);
  }

  public async linkWithPopup(user: User, provider: AuthProvider, resolver?: PopupRedirectResolver): Promise<UserCredential> {
    return linkWithPopup(user, provider, resolver);
  }

  public async reauthenticateWithCredential(user: User, credential: AuthCredential): Promise<UserCredential> {
    return reauthenticateWithCredential(user, credential);
  }

  public async sendEmailVerification(user: User, actionCodeSettings?: ActionCodeSettings): Promise<void> {
    return sendEmailVerification(user, actionCodeSettings);
  }

  public async sendPasswordResetEmail(email: string, actionCodeSettings?: ActionCodeSettings): Promise<void> {
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  }

  public setLanguageCode(locale: string): void {
    this.auth.languageCode = locale;
  }

  public async signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public async signInWithPopup(provider: AuthProvider, resolver?: PopupRedirectResolver): Promise<UserCredential> {
    return signInWithPopup(this.auth, provider, resolver);
  }

  public async signInWithRedirect(provider: AuthProvider, resolver?: PopupRedirectResolver): Promise<never> {
    return signInWithRedirect(this.auth, provider, resolver);
  }

  public async signOut(): Promise<void> {
    return signOut(this.auth);
  }

  public async updateEmail(user: User, email: string): Promise<void> {
    return updateEmail(user, email);
  }

  public async updatePassword(user: User, password: string): Promise<void> {
    return updatePassword(user, password);
  }

  public async verifyBeforeUpdateEmail(user: User, newEmail: string): Promise<void> {
    return verifyBeforeUpdateEmail(user, newEmail);
  }

  public async verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, code);
  }
}
