// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * WARNING: ViewEncapsulation.None All Styles must be wrapped in the parent class!
 */
.lux-banner {
  position: relative;
  background-color: #29a59a;
  padding: 1.5rem 3.25rem;
  text-align: center;
  color: #fff;
  font-size: 1.125rem;
}
.lux-banner a {
  text-decoration: underline;
  color: #fff;
}
.lux-banner a:hover {
  opacity: 0.75;
}
.lux-banner .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
}
.lux-banner .btn-close:focus, .lux-banner .btn-close:hover {
  opacity: 0.25;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
