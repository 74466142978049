import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig, MatchConfig } from '@app/app-config/app-config';
import type { UserDataFirestore } from '@app/users/user';

export interface LessonComputed {
  readonly actual401kmatch: number; // EMPLOYER 401(k) Matching Contribution
  readonly annualIncomeMinusEmployee401kcontribution: number;
  readonly creditutilizationrecommendation: number;
  readonly employee401kcontribution: number;
  readonly retirementyear: number;
  readonly yearsuntilretirement: number;
}

@Injectable({ providedIn: 'root' })
export class LessonCalculatorService {
  constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {}

  public fillComputedFields(userData: UserDataFirestore, age?: number): LessonComputed {
    const annualIncome = userData.annualincome ?? 0;

    // What percentage of their salary does the member contribute to their 401(k) each year?
    const employee401kcontributionrate = userData.employee401kcontributionrate ?? 0;

    // The percentage of the employee's salary that the employer will match.
    // If no match configuration, then default to allowing the user to write in the amount.
    const contributionRate = this.appConfig.matchConfig
      ? this._calculate401kContributionRate(employee401kcontributionrate, this.appConfig.matchConfig)
      : 0;

    const creditLimit = userData.creditlimit ?? 0;
    const currentYear = new Date().getFullYear();

    const employee401kcontribution = annualIncome * employee401kcontributionrate * .01;
    const annualIncomeMinusEmployee401kcontribution = Math.max(0, annualIncome - employee401kcontribution);

    // EMPLOYER Matching Rate as percent of employer contribution rate.
    // If no match configuration, then the user may manually provide their employer's match percentage.
    // If no match configuration AND no manually provdes match,  then assume it's a 100% match.
    const employerMatch = this.appConfig.matchConfig ? 100 : 0;

    const actual401kmatch = annualIncome * contributionRate * .01 * employerMatch * .01;

    const retireAge = userData.ageretire ?? 65;
    const userAge = age ?? 30;
    const yearsuntilretirement = retireAge - userAge;

    return {
      actual401kmatch, // EMPLOYER 401(k) Matching Contribution
      annualIncomeMinusEmployee401kcontribution, // The user's income, minus their 401k investment
      creditutilizationrecommendation: creditLimit * .3,
      employee401kcontribution,
      // (userData.futurevalueofshares * userData.numofshares) - (userData.numofshares * userData.exercisepriceofshares)
      retirementyear: yearsuntilretirement + currentYear, // (userData.ageretire - userData.age) + 2019
      yearsuntilretirement,
    };
  }

  /**
   * Calculate the Employer's contribution rate based on the app's configuration and
   * the employee's current 401(k) contribution rate.
   *
   * - employeeContributionRate - Percentage of their salary that the member contributes to their 401(k) each year.
   * - matchConfig - Configuration for how to calculate the current client's match amount.
   */
  private _calculate401kContributionRate(employeeContributionRate: number, matchConfig: MatchConfig): number {
    if (!matchConfig.max) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Missing matchConfig.max');
    }

    if (matchConfig.min == undefined) { // eslint-disable-line @typescript-eslint/no-unnecessary-condition
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Missing matchConfig.min');
    }

    if (matchConfig.min < 0) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Invalid matchConfig.min');
    }

    if (matchConfig.min >= matchConfig.max) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'matchConfig.max must exceed min');
    }

    if (!matchConfig.percent) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Missing matchConfig.percent');
    }

    if (!matchConfig.type) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Missing matchConfig.type');
    }

    if (matchConfig.type === 'flat' && matchConfig.min > 0) {
      console.error('LessonCalculatorService#calculate401kContributionRate', 'Flat matches cannot have a min contribution > 0');
    }

    // For stepped matches
    if (matchConfig.type === 'stepped') {
      return this._calculateSteppedMatch(employeeContributionRate, matchConfig);
    }

    // For flat matches
    return this._calculateFlatMatch(employeeContributionRate, matchConfig);
  }

  /*
   * Calculate the flat match based on the employer's configuration and employee's 401(k) contribution rate.
   *
   * Examples:
   * - Toast will match 50% on the first 6% of an employees salary that they contribute.
   * - New Balance will match 100% on the first 5% of an employees salary that they contribute.
   * - Fifth Thirds will match 100% on the first 4% of an employees salary that they contribute.
   *
   * Unsupported:
   * - City of Elizabeth City's match is not represented here because they do 5% with or without employee contribution.
   * - Afterpay's match is not represented here because we don't know what it will be at open enrollment.
   */
  private _calculateFlatMatch(employeeContributionRate: number, matchConfig: MatchConfig): number {
    // Percentage employee is contributing that the employer will match.
    // Limited by the max amount that the employer will match.
    const employeeContributionCapped = Math.min(matchConfig.max, employeeContributionRate);
    return employeeContributionCapped * matchConfig.percent / 100;
  }

  /*
   * Calculate the stepped match based on the employer's configuration and employee's 401(k) contribution rate.
   *
   * For example:
   * - iLendingDIRECT will match 100% on the first 1% of employee contributions,
       then 50% on contributions up to 6% of employee contributions.
   * - Aarons will match 100% on the first 3% of employee contributions,
       then 50% on contributions up to 5% of employee contributions.
   */
  private _calculateSteppedMatch(employeeContributionRate: number, matchConfig: MatchConfig): number {
    // Percentage employee is contributing that the employer will match.
    // Limited by the max amount that the employer will match.
    const employeeContributionCapped = Math.min(matchConfig.max, employeeContributionRate);

    // Convert 0-100 -> 0-1 to support more consistent config.
    const percent = matchConfig.percent / 100;

    // In the case of a stepped match, this is the percentage of employee contributions that are between the
    // amount that the employer matches at 100%, and the max.
    const offset = employeeContributionCapped - matchConfig.min;

    // Subtract the difference from the maximum match to return the actual stepped match amount.
    return employeeContributionCapped - Math.max(0, offset * percent);
  }
}
