/**
 * Name: SuggestionItemComponent
 * Description: Show the user a single suggestion
 */
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  map,
  of,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { CheckupService } from '@app/checkup/checkup.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { CheckupItem } from '@app/checkup/checkup.service';
import { ConfettiComponent } from '@app/confetti/confetti.component';
import { GoalsTabComponent } from '@app/goals/goals-tab/goals-tab.component';
import { LessonsListItemComponent } from '@app/lessons/lesson-list-item/list-item.component';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TrackByIdentityDirective } from '@app/track-by/track-by-identity.directive';
import { TranslationModule } from '@app/translation/translation.module';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserSuggestionsService } from '@app/users/user-suggestions.service';
import type { UserSuggestion } from '@app/users/user-suggestions.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

interface Payload {
  checkupItemKey: string;
  suggestionId: string;
  suggestionStatus: UserSuggestion['status'];
}

export interface ViewModel {
  checkupItem: CheckupItem;
  suggestion: UserSuggestion;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ConfettiComponent,
    GoalsTabComponent,
    LessonsListItemComponent,
    NgOptimizedImage,
    SpinnerComponent,
    TrackByIdentityDirective,
    TranslationModule,
  ],
  selector: 'lux-suggestion-item',
  standalone: true,
  styleUrls: [ './suggestion-item.component.scss' ],
  templateUrl: './suggestion-item.component.html',
})
export class SuggestionItemComponent {
  public readonly vm$: Observable<ViewModel>;

  private readonly _setStatusSub$: Subject<Payload>;
  private readonly _suggestionSub$: ReplaySubject<UserSuggestion>;

  constructor(
    private readonly checkupService: CheckupService,
    private readonly suggestionService: UserSuggestionsService,
    private readonly userDataService: UserDataService,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this._setStatusSub$ = new Subject<Payload>();
    this._suggestionSub$ = new ReplaySubject<UserSuggestion>(1);

    this._setStatusSub$.pipe(
      switchMap(
        (payload: Payload): Observable<boolean> =>
          this.suggestionService.setStatus(payload.suggestionId, payload.suggestionStatus).pipe(
            switchMap(
              (suggestionResult: boolean): Observable<boolean> => {
                this._trackEvent(payload);

                if (suggestionResult && payload.suggestionStatus === 'done') {
                  // If the current suggestion has an associated checkup item, we can mark that checkup item as done.
                  return this.userDataService.updateUserData({ checkup: { [payload.checkupItemKey]: 'done' } });
                }
                return of(suggestionResult);
              },
            ),
          ),
      ),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => { console.error('SuggestionComponent#doUpdate$', err); },
    });

    this.vm$ = this._suggestionSub$.pipe(
      switchMap(
        (suggestion: UserSuggestion): Observable<ViewModel> =>
          // getCheckupItemByKey will throw an error is the referenceKey doesn't map to a checkupItem
          this.checkupService.getCheckupItemByKey(suggestion.referenceKey).pipe(
            map((checkupItem: CheckupItem): ViewModel => ({ checkupItem, suggestion })),
          ),
      ),
    );
  }

  @Input({ required: true }) public set suggestion(val: UserSuggestion) {
    if (val.id == undefined) {
      throw new Error('Attribute suggestion with ID is required.');
    }

    this._suggestionSub$.next(val);
  }

  public update(suggestion: UserSuggestion, suggestionStatus: UserSuggestion['status']): void {
    if (suggestion.id == undefined) {
      throw new Error('Cannot update suggestion without ID.');
    }

    this._setStatusSub$.next({
      checkupItemKey: suggestion.referenceKey,
      suggestionId: suggestion.id,
      suggestionStatus,
    });
  }

  // Track Analytics Event.
  private _trackEvent(payload: Payload): void {
    // Mapping of suggestion status to the event name.
    const lookup = {
      dismissed: EVENTS.suggestionsSuggestionDismissed,
      done: EVENTS.suggestionsSuggestionCompleted,
      inprogress: EVENTS.suggestionsSuggestionUndone,
    };

    this.userTrackingService.trackEvent(lookup[payload.suggestionStatus], {
      id: payload.suggestionId,
      referenceKey: payload.checkupItemKey,
      status: payload.suggestionStatus,
      type: 'Checkup',
    });
  }
}
