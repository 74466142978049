import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports -- Functions is Angular Injected
import { Functions, httpsCallableData } from '@angular/fire/functions';
import type { HttpsCallableOptions } from '@angular/fire/functions';
import type { Observable } from 'rxjs';

type HttpsCallableDataFunction<RequestData, ResponseData> = (data: RequestData) => Observable<ResponseData>;

@Injectable({ providedIn: 'root' })
export class AngularFireFunctionsService {
  constructor(private readonly func: Functions) {}

  public httpsCallable<RequestData = unknown, ResponseData = unknown>(
    name: string,
    options?: HttpsCallableOptions,
  ): HttpsCallableDataFunction<RequestData, ResponseData> {
    return httpsCallableData<RequestData, ResponseData>(this.func, name, options);
  }
}
