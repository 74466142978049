import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lux-arrow',
  standalone: true,
  styleUrls: [ './arrow.component.scss' ],
  templateUrl: './arrow.component.html',
})

export class ArrowComponent {}
