// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-menu {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
@media (min-width: 992px) {
  .country-menu {
    max-height: 500px;
  }
}

.country-link {
  --bs-dropdown-item-padding-y: var(--bs-dropdown-item-padding-x);
  display: flex;
  justify-content: space-between;
}
.country-link .material-symbols-rounded {
  color: #29a59a;
}
@media (min-width: 576px) {
  .country-link .material-symbols-rounded {
    visibility: hidden;
  }
}
.country-link.disabled {
  background-color: rgba(41, 165, 154, 0.1);
  color: #000;
}
.country-link.disabled .material-symbols-rounded {
  visibility: hidden;
}
.country-link:hover, .country-link:focus {
  background-color: #29a59a;
  color: #fff;
}
.country-link:hover .material-symbols-rounded, .country-link:focus .material-symbols-rounded {
  visibility: visible;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
