import { differenceInCalendarDays } from 'date-fns';

export const diffInDays = (transactionDateNewest: Date, transactionDateOldest: Date): number => {
  // differenceInCalendarDays returns 0 days if the dates are the same.
  // These Timestamps are created from just the date portion of the string so they should have
  // the same time... but what if they don't?
  // Does 2022-04-14 - 2022-04-13 = 1 Day or 2? Maybe we should just be adding one to differenceInCalendarDays?
  if (transactionDateNewest.getTime() === transactionDateOldest.getTime()) {
    return 1;
  }
  return differenceInCalendarDays(transactionDateNewest, transactionDateOldest);
};
