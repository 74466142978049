/**
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
import { CommonModule, Location } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { FirebaseError } from '@angular/fire/app';
import { Router, RouterModule } from '@angular/router'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import {
  catchError,
  delay,
  EMPTY,
  map,
  of,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseActionCodeInfo } from '@app/angular-fire-shims/angular-fire-auth.service';
import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { getActionState } from '../action-state';
import type { AuthActionState } from '../action-state';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EmailPassLoginService } from '../email-pass-login.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EmailVerificationService } from '../email-verification.service';

export interface ViewModel {
  email: string;
  errorCode: string;
  nextUrl: string;
  resetPasswordSent: boolean;
  validCode: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ErrorMessageComponent,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-recover-email',
  standalone: true,
  templateUrl: './recover-email.component.html',
})
export class RecoverEmailComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly emailVerifyService: EmailVerificationService,
    private readonly emailLoginService: EmailPassLoginService,
    private readonly location: Location,
    private readonly router: Router,
  ) {
    this.vm$ = of(this.location.getState()).pipe(
      map(getActionState),
      catchError((stateErr: unknown): Observable<never> => {
        console.error('RecoverEmailComponent#ActionCode error', stateErr);
        // actionCode is missing, go to default route
        this.router.navigateByUrl('/', { replaceUrl: true })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((err: unknown): void => {
            console.error('RecoverEmailComponent#handleInviteError', err);
          });
        return EMPTY;
      }),
      switchMap(
        // Handle the action code, which should give us the email being restored for the template.
        (state: AuthActionState): Observable<ViewModel> => this.emailVerifyService.handleChangeEmailCode(state.actionCode).pipe(
          // Unfortunately it can take time for Firebase to recognize that the email has been restored so we can send the password
          // reset email.
          delay(500), // .5 seconds
          switchMap((info: FirebaseActionCodeInfo): Observable<ViewModel> => {
            const { email } = info.data;
            if (email) {
              // In case the account was compromised, send a password reset email as Firebase recommends.
              return this._sendPasswordReset(state, email);
            }
            return this._handleNoEmailError(state, info);
          }),
          catchError((err: unknown): Observable<ViewModel> => {
            // Possible errors:
            // auth/expired-action-code
            // auth/invalid-action-code
            // auth/user-disabled
            // auth/user-not-found
            console.error('RecoverEmailComponent#EmailVerificationService#handleChangeEmailCode Error', err);
            if (err instanceof Error) {
              const someErr = err as Error | FirebaseError;
              return of({
                email: '',
                errorCode: 'code' in someErr ? someErr.code : someErr.message,
                nextUrl: '/',
                resetPasswordSent: false,
                validCode: false,
              });
            }
            throw new Error('Unknown Error Occurred.');
          }),
        ),
      ),
    );
  }

  /**
   * If Firebase is functioning correctly this should _never_ happen because actionCodes for recovering email should always
   * have an email. The Types just don't reflect that.
   *
   * @param state - The action state from the location state.
   * @param info - The FirebaseActionCodeInfo for the code attempted to be applied.
   * @returns Observable that emits the error state view model to display.
   */
  private _handleNoEmailError(state: AuthActionState, info: FirebaseActionCodeInfo): Observable<ViewModel> {
    const errMsg = `ActionCodeInfo missing email: ${JSON.stringify(info)}`;
    console.error('RecoverEmailComponent#EmailVerificationService#handleChangeEmailCode Error', errMsg);
    return of({
      email: '',
      errorCode: '',
      nextUrl: state.nextUrl,
      resetPasswordSent: false,
      validCode: true,
    });
  }

  /**
   * Sends the password reset email to the recovered email.
   *
   * @param state - The action state from the location state.
   * @param email - The new email after email recovery.
   * @returns Observable that emits the view model to display. Valid state if successful, error state if not.
   */
  private _sendPasswordReset(state: AuthActionState, email: string): Observable<ViewModel> {
    return this.emailLoginService.sendPasswordResetEmail(email).pipe(
      map((): ViewModel => ({
        email,
        errorCode: '',
        nextUrl: state.nextUrl,
        resetPasswordSent: true,
        validCode: true,
      })),
      catchError((err: unknown): Observable<ViewModel> => {
        // Possible errors:
        // auth/invalid-email
        // auth/missing-continue-uri
        // auth/invalid-continue-uri
        // auth/unauthorized-continue-uri
        // auth/user-not-found
        console.error('RecoverEmailComponent#AngularFireAuth#applyActionCode#sendPasswordResetEmail Error', err);
        if (err instanceof Error) {
          const someErr = err as Error | FirebaseError;
          return of({
            email,
            errorCode: 'code' in someErr ? someErr.code : someErr.message,
            nextUrl: state.nextUrl,
            resetPasswordSent: false,
            validCode: true,
          });
        }
        throw new Error('Unknown Error Occurred.');
      }),
    );
  }
}
