// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-break-or {
  display: flex;
  gap: 0.5rem;
  margin: 1.125rem 0;
  text-align: center;
}
.horizontal-break-or::after, .horizontal-break-or::before {
  flex: 1;
  align-self: center;
  border-bottom: 1px solid #d6dbdc;
  padding-top: 2px;
  content: "";
}

.org-logo {
  height: 34px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
