import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { TranslationModule } from '@app/translation/translation.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ RouterModule, TranslationModule ],
  selector: 'lux-link-modal-content',
  standalone: true,
  templateUrl: './modal-content.component.html',
})
export class LinkModalContentComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
