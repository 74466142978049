import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
import type { DocumentReference, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

interface UserPermissionsBase {
  createdAt: Date | FirestoreTimestamp;
  lastModified: Date | FirestoreTimestamp;
  roles: string[];
}

export interface UserPermissions extends UserPermissionsBase {
  createdAt: Date;
  lastModified: Date;
}

// Should only be used for testing!
export interface UserPermissionsFirestore extends UserPermissionsBase {
  createdAt: FirestoreTimestamp;
  lastModified: FirestoreTimestamp;
}

type FirestoreDocument = DocumentReference<UserPermissionsFirestore>;

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {
  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
  ) {}

  public getUserPermissions(): Observable<UserPermissions | undefined> {
    return this.authService.currentUser$.pipe(
      map((user: FirebaseUser): FirestoreDocument => this.afStore.doc<UserPermissionsFirestore>(`permissions/${user.uid}`)),
      switchMap((docRef: FirestoreDocument): Observable<UserPermissionsFirestore | undefined> => this.afStore.docData(docRef)),
      map((rawPermissions: UserPermissionsFirestore | undefined): UserPermissions | undefined => {
        if (rawPermissions == undefined) {
          return rawPermissions;
        }

        // Firestore timestampsInSnapshots defaults to true since v5 of angularfire2.
        // So even though we put up a date, firestore is going to give us back a Timestamp that
        // we must jump through hoops to correct.

        const permissions: UserPermissions = {
          ...rawPermissions,
          createdAt: rawPermissions.createdAt.toDate(),
          lastModified: rawPermissions.lastModified.toDate(),
        };
        return permissions;
      }),
    );
  }
}
