import { Injectable } from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  shareReplay,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
import type { UserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { DEFAULT_USER_GOALS } from './goals';
import type { Goal, GoalFirestore, UserGoals } from './goals';

export type { Goal, UserGoals };

export type GoalsMap = Map<string, Goal>;

const mapGoals = (goal: Goal): [ string, Goal ] => [ goal.slug, goal ];

@Injectable({ providedIn: 'root' })
export class GoalsService {
  public readonly goals$: Observable<Goal[]>;
  public readonly goalsBySlug$: Observable<GoalsMap>;
  public readonly userGoals$: Observable<UserGoals>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly userDataService: UserDataService,
  ) {
    this.userGoals$ = this._getUserGoals();

    // Custom sorting needed, so not sorting in the database query.
    const collection = this.afStore.collection<GoalFirestore>('goals');
    this.goals$ = combineLatest([
      this.afStore.collectionData(collection), // No need for ids
      this.userGoals$,
    ]).pipe(
      map(([ goals, userGoals ]: [ GoalFirestore[], UserGoals ]): Goal[] => this.userDataService.mogrifyGoals(goals, userGoals)),
    );

    this.goalsBySlug$ = this.goals$.pipe(
      map((goals: Goal[]): GoalsMap => new Map(goals.map(mapGoals))),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /** Find the first GOALS item with a specific slug. */
  public getGoalBySlug(slug: string): Observable<Goal> {
    return this.goalsBySlug$.pipe(map((goalsBySlug: GoalsMap): Goal => {
      const goal = goalsBySlug.get(slug);
      if (goal == undefined) {
        throw new Error(`Goal for slug '${slug}' not found.`);
      }
      return goal;
    }));
  }

  /**
   * Fetch the User's goals from the database, and merge them with the default goals in case there
   * new ones.
   */
  private _getUserGoals(): Observable<UserGoals> {
    return this.userDataService.userData$.pipe(
      map((userData: UserData): UserGoals | undefined => userData.goals),
      distinctUntilChanged((x: UserGoals | undefined, y: UserGoals | undefined): boolean => {
        // Because of Javascript's object equality limitations, we need to manually check if these
        // objects are the same. Only if they are different do we want to trigger changes on this
        // observable and update everywhere downstream. While JSON.stringify does require the object
        // to be ordered (which is a problem in itself), these objects are coming from the same
        // source and thus this check is good enough.
        return JSON.stringify(x) === JSON.stringify(y);
      }),
      map((goals: UserGoals | undefined): UserGoals => ({ ...DEFAULT_USER_GOALS, ...goals })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
