import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FirebaseError } from '@angular/fire/app';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  map,
  Subject,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import { ErrorMessageComponent } from '@app/error-message/error-message.component';
import { DirtyErrorsPipe } from '@app/pipes/dirty-errors/dirty-errors.pipe';
import { InvalidFormPipe } from '@app/pipes/invalid-form/invalid-form.pipe';
import { AutofocusDirective } from '@app/stuff/autofocus/autofocus.directive';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { AuthService } from '../auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EmailPassLoginService } from '../email-pass-login.service';

interface FormSettings {
  errorCode: string;
  showForm: boolean;
  successMessage: boolean;
}

export interface ViewModel extends FormSettings {
  domain: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutofocusDirective,
    CommonModule,
    DirtyErrorsPipe,
    ErrorMessageComponent,
    InvalidFormPipe,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-forgot-password',
  standalone: true,
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  public readonly authForm: FormGroup;
  public readonly emailCntrl: FormControl;
  public readonly vm$: Observable<ViewModel>;

  private readonly _formSettingsSub$: BehaviorSubject<FormSettings>;
  private readonly _submitSub$: Subject<string>;

  constructor(
    private readonly authService: AuthService,
    private readonly emailLoginService: EmailPassLoginService,
  ) {
    this._formSettingsSub$ = new BehaviorSubject<FormSettings>({
      errorCode: '',
      showForm: true,
      successMessage: false,
    });

    this._submitSub$ = new Subject<string>();

    this._submitSub$.pipe(
      switchMap((email: string): Observable<boolean> => this.emailLoginService.sendPasswordResetEmail(email).pipe(
        catchError((err: unknown): Observable<never> => {
          if (err instanceof Error) {
            this._handleError(err);
          }
          return EMPTY;
        }),
      )),
      takeUntilDestroyed(),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('ForgotPasswordComponent#subscribe#error', err);
      },
      next: (): void => {
        this._handleSuccess();
      },
    });

    this.emailCntrl = new FormControl('', [ Validators.required, Validators.email ]);
    // FormGroup required for ngSubmit
    this.authForm = new FormGroup({ email: this.emailCntrl });

    this.vm$ = this._formSettingsSub$.pipe(
      map((settings: FormSettings): ViewModel => ({
        domain: this.authService.appDomain,
        ...settings,
      })),
    );
  }

  public onSubmit(): void {
    if (this.authForm.invalid) {
      throw new Error('Form Invalid');
    }

    this._formSettingsSub$.next({
      errorCode: '', // Hide any existing error message.
      showForm: false, // Hide form to prevent multiple submission.
      successMessage: false, // Hide the success message for new submit.
    });

    const { email } = this.authForm.value as { email: string };

    this._submitSub$.next(email);
  }

  private _handleError(err: Error | FirebaseError): void {
    console.warn('Auth Password Reset Error', err);

    this._formSettingsSub$.next({
      errorCode: 'code' in err ? err.code : err.message,
      showForm: true,
      successMessage: false,
    });
  }

  private _handleSuccess(): void {
    this._formSettingsSub$.next({
      errorCode: '',
      showForm: true,
      successMessage: true,
    });
  }
}
