/* eslint-disable max-lines */
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
} from '@angular/core';
import type { OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { // eslint-disable-line @typescript-eslint/consistent-type-imports
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  PRIMARY_OUTLET,
  Router,
  RouterModule,
} from '@angular/router';
import type { Data, Event } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  pairwise,
  share,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import type { Observable, Subscription } from 'rxjs';

import { AuthService } from './auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HeartbeatService } from './heartbeat.service';
import { HubSpotService } from './hub-spot/hub-spot.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { LayoutModule } from './layout/layout.module';
import { LoggerService } from './logger/logger.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { TranslationService } from './translation/translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { UserMonitorService } from './users/user-monitor.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { SessionListenerService } from './session-listener.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import type { AuthConfig } from './client-config/client-config';
import { TimeoutModalComponent } from './stuff/timeout-modal/timeout-modal.component';

type LoginPair = [ boolean | undefined, boolean | undefined ];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ LayoutModule, RouterModule ],
  selector: 'lux-root',
  standalone: true,
  styleUrls: [ './app.component.scss' ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') public pageClass: string = '';
  private readonly _destroy$: Subject<void> = new Subject<void>();
  private readonly _logout$: Observable<LoginPair>;
  private _modalRef: NgbModalRef | undefined = undefined;
  private readonly _routeData$: Observable<Data>;
  private _sessionTimeoutSubscription?: Subscription;
  // Emits when the user chooses to stay logged in, triggering the heartbeat service
  private readonly _stayLoggedIn$: Subject<void> = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly heartbeatService: HeartbeatService,
    private readonly hubspot: HubSpotService,
    private readonly logger: LoggerService,
    private readonly modalConfig: NgbModalConfig,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly translationService: TranslationService,
    private readonly userMonitor: UserMonitorService,
    private readonly sessionListenerService: SessionListenerService,
  ) {
    void this.sessionListenerService; // eslint-disable-line no-void

    this.modalConfig.ariaLabelledBy = 'title'; // All modals should have id="title" somewhere!
    this.modalConfig.centered = true;

    this._routeData$ = this.router.events.pipe(
      tap((ev: Event): void => {
        if (ev instanceof NavigationStart) {
          this.modalService.dismissAll('navigation');
        }
      }),
      filter((ev: Event): ev is NavigationEnd => ev instanceof NavigationEnd),
      tap((ev: NavigationEnd): void => {
        this.logger.setLocation(ev.urlAfterRedirects);
      }),
      map((_: NavigationEnd): ActivatedRoute => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route: ActivatedRoute): boolean => route.outlet === PRIMARY_OUTLET),
      mergeMap((route: ActivatedRoute): Observable<Data> => route.data),
    );

    this._logout$ = this.authService.isLoggedIn$
      .pipe(
        pairwise(),
        filter(([ prev, curr ]: LoginPair): boolean => prev === true && curr === false),
      );

    merge(
      this.hubspot.hubspotLoaded$.pipe(catchError((err: unknown): Observable<never> => {
        console.error('AppComponent#hubspot.hubspotLoaded$', err);
        return EMPTY;
      })),
      this._logout$.pipe(tap({
        error: (): void => { console.error('AppComponent#handleLoginRedirect'); },
        next: (): void => { this._handleLoginRedirect(); },
      })),
      this._routeData$.pipe(tap({
        error: (): void => { console.error('AppComponent#handleNavigation'); },
        next: (data: Data): void => { this._handleNavigation(data); },
      })),
      from(this.translationService.initTransifex()).pipe(catchError((err: unknown): Observable<never> => {
        console.error('AppComponent#translationService.initPromise', err);
        return EMPTY;
      })),
      this.userMonitor.monitor$.pipe(catchError((err: unknown): Observable<never> => {
        console.error('AppComponent#userMonitor.monitor$', err);
        return EMPTY;
      })),
    ).pipe(takeUntilDestroyed())
      .subscribe({
        error: (err: unknown): void => { console.error('AppComponent#ngOnInit', err); },
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._stayLoggedIn$.complete();
    this._stopSessionTimeoutHandling();
  }

  public ngOnInit(): void {
    this._setAppCookie();

    combineLatest([
      this.authService.isLoggedIn$,
      this.clientConfig.authConfig$,
      this.sessionListenerService.sessionIdsSynced$,
    ]).pipe(
      map(
        ([ isLoggedIn, authConfig, sessionIdsSynced ]: [boolean, AuthConfig, boolean]): {
          isLoggedIn: boolean;
          sessionIdsSynced: boolean;
          sessionTimeoutEnabled: boolean;
        } => ({
          isLoggedIn,
          sessionIdsSynced,
          sessionTimeoutEnabled: !!authConfig.sessionTimeout.enabled,
        }),
      ),
      distinctUntilChanged(
        (prev: {
          isLoggedIn: boolean;
          sessionIdsSynced: boolean;
          sessionTimeoutEnabled: boolean;
        }, curr: {
          isLoggedIn: boolean;
          sessionIdsSynced: boolean;
          sessionTimeoutEnabled: boolean;
        }): boolean =>
          prev.isLoggedIn === curr.isLoggedIn
          && prev.sessionTimeoutEnabled === curr.sessionTimeoutEnabled
          && prev.sessionIdsSynced === curr.sessionIdsSynced,
      ),
      tap(
        ({
          isLoggedIn,
          sessionIdsSynced,
          sessionTimeoutEnabled,
        }: {
          isLoggedIn: boolean;
          sessionIdsSynced: boolean;
          sessionTimeoutEnabled: boolean;
        }): void => {
          if (isLoggedIn && sessionTimeoutEnabled && sessionIdsSynced) {
            this._startSessionTimeoutHandling();
          } else {
            this._stopSessionTimeoutHandling();
          }
        },
      ),
      takeUntil(this._destroy$),
    ).subscribe({
      error: (err: unknown): void => {
        console.error('Failed to manage session timeout handling:', err);
      },
    });
  }

  /** When the user logs out, redirect them to the login page. */
  private _handleLoginRedirect(): void {
    // Save the current page so they return there on login, unless it is the logout page.
    if (this.router.url !== '/settings/logout') {
      this.authService.redirectUrl = this.router.url;
    }

    // Unload HubSpot Widget
    this.hubspot.removeWidget();

    // Check if logged out due to inactivity and redirect with a query param
    const queryParams: Record<string, string> = {};
    if (this.authService.loggedOutDueToInactivity) {
      queryParams['signedOutReason'] = 'Your session has expired. Please log in again.';
    }

    // eslint-disable-next-line promise/prefer-await-to-then
    this.router.navigate([ '/login' ], { queryParams }).catch((err: unknown): void => {
      console.error('AppComponent#handleLoginRedirect', err);
    });

    // Reset the inactivity flag after handling the redirection
    this.authService.loggedOutDueToInactivity = false;
  }

  /** Perform actions when the app completes navigation to a new route. */
  private _handleNavigation(data: Data): void {
    // Cannot use theComponent name because with minification it won't be a useful value
    // const theComponent = route.routeConfig.component;
    // this.pageClass = theComponent.name;
    // Have to reset this now if the route changes.
    this.pageClass = (data['pageClass'] as string | undefined) ?? '';

    this.logger.track('pageview');
    this.hubspot.trackPageView();
  }

  private _handleSessionTimeout(): Observable<void> {
    if (this._modalRef) {
      return EMPTY;
    }

    const modalRef = this.modalService.open(TimeoutModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });

    this._modalRef = modalRef;

    return from(modalRef.result).pipe(
      tap((result: string): void => {
        if (result === 'sessionExpired') {
          // eslint-disable-next-line rxjs/no-ignored-observable
          this.authService.logout(true);
        } else if (result === 'stayLoggedIn') {
          this._stayLoggedIn$.next();
        } else {
          // eslint-disable-next-line rxjs/no-ignored-observable
          this.authService.logout(true);
        }
      }),
      catchError((err: unknown): Observable<never> => {
        console.error('Modal dismissed with error:', err);
        // eslint-disable-next-line rxjs/no-ignored-observable
        this.authService.logout(true);
        return EMPTY;
      }),
      finalize((): void => {
        this._modalRef = undefined;
      }),
      map((): void => undefined),
    );
  }

  /**
   * Sets a cookie containing the domain for this app so that the public website can redirect users
   * to login to their app.
   */
  private _setAppCookie(): void {
    const domain = this.authService.appDomain;
    // If developing on localhost this will result in localhost being the parent domain.
    // Else it will set the cookie to primary domain for the app (last two parts) e.g. learnlux.com
    // The domain no longer needs to be prefixed with a dot:
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-cookie_domain
    const parentDomain = domain.split('.').slice(-2).join('.');
    const expiresDate = new Date(Date.now() + 31_536_000_000); // One year in milliseconds
    const cookieString = `learnlux-app=${encodeURIComponent(domain)};`
      + `expires=${expiresDate.toUTCString()};`
      + 'path=/;'
      + `domain=${parentDomain};`
      + 'SameSite=Lax;' // https://web.dev/samesite-cookies-explained/
      + 'secure;';
    this.document.cookie = cookieString;
  }

  private _startSessionTimeoutHandling(): void {
    if (this._sessionTimeoutSubscription) {
      return;
    }
    const userActivity$ = merge(
      fromEvent(this.document, 'keydown'),
      fromEvent(this.document, 'pointerdown'),
      fromEvent(this.document, 'pointermove'),
      fromEvent(this.document, 'scroll'),
      fromEvent(this.document, 'visibilitychange'),
      fromEvent(window, 'focus'),
      fromEvent(window, 'load'),
      fromEvent(window, 'online'),
      fromEvent(window, 'resize'),
    ).pipe(
      takeUntil(
        this.authService.isLoggedIn$.pipe(filter((isLoggedIn: boolean): boolean => !isLoggedIn)),
      ),
      share(),
    );

    const resetTimer$ = merge(
      userActivity$.pipe(
        filter((): boolean => !this._modalRef),
        tap((): void => {
          this.heartbeatService.eventTrigger();
        }),
      ),
      this._stayLoggedIn$.pipe(
        tap((): void => {
          this.heartbeatService.eventTrigger();
        }),
      ),
    ).pipe(share());

    this._sessionTimeoutSubscription = this.clientConfig.authConfig$
      .pipe(
        filter((authConfig: AuthConfig): boolean => !!authConfig.sessionTimeout.enabled),
        switchMap((authConfig: AuthConfig): Observable<void> => {
          const { timeoutMs } = authConfig.sessionTimeout;
          if (timeoutMs === undefined) {
            return EMPTY;
          }
          return resetTimer$.pipe(
            // eslint-disable-next-line unicorn/no-null
            startWith(null),
            debounceTime(timeoutMs),
            switchMap((): Observable<void> => this._handleSessionTimeout()),
            takeUntil(
              this.authService.isLoggedIn$.pipe(filter((isLoggedIn: boolean): boolean => !isLoggedIn)),
            ),
            takeUntil(this._destroy$),
          );
        }),
        takeUntil(this._destroy$),
      )
      .subscribe({
        error: (err: unknown): void => {
          console.error('Error in session timeout handling:', err);
        },
      });
  }

  private _stopSessionTimeoutHandling(): void {
    if (this._sessionTimeoutSubscription) {
      this._sessionTimeoutSubscription.unsubscribe();
      this._sessionTimeoutSubscription = undefined;
    }
  }
}
