import { diffInDays } from '@app/utilities/diff-in-days';

import type { AccountsSummary, AccountsSummaryFirestore } from './user';
import type { AccountSummary, AccountSummaryFirestore } from './user-accounts';

type SummaryOrUndef = AccountsSummary | AccountSummary | undefined;

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Using a function instead of arrow function for clearer typing:
// https://github.com/microsoft/TypeScript/issues/33482#issuecomment-533058120
export function mogrifyAccountSummary(rawSummary?: AccountSummaryFirestore): AccountSummary | undefined;
export function mogrifyAccountSummary(rawSummary?: AccountsSummaryFirestore): AccountsSummary | undefined;
export function mogrifyAccountSummary(rawSummary?: AccountsSummaryFirestore | AccountSummaryFirestore): SummaryOrUndef {
  if (rawSummary) {
    const transactionDateNewest = rawSummary.transactionDateNewest?.toDate();
    const transactionDateOldest = rawSummary.transactionDateOldest?.toDate();
    const summaryDays: number = transactionDateNewest && transactionDateOldest
      ? diffInDays(transactionDateNewest, transactionDateOldest)
      : 0;

    return {
      ...rawSummary,
      summaryDays,
      transactionDateNewest,
      transactionDateOldest,
    };
  }

  return undefined;
}
