/* eslint-disable max-lines */
import type { Type as ComponentType } from '@angular/core';
import type { Routes } from '@angular/router';

import { advisorAdminGuard } from './advisor/guards/advisor-admin.guard';
import { advisorEnabledGuard } from './advisor/guards/advisor-enabled.guard';
import { rpcEnabledGuard } from './advisor/guards/rpc-enabled.guard';
import { ActionsComponent } from './auth/actions/actions.component';
import { AdvisorLoginComponent } from './auth/advisor-login/advisor-login.component';
import { AuthComponent } from './auth/auth.component';
import { ConfirmEmailComponent } from './auth/confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { activateAuthGuard } from './auth/guards/auth.guard';
import { activateNoAuthGuard } from './auth/guards/no-auth.guard';
import { JoinComponent } from './auth/join/join.component';
import { AuthLoginComponent } from './auth/login/auth-login.component';
import { RecoverEmailComponent } from './auth/recover-email/recover-email.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { budgetEnabledGuard } from './budget/guards/budget-enabled.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { featureEnabledGuardFactory } from './guards/feature-enabled.guard';
import { organizationGuardFactory } from './guards/organization.guard';
import { CalculatingComponent } from './onboarding/calculating/calculating.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const routes: Routes = [
  // Needs to be a destination for after login. Must be part of the first empty path route imported.
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },

  /**
   * Views outside of logged in area.
   */
  {
    path: '',

    canActivateChild: [ activateNoAuthGuard ],
    children: [
      // Views within auth page layout.
      {
        path: '',

        component: AuthComponent,
        data: { pageClass: 'auth-component' },
        title: '', // No common title shared by children

        children: [
          // Allows the members to get a URL that they can use to reset a forgotten password.
          {
            path: 'forgot-password',

            component: ForgotPasswordComponent,
            title: 'auth.reset-password.forgot-password-link',
          },
          // Allows members to signup using email & password.
          {
            path: 'join',

            component: JoinComponent,
            title: 'page-title.join',
          },
          // Email & Password sign in and SSO signup and login possibly.
          {
            path: 'login',

            component: AuthLoginComponent,
            title: 'auth.reset-password.login-link',
          },
          // Special hidden email & password for Planners to login to apps.
          {
            path: 'planner/admin/login',

            component: AdvisorLoginComponent,
            title: 'page-title.advisor-admin.login',
          },
          // Allows the user to set a password to their account if they forgot it.
          {
            path: 'reset-password',

            component: ResetPasswordComponent,
            title: 'auth.forgot-password.reset-password',
          },
        ],
      },

      // Auto-magic SSO redirect login for _select_ clients.
      {
        path: 'redirect-login',

        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const mod = await import('./auth/redirect-login/redirect-login.component');
          return mod.RedirectLoginComponent;
        },
        title: '', // No need for a title since this page is a redirect.
      },
    ],
  },

  /**
   * Views inside the login required area.
   */
  {
    path: '',

    canActivateChild: [ activateAuthGuard ],
    children: [
      {
        path: 'accounts',

        canMatch: [ featureEnabledGuardFactory('accountsEnabled') ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./accounts/accounts.routes');
          return mod.routes;
        },
        title: 'nav.link.my-accounts',
      },
      {
        path: 'benefits',

        canMatch: [ featureEnabledGuardFactory('benefitsEnabled') ],
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const mod = await import('./benefits/benefits.component');
          return mod.BenefitsComponent;
        },
        title: 'nav.link.benefits',
      },
      {
        path: 'budget',

        canMatch: [ budgetEnabledGuard ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./budget/budget.routes');
          return mod.routes;
        },
        title: '', // Titles set in loaded route
      },
      {
        path: 'calculating', // Onboarding

        component: CalculatingComponent, // Small component, so not lazy loaded
        title: 'page-title.onboarding.calculating',
      },
      {
        path: 'calculators',

        canMatch: [ featureEnabledGuardFactory('calculatorsEnabled') ],
        loadChildren: async (): Promise<Routes> => {
          const lazyLoaded = await import('./calculators/calculators.routes');
          return lazyLoaded.routes;
        },
        title: 'text.calculators',
      },
      {
        path: 'checkup',

        canMatch: [ featureEnabledGuardFactory('checkupEnabled') ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./checkup/checkup.routes');
          return mod.routes;
        },
        title: 'nav.link.my-checkup',
      },
      { path: 'connect', redirectTo: '/planner/connect' },
      {
        path: 'connect-rpc',

        canMatch: [ rpcEnabledGuard ],
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const lazyLoaded = await import('./integrations/rpc/connect-rpc/connect-rpc.component');
          return lazyLoaded.ConnectRpcComponent;
        },
        title: 'nav.link.advisor',
      },
      {
        path: 'dashboard',

        component: DashboardComponent,
        title: 'page-title.dashboard',
      },
      {
        path: 'goals',

        canMatch: [ featureEnabledGuardFactory('goalsEnabled') ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./goals/goals.routes');
          return mod.routes;
        },
        title: '', // Titles set in loaded route
      },
      {
        path: '53-incentive-program',

        canMatch: [ organizationGuardFactory('Fifth Third Bank') ],
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const lazyLoaded = await import('./incentive-program/fifth-third-landing-page/fifth-third-landing-page.component');
          return lazyLoaded.FifthThirdLandingPageComponent;
        },
        title: 'page-title.incentive-program',
      },
      {
        path: 'lessons',
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./lessons/lessons.routes');
          return mod.routes;
        },
        // Set the parent title for all loaded routes
        title: 'page-title.lessons',
      },
      { path: 'link', redirectTo: '/link-email' },
      {
        path: 'link-email',
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const mod = await import('./auth/link/link-credentials/link-credentials.component');
          return mod.LinkCredentialsComponent;
        },
        title: 'page-title.link-email',
      },
      {
        path: 'link-external',
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const mod = await import('./auth/link/link-provider/link-provider.component');
          return mod.LinkProviderComponent;
        },
        title: 'page-title.link-external',
      },
      {
        path: 'planner/admin',

        canMatch: [ advisorAdminGuard ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./advisor/admin/advisor-admin.routes');
          return mod.routes;
        },
        title: 'page-title.advisor-admin',
      },
      {
        path: 'planner',

        canMatch: [ advisorEnabledGuard ],
        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./advisor/advisor.routes');
          return mod.routes;
        },
        title: '', // Titles set in loaded route
      },
      {
        path: 'september-challenge',
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const lazyLoaded = await import('./challenges/september/september.component');
          return lazyLoaded.SeptemberChallengeComponent;
        },
        title: 'page-title.september-challenge',
      },
      {
        path: 'settings',

        loadChildren: async (): Promise<Routes> => {
          const mod = await import('./settings/settings.routes');
          return mod.routes;
        },
        title: 'nav.link.settings',
      },
      {
        path: 'start', // Onboarding

        data: { pageClass: 'onboarding' },
        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const lazyLoaded = await import('./onboarding/interview/interview.component');
          return lazyLoaded.InterviewComponent;
        },
        title: 'page-title.onboarding.start',
      },
      { path: 'take-action', redirectTo: '/benefits' },
      // Allows the user to verify that their email address is good. (Currently not required by the app.)
      {
        path: 'verify-email',

        loadComponent: async (): Promise<ComponentType<unknown>> => {
          const mod = await import('./auth/verify-email/verify-email.component');
          return mod.VerifyEmailComponent;
        },
        title: 'auth.confirm-email.verify-email-link',
      },
    ],
  },

  /**
   * For these paths we don't care if they are authenticated or not.
   */
  // Redirects the user from the Firebase email to our specific login recovery URL
  {
    path: 'actions',

    component: ActionsComponent,
    title: '', // Redirect page with no need for title
  },
  // Redirects the user from the old advisor routes to the new planner routes
  // Redirect doesn't have a slash so it includes all child routes.
  { path: 'advisor', redirectTo: 'planner' },
  // Verifies the user's email address.
  {
    path: 'confirm-email',

    component: ConfirmEmailComponent,
    title: 'page-title.confirm-email',
  },
  // Allows the user to undo a recovery of their email address
  {
    path: 'recover-email',

    component: RecoverEmailComponent,
    title: 'page-title.recover-email',
  },
  {
    path: 'style-guide',

    // canMatch: [ matchAuthGuard ], // Specifically want this route accessible to anyone
    data: { pageClass: 'style-guide' },
    loadComponent: async (): Promise<ComponentType<unknown>> => {
      const lazyLoaded = await import('./style-guide/style-guide.component');
      return lazyLoaded.StyleGuideComponent;
    },
    title: 'page-title.style-guide',
  },

  {
    path: '**',

    component: PageNotFoundComponent,
    title: 'page-not-found.title',
  },
];
