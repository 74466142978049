/**
 * US Federal and State taxes data models.
 */

export interface TaxesComputed {
  abovethelinedeductions: number;
  adjustedgrossincome: number;
  incomeaftertaxes: number;
  monthlynet: number;
  taxableincome: number;
  taxfederal: number;
  taxlocalavg: number;
  taxmedicare: number;
  taxsocialsecurity: number;
  taxstate: number;
  taxtotal: number;
}

/**
 * URLs for tax data json files. Should be cache busted by hashing the file contents using crc32.
 */
export const taxDataUrls: Readonly<{ [year: string]: string }> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  2019: '/assets/tax-data/fy-2019-1qdjz1u.json',
  2020: '/assets/tax-data/fy-2020-kpfgr2.json',
  2021: '/assets/tax-data/fy-2021-lxb5nd.json',
  2022: '/assets/tax-data/fy-2022-8g27ms.json',
  2023: '/assets/tax-data/fy-2023-bn2nwp.json',
  2024: '/assets/tax-data/fy-2024-1iwv1xm.json',
  2025: '/assets/tax-data/fy-2025-f4bqwu.json',
  /* eslint-enable @typescript-eslint/naming-convention */
} as const;

/** This array defines the list of Federal filing statuses in the order we want to display them. */
export const federalFilingStatuses = [
  'Single',
  'Married (Filing Jointly)',
  'Head of Household',
  'Married (Filing Separately)',
] as const;
export type FilingStatus = typeof federalFilingStatuses[number];

export interface IncomeTaxBracket {
  readonly bracket: number | null;
  readonly rate: number;
}

export type IncomeTaxBracketMap = {
  [status in FilingStatus]: IncomeTaxBracket[];
};

export type IncomeTaxDeductionMap = {
  [status in FilingStatus]: number;
};

/**
 * The states are sorted alphabetically by the full name, not by the code.
 * This might make keyboard navigation of the select drop down difficult.
 */
export const stateCodes = [
  /* eslint-disable @stylistic/array-element-newline */
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
  'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
  'VA', 'WA', 'WV', 'WI', 'WY', 'D.C.',
  /* eslint-enable @stylistic/array-element-newline */
] as const;
export type StateCodes = typeof stateCodes[number];

export interface StateTaxData {
  readonly brackets: Partial<IncomeTaxBracketMap>;
  readonly deductions: Partial<IncomeTaxDeductionMap>;
  readonly income: 'agi' | 'federal';
  readonly localTaxAvg?: number;
}

export type StateTaxDataMap = {
  [state in StateCodes]: StateTaxData;
};

interface LimitRange {
  readonly max: number;
  readonly min: number;
}

// We could expose this information to the lessons so that slides can use these values in calculations.
// https://thefinancebuff.com/401k-403b-ira-contribution-limits.html
export interface TaxLawSettings {
  _401k: {
    catchUpContribution: number;
    catchUpContribution60to63: number;
    maxAnnualAdditions: number;
    maxCompensation: number;
    maxContribution: number; // 403(b) shares the same limit as 401(k)
  };
  dcfsa: {
    family: number; // Single, Filing Jointly, Head of Household
    separately: number; // Filing Separately
  };
  estateTax: number;
  fsa: {
    maxContribution: number;
  };
  gifts: {
    individuals: number;
    individuals529plan: number;
    marriedCouples: number;
    marriedCouples529plan: number;
  };
  hsa: {
    catchUpContribution: number;
    deductible: {
      family: number;
      single: number;
    };
    maxContribution: {
      family: number;
      single: number;
    };
  };
  ira: {
    catchUpContribution: number;
    deductibleIncomeLimit: { // Modified AGI
      marriedActive: LimitRange;
      single: LimitRange;
      spouseActive: LimitRange;
    };
    maxContribution: number;
    rothIncomeLimit: { // Modified AGI
      married: LimitRange;
      single: LimitRange;
    };
  };
  medicare: {
    additionalRateOverWageBase: number;
    additionalSelfEmployedRate: number;
    baseRate: number;
    wageBaseLimits: IncomeTaxDeductionMap;
  };
  qsehra: { // Yearly amounts
    family: number;
    single: number;
  };
  simpleira: {
    catchUpContribution: number;
    maxContribution: number;
  };
  socialSecurity: {
    additionalSelfEmployedRate: number;
    maxIncome: number;
    rate: number;
  };
  studentLoans: {
    interestDeduction: {
      incomeLimit: { // Modified AGI
        jointly: LimitRange;
        single: LimitRange;
      };
      maxDeduction: number;
    };
    lifetimeLearnersCredit: {
      incomeLimit: { // Modified AGI
        jointly: LimitRange;
        single: LimitRange;
      };
      maxQualifiedExpenses: number;
      percentage: number;
    };
  };
  travelDeduction: number;
}

interface TaxDates<T extends Date | string> {
  readonly autoExtensionIrs: T;
  readonly currentTaxYear: number;
  readonly deadlineAlt?: T; // For MA state deadline change
  readonly deadlineIrs: T;
  readonly extensionIrs: T;
  readonly startIrs: T;
}

interface TaxDataBase {
  readonly federalAlternativeMinimumTax: {
    readonly exemptions: IncomeTaxDeductionMap;
    readonly phaseoutThresholds: IncomeTaxDeductionMap;
  };
  // Warning, these are not brackets we can use with the Tax Calculator because they aren't structured
  // with the top of the ranges:
  // Nick: In 2024 (single tax filer), if their income is $0-$47,025 the capital gains rate is 0%. For
  // income between $47,025-$518,900 the capital gains tax rate is 15%. And, for income greater than
  // $518,900 the capital gains tax rate is 20%.
  readonly federalCapitalGains: IncomeTaxBracketMap;
  readonly federalIncomeTaxBrackets: IncomeTaxBracketMap;
  readonly federalIncomeTaxDeductions: IncomeTaxDeductionMap;
  readonly stateIncomeTaxData: StateTaxDataMap;
  readonly taxLawSettings: TaxLawSettings;
}

export interface TaxDataJson extends TaxDataBase {
  readonly dates: TaxDates<string>;
}

export interface TaxData extends TaxDataBase {
  readonly dates: TaxDates<Date>;
}
