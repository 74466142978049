import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import type { Observable } from 'rxjs';

import { TranslationModule } from '@app/translation/translation.module';
import { computePasswordStrength } from '@app/utilities/password-complexity';

export interface ViewModel {
  barClass: string;
  label: string;
  strength: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, TranslationModule ],
  selector: 'lux-password-strength',
  standalone: true,
  styleUrls: [ './password-strength.component.scss' ],
  templateUrl: './password-strength.component.html',
})
export class PasswordStrengthComponent {
  public readonly vm$: Observable<ViewModel | false>;

  private readonly _passwordSub$: ReplaySubject<string>;

  constructor() {
    this._passwordSub$ = new ReplaySubject<string>(1);

    this.vm$ = this._passwordSub$.pipe(
      map((pwd: string): ViewModel | false => {
        // If the password is blank then hide the indicator. Note that this makes the page "jumpy"!
        if (pwd === '') {
          return false;
        }

        const rawStrength = computePasswordStrength(pwd);

        /*
         * Strength:
         *  - Weak: str < 45
         *  - Okay: 45 <= str < 65
         *  - Strong: 65 <= str
         */
        let barClass = '';
        let label = '';
        if (rawStrength < 45) {
          barClass = 'bg-danger';
          label = 'Weak';
        } else if (rawStrength < 65) {
          barClass = 'bg-info';
          label = 'Okay';
        } else {
          barClass = 'bg-success';
          label = 'Strong';
        }

        return {
          barClass,
          label,
          strength: Math.min(Math.max(rawStrength, 11), 100),
        };
      }),
    );
  }

  @Input({ required: true }) public set password(pwd: string) {
    this._passwordSub$.next(pwd);
  }
}
