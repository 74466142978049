import type { OnDestroy, PipeTransform } from '@angular/core';
import { ChangeDetectorRef, Pipe } from '@angular/core'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ITranslateParams } from '@transifex/native';
import type { Observable, Subscription } from 'rxjs';

import { TranslationService } from './translation.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

/**
 * Based upon: https://github.com/angular/angular/blob/203a3d59a0194fc839e00912ef46bc933d66d817/packages/common/src/pipes/async_pipe.ts#L89
 */
@Pipe({
  name: 'translate',
  pure: false, // eslint-disable-line @angular-eslint/no-pipe-impure -- required to update the translation
  standalone: true,
})
export class TranslatePipe implements OnDestroy, PipeTransform {
  private _lastParams?: ITranslateParams;
  private _lastStr?: string;
  private _latestValue: string = '';
  private readonly _obs$: Observable<unknown>;
  private _ref: ChangeDetectorRef | undefined;
  private _subscription?: Subscription;

  constructor(
    ref: ChangeDetectorRef,
    private readonly translationService: TranslationService,
  ) {
    // Assign `ref` into `this._ref` manually instead of declaring `_ref` in the constructor
    // parameter list, as the type of `this._ref` includes `undefined` unlike the type of `ref`.
    this._ref = ref;

    // When either of these Observables emit, then update the current translated string value.
    this._obs$ = this.translationService.updateTranslations$;
  }

  public ngOnDestroy(): void {
    this._dispose();

    // Clear the `ChangeDetectorRef` and its association with the view data, to mitigate
    // potential memory leaks in Observables that could otherwise cause the view data to
    // be retained.
    // https://github.com/angular/angular/issues/17624
    this._ref = undefined;
  }

  public transform(str: string, params: ITranslateParams = {}): string {
    this._updateTranslation(str, params);

    if (!this._subscription) {
      // This Observable doesn't depend on the transform parameters, so it can just be used
      // for the entire lifecycle of the Pipe.
      this._subscription = this._obs$.subscribe({ // eslint-disable-line rxjs-angular/prefer-takeuntil -- How AsyncPipe does it
        error: (err: unknown): void => { console.error('TranslatePipe#subscription', err); },
        next: (): void => {
          // These should both be set by the above call to _updateTranslation
          if (this._lastParams && this._lastStr) {
            this._updateTranslation(this._lastStr, this._lastParams);
          }
        },
      });
    }

    return this._latestValue;
  }

  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  private _updateTranslation(str: string, params: ITranslateParams): void {
    this._latestValue = this.translationService.translate(str, params);
    this._lastParams = params;
    this._lastStr = str;
    // Note: `this._ref` is only cleared in `ngOnDestroy` so is known to be available when a
    // value is being updated.
    this._ref?.markForCheck();
  }
}
