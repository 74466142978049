import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '@app/window.provider';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  public readonly sessionStorage: Storage;

  constructor(@Inject(WINDOW) private readonly window: Window) {
    this.sessionStorage = this.window.sessionStorage;
  }

  public get(key: string): string | null {
    return this.sessionStorage.getItem(key);
  }

  public remove(key: string): void {
    this.sessionStorage.removeItem(key);
  }

  public set(key: string, value: string): void {
    this.sessionStorage.setItem(key, value);
  }
}
