// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `lux-suggestion-preview + lux-suggestion-preview {
  display: block;
  margin-top: 1.5rem;
}

.more {
  margin-top: 1.5rem;
  text-align: start;
}

@media (max-width: 575.98px) {
  .more {
    text-align: center;
  }
  .more .restart {
    display: block;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
