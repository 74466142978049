import { CommonModule, Location } from '@angular/common'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';

import type { Country, GlobalConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';

const A_BEFORE_B = -1;
const B_BEFORE_A = 1;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgbDropdownModule,
    SpinnerComponent,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-country-dropdown',
  standalone: true,
  styleUrls: [ './country-dropdown.component.scss' ],
  templateUrl: './country-dropdown.component.html',
})
export class CountryDropdownComponent {
  public readonly vm$: Observable<GlobalConfig>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly location: Location,
  ) {
    this.vm$ = this.clientConfig.globalConfig$.pipe(
      map((globalConfig: GlobalConfig): GlobalConfig => {
        // `enabled` is a computed property. If it is true then `countries` list must have items
        // and `default` must be defined as a Country.
        if (globalConfig.enabled) {
          const countries = this._updateCountryLinks(globalConfig);
          this._sortCountries(countries, globalConfig.default?.label);

          return {
            ...globalConfig,
            countries,
          };
        }

        return globalConfig;
      }),
    );
  }

  /**
   * Sort the countries list with the default country first, then the rest in descending order by label.
   */
  private _sortCountries(countries: Country[], defaultLabel: string | undefined): void {
    countries.sort((a: Country, b: Country): number => {
      // Sort the default country to the top of the list.
      if (a.label === defaultLabel) {
        return A_BEFORE_B;
      }
      if (b.label === defaultLabel) {
        return B_BEFORE_A;
      }
      // Sort the other countries alphabetically
      return a.label.localeCompare(b.label);
    });
  }

  /**
   * Append a path to the Country link property URL.
   * If the current page is the join page, then maintain the `src` attribution query parameter
   * All other pages should point to login and then dashboard.
   * Note: the `next` query parameter is not maintained by this function. Possibly an oversight.
   */
  private _updateCountryLinks(globalConfig: GlobalConfig): Country[] {
    // This returns both /URL/path, ?query=parameters, and #fragment all of which might be
    // used for user tracking.
    const path = this.location.path(true);
    // For the join page we care about the `?src=` attribution query parameter. For any other
    // page Alex believes the best destination is dashboard _after login_ because otherwise it may
    // be difficult to tell what happened if you end up back in the Settings Change Location page.
    const resolvedPath = path.startsWith('/join') ? path : '/login';
    return globalConfig.countries.map((country: Country): Country => ({
      ...country,
      link: new URL(resolvedPath, country.link).href,
    }));
  }
}
