import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule ],
  selector: 'lux-logo',
  standalone: true,
  styleUrls: [ './logo.component.scss' ],
  templateUrl: './logo.component.html',
})
export class LogoComponent {
  @Input({ required: true }) public height!: number;
  @Input({ required: true }) public width!: number;
}
