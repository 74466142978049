import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { Observable } from 'rxjs';
import {
  ignoreElements,
  merge,
  of,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { ProfileProgressService } from '../profile-progress.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ProfileProgress } from '../profile-progress.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-marquee',
  standalone: true,
  styleUrls: [ './marquee.component.scss' ],
  templateUrl: './marquee.component.html',
})
export class MarqueeComponent {
  public readonly progress$: Observable<ProfileProgress>;

  private readonly _skipStepTrigger$: Subject<string> = new Subject<string>();

  constructor(
    private readonly service: ProfileProgressService,
    private readonly userDataService: UserDataService,
  ) {
    const doSkip$ = this._skipStepTrigger$.pipe(
      withLatestFrom(this.service.progress$),
      switchMap(([ step, progress ]: [ string, ProfileProgress ]): Observable<boolean> => {
        const skippedSteps: string[] = progress.skippedSteps ?? [];
        if (!skippedSteps.includes(step)) {
          const updatedSteps = [ ...skippedSteps, step ];
          return this.userDataService.updateUserData({ skippedProgressSteps: updatedSteps });
        }
        return of(false);
      }),
      ignoreElements(),
    );

    this.progress$ = merge(
      this.service.progress$,
      doSkip$,
    );
  }

  public skipProgressStep(step: string): void {
    this._skipStepTrigger$.next(step);
  }
}
