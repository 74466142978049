import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

import type { IncentiveConfig } from '@app/client-config/client-config';
import { TrackByPropertyDirective } from '@app/track-by/track-by-property.directive';
import { TranslationModule } from '@app/translation/translation.module';
import { clamp } from '@app/utilities/clamp';

import { IncentiveComingSoonWidgetComponent } from '../incentive-coming-soon-widget/incentive-coming-soon-widget.component';
import { IncentiveProgramService } from '../incentive-program.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { IncentiveProgramProgress } from '../incentive-program.service';

type ProgressViewModel = Omit<ViewModel, 'endDate' | 'programName'>;

export interface ViewModel {
  earningSummary: EarningSummary;
  endDate: Date;
  isBudgetComplete: boolean;
  isCheckupComplete: boolean;
  programName: string;
  showProgram: boolean;
  tiers: Tier[];
}

interface Tier {
  lessons: number;
  maxLessons: number;
  name: string;
  points: number;
}

type EarningSummary = Omit<Tier, 'lessons' | 'maxLessons'>;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IncentiveComingSoonWidgetComponent,
    NgOptimizedImage,
    RouterModule,
    TrackByPropertyDirective,
    TranslationModule,
  ],
  selector: 'lux-mr-cooper-incentive-widget',
  standalone: true,
  styleUrls: [ './mr-cooper-incentive-widget.component.scss' ],
  templateUrl: './mr-cooper-incentive-widget.component.html',
})
export class MrCooperIncentiveWidgetComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(private readonly incentiveProgramService: IncentiveProgramService) {
    const progress$ = this.incentiveProgramService.incentiveSummaryProgramId$.pipe(
      switchMap((summaryProgramId: string | undefined): Observable<ProgressViewModel> => {
        return (
          summaryProgramId == undefined
            ? this.incentiveProgramService.incentiveProgramProgress$ // get current program data
            : this.incentiveProgramService.getIncentiveProgramProgress(summaryProgramId) // get previous program data
        ).pipe(
          map((progress: IncentiveProgramProgress): ProgressViewModel => {
            const {
              hasVisitedCheckupResults: isCheckupComplete = false,
              isBudgetComplete = false,
              lessonsCompleted = 0,
            } = progress;

            return {
              earningSummary: this._getEarningsSummary(isBudgetComplete, isCheckupComplete, lessonsCompleted),
              isBudgetComplete,
              isCheckupComplete,
              showProgram: summaryProgramId == undefined,
              tiers: this._getTiers(lessonsCompleted),
            };
          }),
        );
      }),
    );

    this.vm$ = combineLatest([
      progress$,
      this.incentiveProgramService.incentiveConfig$,
    ]).pipe(
      map(([ progress, config ]: [ ProgressViewModel, IncentiveConfig ]): ViewModel => {
        return {
          ...progress,
          endDate: config.endAt,
          programName: 'Wellbeing360',
        };
      }),
    );
  }

  private _getEarningsSummary(isBudgetComplete: boolean, isCheckupComplete: boolean, lessonsComplete: number): EarningSummary {
    if (!isBudgetComplete || !isCheckupComplete) {
      return { name: '', points: 0 };
    }

    if (lessonsComplete >= 20) {
      return { name: 'Gold', points: 400 };
    }

    if (lessonsComplete >= 10) {
      return { name: 'Silver', points: 200 };
    }

    if (lessonsComplete >= 5) {
      return { name: 'Bronze', points: 100 };
    }

    return { name: '', points: 0 };
  }

  private _getTiers(lessonsComplete: number): Tier[] {
    return [
      {
        lessons: clamp(lessonsComplete, 5),
        maxLessons: 5,
        name: 'Bronze',
        points: 100,
      },
      {
        lessons: clamp(lessonsComplete, 10),
        maxLessons: 10,
        name: 'Silver',
        points: 200,
      },
      {
        lessons: clamp(lessonsComplete, 20),
        maxLessons: 20,
        name: 'Gold',
        points: 400,
      },
    ];
  }
}
