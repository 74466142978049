import { Inject, Injectable } from '@angular/core';
import { delay, map } from 'rxjs';
import type { Observable } from 'rxjs';

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';
import { ScriptsService } from '@app/utilities/scripts.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { WINDOW } from '@app/window.provider';

export interface HubSpotConversationWidget {
  clear: ({ resetWidget }?: { resetWidget: boolean }) => void;
  close: () => void;
  load: ({ widgetOpen }?: { widgetOpen: boolean }) => void;
  open: () => void;
  refresh: ({ openToNewThread }?: { openToNewThread: boolean }) => void;
  remove: () => void;
  status: () => { loaded: boolean; pending?: boolean };
}
interface IHubSpotConversations {
  widget: HubSpotConversationWidget;
}
export interface HubSpotWinExt {
  _hsq?: unknown[];
  HubSpotConversations?: IHubSpotConversations; // eslint-disable-line @typescript-eslint/naming-convention
}
type HubSpotWindow = HubSpotWinExt & Window;

export interface HubSpotIdentity {
  company: string;
  country: string;
  email?: string;
  firstname?: string;
  interestinfinadvisor: string;
  lastname?: string;
  preferredLocale: string;
  userid: string;

  // Note: The member's app domain (ex: acme.learnlux.com).
  // Used to associate the user with a company in the CRM.
  website: string;
}

@Injectable({ providedIn: 'root' })
export class HubSpotService {
  /**
   * This Observable will complete after one emission. Subscribing to it multiple times shouldn't
   * work.
   */
  public readonly hubspotLoaded$: Observable<boolean>;

  private readonly _learnluxScriptSrc: string = 'https://js.hs-scripts.com/8982957.js';
  private readonly _queue: unknown[];

  constructor(
    @Inject(APP_CONFIG) public readonly appConfig: AppConfig,
    private readonly scripts: ScriptsService,
    @Inject(WINDOW) private readonly window: HubSpotWindow,
  ) {
    // this alias doesn't work with load and remove widget so always directly access the window.
    // this.hsConversations = this.window.HubSpotConversations;
    this._queue = this.window._hsq ??= []; // eslint-disable-line no-multi-assign

    const scriptSrc = this.appConfig.hubspotScriptSrc ?? this._learnluxScriptSrc;

    this.hubspotLoaded$ = this.scripts.loadJsScript(scriptSrc).pipe(
      delay(250), // A short delay to wait for the inner hubspot scripts to be added and loaded.
      map((): boolean => {
        const gotHubSpot = Boolean(this.window.HubSpotConversations);

        if (!gotHubSpot) {
          // Adblockers might block this code. Nothing we can really do about that.
          console.warn('HubSpot JS not loaded.');
        }

        return gotHubSpot;
      }),
    );
  }

  public identify(ident: HubSpotIdentity): void {
    // Assemble the URL for accessing the member's user data export.
    // Note: This URL will only work if there's an export for the corresponding UserID
    // Note: snake_case is used to ensure consistency with other keys within Hubspot.
    const dataExportUrl = `https://${ident.website}/planner/admin/user-data?userid=${ident.userid}`;

    // Identify a visitor to your site in HubSpot. The unique identifier is an email address.
    // If there is an existing contact record for that email address, that record will be updated.
    // Otherwise, a new contact record will be created. In both cases, the analytics data
    // collected for the visitor will be associated with the contact record.
    // Note: The email address of an existing contact cannot be updated with this method.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this._queue.push([ 'identify', { ...ident, data_export_url: dataExportUrl } ]);

    // This function call stores the data in the tracker, but the data is not actually passed to
    // HubSpot with this call. The data will only be passed when tracking a pageview or an event
    // (with either the trackPageView or trackEvent functions).
    // Note: Events will only be processed for accounts with Marketing Hub Enterprise.
    // Note: This may result in pages being double-tracked, due to repetition from app.component.ts
    this.trackPageView();
  }

  public loadWidget(): void {
    if (this.window.HubSpotConversations) {
      this.window.HubSpotConversations.widget.load();
    }
  }

  /** Open Chat Window */
  public openChat(): void {
    // This doesn't work on Mobile so for now we will only have a single chat flow.
    // if (this.gotHubSpot) {
    //   // Open the "Ask a Question" chat flow:
    //   // https://developers.hubspot.com/docs/api/conversations/open-to-chatflow
    //   this.window.history.pushState({}, 'hs_bot', '?chat=question');
    //   this.refreshWidget();
    // } else {
    //   console.error('Error opening Hubspot chat on demand.');
    // }

    if (this.window.HubSpotConversations) {
      // Since we sometimes don't load the widget now we need to check that it is before removing it.
      const status = this.window.HubSpotConversations.widget.status();
      if (status.loaded) {
        this.window.HubSpotConversations.widget.open();
      }
    }
  }

  public removeWidget(): void {
    if (this.window.HubSpotConversations) {
      // Since we sometimes don't load the widget now we need to check that it is before removing it.
      const status = this.window.HubSpotConversations.widget.status();

      // Due to a race condition with TranslationService#updateTranslations$ emitting the user's
      // language preference this remove might be called before the widget has fully loaded.
      // You cannot remove a widget until it is loaded. So if there is a pending loading of the widget
      // This will poll for it to finish loading and then remove it.
      if (status.pending) {
        // this.window.hsConversationsOnReady happens too early for this purpose.
        setTimeout(
          (): void => {
            this.removeWidget();
          },
          250,
        );
      }

      if (status.loaded) {
        this.window.HubSpotConversations.widget.remove();
      }
    }
  }

  /**
   * Manually track the page view in HubSpot
   * https://developers.hubspot.com/docs/api/events/tracking-code
   */
  public trackPageView(): void {
    this._queue.push([ 'trackPageView' ]);
  }
}
