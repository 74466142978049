/* eslint-disable max-lines */
import { Inject, Injectable } from '@angular/core';
import {
  catchError,
  map,
  of,
  shareReplay,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';

import {
  DEFAULT_ADVISOR_CONFIG,
  DEFAULT_FEATURES_CONFIG,
  DEFAULT_GLOBAL_CONFIG,
  DEFAULT_ORG_CONFIG,
  DEFAULT_SELFSIGNUP_CONFIG,
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_US_CALCULATORS,
} from './client-config';
import type {
  AdvisorConfig,
  AdvisorConfigFirestore,
  AuthConfig,
  BannerConfig,
  BannerConfigOrUndef,
  FeaturesConfig,
  FeaturesConfigFirestore,
  GlobalConfig,
  IncentiveConfig,
  IncentiveConfigFirestore,
  OrganizationConfig,
  RpcConfig,
  RpcConfigOrUndef,
  SelfSignupConfig,
  TranslationConfig,
} from './client-config';

const mogrifyIncentiveConfig = (key: string, config: IncentiveConfigFirestore | undefined): Record<string, IncentiveConfig> | undefined => {
  if (config) {
    return {
      [key]: {
        ...config,
        endAt: config.endAt.toDate(),
        startAt: config.startAt.toDate(),
      },
    };
  }
  return undefined;
};

@Injectable({ providedIn: 'root' })
export class ClientConfigService {
  public readonly advisorConfig$: Observable<AdvisorConfig>;
  public readonly authConfig$: Observable<AuthConfig>;
  public readonly bannerConfig$: Observable<BannerConfigOrUndef>;
  public readonly featuresConfig$: Observable<FeaturesConfig>;
  public readonly globalConfig$: Observable<GlobalConfig>;
  public readonly organizationConfig$: Observable<OrganizationConfig>;
  public readonly rpcConfig$: Observable<RpcConfigOrUndef>;
  public readonly selfSignupConfig$: Observable<SelfSignupConfig>;
  public readonly translationConfig$: Observable<TranslationConfig>;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly afStore: AngularFirestoreService,
  ) {
    this.advisorConfig$ = this._getAdvisorConfig();
    this.authConfig$ = this._getAuthConfig();
    this.bannerConfig$ = this._getBannerConfig();
    this.featuresConfig$ = this._getFeaturesConfig();
    this.globalConfig$ = this._getGlobalConfig();
    this.organizationConfig$ = this._getOrganizationConfig();
    this.rpcConfig$ = this._getRpcConfig();
    this.selfSignupConfig$ = this._getSelfSignupConfig();
    this.translationConfig$ = this._getTranslationConfig();
  }

  /**
   * Fetches the advisor configuration document from firestore.
   * Handles errors retrieving the document by using the DEFAULT_ADVISOR_CONFIG.
   */
  private _getAdvisorConfig(): Observable<AdvisorConfig> {
    const configRef = this.afStore.doc<Partial<AdvisorConfigFirestore>>('config/advisor');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        map((rawDoc: Partial<AdvisorConfigFirestore> | undefined): AdvisorConfig => {
          // Merge the default config into the one from the DB so anything missing in the DB is set.
          const ret = {
            ...DEFAULT_ADVISOR_CONFIG,
            ...rawDoc,
          };

          // These values are dynamically set from the other values and are not from Firestore
          ret.enabled = ret.callEnabled || ret.chatEnabled || Boolean(ret.typeform);
          ret.referenceEnabled = ret.callEnabled || ret.chatEnabled;

          return ret;
        }),
        // If some error happens then catch and return the DEFAULT_ADVISOR_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<AdvisorConfig> => {
          console.error('ClientConfigService#getAdvisorConfig', err);
          return of(DEFAULT_ADVISOR_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  private _getAuthConfig(): Observable<AuthConfig> {
    const configRef = this.afStore.doc<AuthConfig>('config/auth');
    return this.afStore.docData(configRef)
      .pipe(
        map((config: AuthConfig | undefined): AuthConfig => {
          // If Firestore returns undefined or any missing properties, use a default configuration.
          return {
            sessionTimeout: {
              enabled: config?.sessionTimeout.enabled ?? false,
              timeoutMs: config?.sessionTimeout.timeoutMs ?? undefined,
            },
          };
        }),
        catchError((err: unknown): Observable<AuthConfig> => {
          console.error('ClientConfigService#getAuthConfig', err);
          // Return a default configuration on error
          return of({
            sessionTimeout: {
              enabled: false,
              timeoutMs: undefined,
            },
          });
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /** Fetches the HTML content for the BannerComponent from the database, if any. */
  private _getBannerConfig(): Observable<BannerConfigOrUndef> {
    const configRef = this.afStore.doc<BannerConfig>('config/banner');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        // If some error happens then catch and return undefined
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<BannerConfigOrUndef> => {
          console.error('ClientConfigService#getBannerConfig', err);
          return of(undefined);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /**
   * Fetches the document of features flags to toggle in progress/optional features in the app.
   * Handles errors retrieving the document by using the DEFAULT_FEATURES_CONFIG.
   */
  private _getFeaturesConfig(): Observable<FeaturesConfig> {
    const isUSApp = this.appConfig.locale === 'en-US';
    // US Apps default to calculators enabled. All others must be manually enabled.
    // US Apps can disable calculators by setting the flag to false.
    const defaultCalculators = isUSApp ? DEFAULT_US_CALCULATORS : undefined;
    const defaultConfig = { ...DEFAULT_FEATURES_CONFIG, ...defaultCalculators };

    const configRef = this.afStore.doc<Partial<FeaturesConfigFirestore>>('config/features');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        // Merge the default config into the one from the DB so anything missing in the DB is set.
        map((doc: Partial<FeaturesConfigFirestore> | undefined): FeaturesConfig => {
          let customConfig: Partial<FeaturesConfig> | undefined;
          if (doc != undefined) {
            const { incentiveConfig, incentiveSummary, ...partialConfig } = doc;
            customConfig = {
              ...defaultConfig,
              ...partialConfig,
              ...mogrifyIncentiveConfig('incentiveConfig', incentiveConfig),
              ...mogrifyIncentiveConfig('incentiveSummary', incentiveSummary),
            };
          }

          const ret = { ...defaultConfig, ...customConfig };
          ret.calculatorsEnabled = Object.keys(DEFAULT_US_CALCULATORS).some(
            (key: string): boolean => Boolean(ret[key as keyof FeaturesConfig]),
          );

          return ret;
        }),
        // If some error happens then catch and return the DEFAULT_FEATURES_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<FeaturesConfig> => {
          console.error('ClientConfigService#getFeaturesConfig', err);
          return of(DEFAULT_FEATURES_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /**
   * Fetches the document of supported countries to display on sign up and login.
   */
  private _getGlobalConfig(): Observable<GlobalConfig> {
    const configRef = this.afStore.doc<GlobalConfig>('config/global');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        map((config: GlobalConfig | undefined): GlobalConfig => {
          return {
            ...DEFAULT_GLOBAL_CONFIG,
            ...config,
            enabled: Boolean(config?.default?.label) && (config?.countries.length ?? 0) > 1,
          };
        }),
        // If some error happens then catch and return DEFAULT_GLOBAL_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<GlobalConfig> => {
          console.error('ClientConfigService#getGlobalConfig', err);
          return of(DEFAULT_GLOBAL_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /** Fetches the config for the client called RPC from the database, if any. */
  private _getOrganizationConfig(): Observable<OrganizationConfig> {
    const configRef = this.afStore.doc<OrganizationConfig>('config/organization');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        map((config: OrganizationConfig | undefined): OrganizationConfig => {
          return {
            ...DEFAULT_ORG_CONFIG,
            ...config,
          };
        }),
        // If some error happens then catch and return DEFAULT_ORG_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<OrganizationConfig> => {
          console.error('ClientConfigService#getOrganizationConfig', err);
          return of(DEFAULT_ORG_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /** Fetches the config for the client called RPC from the database, if any. */
  private _getRpcConfig(): Observable<RpcConfigOrUndef> {
    const configRef = this.afStore.doc<RpcConfig>('config/rpc');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        // If some error happens then catch and return undefined
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<RpcConfigOrUndef> => {
          console.error('ClientConfigService#getRPCConfig', err);
          return of(undefined);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /**
   * Fetches the configuration for user account self signup (aka Join the App) from firestore.
   * Handles errors retrieving the document by using the DEFAULT_SELFSIGNUP_CONFIG.
   */
  private _getSelfSignupConfig(): Observable<SelfSignupConfig> {
    const configRef = this.afStore.doc<Partial<SelfSignupConfig>>('config/signup');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        // Merge the default config into the one from the DB so anything missing in the DB is set.
        // Beware that the nested Object in the password key will not be deep merged!
        map((doc: Partial<SelfSignupConfig> | undefined): SelfSignupConfig => ({ ...DEFAULT_SELFSIGNUP_CONFIG, ...doc })),
        // If some error happens then catch and return the DEFAULT_SELFSIGNUP_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<SelfSignupConfig> => {
          console.error('ClientConfigService#getSelfSignupConfig', err);
          return of(DEFAULT_SELFSIGNUP_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }

  /**
   * Fetches the configuration for translation support from firestore.
   * Handles errors retrieving the document by using the DEFAULT_TRANSLATION_CONFIG.
   */
  private _getTranslationConfig(): Observable<TranslationConfig> {
    const configRef = this.afStore.doc<Partial<TranslationConfig>>('config/translation');
    return this.afStore.docData(configRef) // Don't need the ID.
      .pipe(
        // Merge the default config into the one from the DB so anything missing in the DB is set.
        // Beware that the locales array will not be merged!
        map((doc: Partial<TranslationConfig> | undefined): TranslationConfig => ({ ...DEFAULT_TRANSLATION_CONFIG, ...doc })),
        // If some error happens then catch and return the DEFAULT_TRANSLATION_CONFIG
        // Need to catch the error at this level so we don't break the outer pipe.
        // Configs are now accessable to unauthenticated visitors.
        catchError((err: unknown): Observable<TranslationConfig> => {
          console.error('ClientConfigService#getTranslationConfig', err);
          return of(DEFAULT_TRANSLATION_CONFIG);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
  }
}
