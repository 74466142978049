import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { IncentiveProgramProgress, IncentiveProgramService } from './incentive-program.service';

export interface Goal2Progress {
  budget: number;
  debtPayoffCalculator: number;
  emergencySavingsCalculator: number;
  employeeAssistanceProgram: number;
  goalComplete: boolean;
  lessons: number;
  retirementCalculator: number;
  savingsGoalCalculator: number;
  totalPoints: number;
}

interface LessonPoints {
  employeeAssistanceProgramPoints: number;
  totalLessonPoints: number;
}

const REQUIRED_LESSON_SLUG = '53-supportlinc-eap';

@Injectable({ providedIn: 'root' })
export class FifthThirdIncentiveProgressService {
  public readonly goal1Complete$: Observable<boolean>;
  public readonly goal2Progress$: Observable<Goal2Progress>;

  constructor(private readonly incentiveProgramService: IncentiveProgramService) {
    this.goal1Complete$ = this.incentiveProgramService.incentiveProgramProgress$.pipe(
      map((progress: IncentiveProgramProgress): boolean => progress.hasAnsweredCheckup),
    );

    this.goal2Progress$ = this.incentiveProgramService.incentiveProgramProgress$.pipe(
      map((progress: IncentiveProgramProgress): Goal2Progress => {
        return this._calculateGoal2Progress(progress);
      }),
    );
  }

  private _calculateGoal2Progress(progress: IncentiveProgramProgress): Goal2Progress {
    const budgetPoints = progress.isBudgetComplete ? 100 : 0;
    const debtPayoffCalculatorPoints = progress.isDebtPayoffCalculatorComplete ? 50 : 0;
    const emergencySavingsCalculatorPoints = progress.isEmergencySavingsCalculatorComplete ? 50 : 0;
    const retirementCalculatorPoints = progress.isRetirementCalculatorComplete ? 50 : 0;
    const savingsGoalCalculatorPoints = progress.isSavingsGoalCalculatorComplete ? 50 : 0;
    const { employeeAssistanceProgramPoints, totalLessonPoints } = this._calculateLessonPoints(progress.completedLessonSlugs);
    const goalProgress: Omit<Goal2Progress, 'goalComplete'> = {
      budget: budgetPoints,
      debtPayoffCalculator: debtPayoffCalculatorPoints,
      emergencySavingsCalculator: emergencySavingsCalculatorPoints,
      employeeAssistanceProgram: employeeAssistanceProgramPoints,
      lessons: totalLessonPoints,
      retirementCalculator: retirementCalculatorPoints,
      savingsGoalCalculator: savingsGoalCalculatorPoints,
      totalPoints:
        totalLessonPoints
        + budgetPoints
        + emergencySavingsCalculatorPoints
        + employeeAssistanceProgramPoints
        + debtPayoffCalculatorPoints
        + retirementCalculatorPoints
        + savingsGoalCalculatorPoints,
    };
    const goal2Complete = this._isGoal2Complete(goalProgress);
    return { ...goalProgress, goalComplete: goal2Complete };
  }

  private _calculateLessonPoints(completedLessonSlugs: string[]): LessonPoints {
    let employeeAssistanceProgramPoints = 0;
    let totalLessonPoints = 0;
    for (const lessonSlug of completedLessonSlugs) {
      if (lessonSlug === REQUIRED_LESSON_SLUG) {
        employeeAssistanceProgramPoints = 100;
      } else {
        totalLessonPoints += 25;
      }
    }
    return { employeeAssistanceProgramPoints, totalLessonPoints };
  }

  private _isGoal2Complete(
    { employeeAssistanceProgram = 0, totalPoints = 0 }: Partial<Goal2Progress>,
  ): boolean {
    return employeeAssistanceProgram > 0 && totalPoints >= 400;
  }
}
