import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>(
  'window',
  {
    factory: (): Window => {
      const doc = inject(DOCUMENT);
      if (!doc.defaultView) {
        throw new Error('Window is not available');
      }
      return doc.defaultView;
    },
    providedIn: 'root',
  },
);
