import { Injectable } from '@angular/core';
import type { UserInfo } from '@angular/fire/auth';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { ProviderConfig, SelfSignupConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';

import { AuthService } from './auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

@Injectable({ providedIn: 'root' })
export class ProviderStatusService {
  public readonly canLinkEmail$: Observable<boolean>;
  public currentIndex: number = 0;
  public readonly currentIndex$: Observable<number>;
  public readonly linkedProviderIds$: Observable<Set<string>>; // TODO unit test
  public readonly linkedProviders$: Observable<readonly ProviderConfig[]>;
  public readonly needToLinkEmail$: Observable<boolean>;
  public readonly oldEmail$: Observable<string>; // TODO unit test
  public readonly providerCountLinked$: Observable<number>; // TODO unit test
  public readonly providerCountTotal$: Observable<number>;
  public readonly providers$: Observable<readonly ProviderConfig[]>;
  public readonly unlinkedProviders$: Observable<readonly ProviderConfig[]>;

  private readonly _currentIndexSub$: BehaviorSubject<number>;
  // The first index when the service loads should not be 0 by default.
  // It should be the index of the first unlinked provider in the list of providers.
  private readonly _firstIndex$: Observable<number>;
  private readonly _isEmailLinked$: Observable<boolean>;
  private readonly _nextProviderSub$: BehaviorSubject<void>;
  private readonly _providerConfigs$: Observable<readonly ProviderConfig[]>;
  private readonly _refreshCurrentUserSub$: BehaviorSubject<void>;

  constructor(
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
  ) {
    this._nextProviderSub$ = new BehaviorSubject<void>(undefined);
    // A set of the ids for all of the Authentication Providers currently linked to the User's account
    this.linkedProviderIds$ = this._nextProviderSub$.pipe(
      switchMap((): Observable<Set<string>> => {
        return this.authService.currentUser$.pipe(
          map((user: FirebaseUser): Set<string> => {
            // Get the array of provider ids already linked to the user account so we can filter out previously linked providers
            const linkedProviderIdsArray = user.providerData.map((provider: UserInfo): string => provider.providerId);
            // Convert to a Set to for easy inclusion testing
            return new Set(linkedProviderIdsArray);
          }),
          shareReplay({ bufferSize: 1, refCount: false }), // without this there are multiple emissions/subscriptions
        );
      }),
    );

    this._refreshCurrentUserSub$ = new BehaviorSubject<void>(undefined);

    // A list of the provider configurations for the client.
    this._providerConfigs$ = this.clientConfig.selfSignupConfig$.pipe(
      map((config: SelfSignupConfig): readonly ProviderConfig[] => config.providers),
      shareReplay({ bufferSize: 1, refCount: false }), // without this there are multiple emissions/subscriptions
    );

    this._isEmailLinked$ = this.linkedProviderIds$.pipe(
      map((linkedProviderIds: Set<string>): boolean => linkedProviderIds.has('password')),
    );

    // A list of the providerConfigs that haven't been linked to the User's account
    this.providers$ = combineLatest([ this.linkedProviderIds$, this._providerConfigs$ ]).pipe(
      map(([ linkedIds, configs ]: [ Set<string>, readonly ProviderConfig[] ]): readonly ProviderConfig[] => {
        // Enrich each provider config with a new field "linked" which tells whether or not
        // the User has linked this provider in Firebase Auth already.
        return configs.map((c: ProviderConfig): ProviderConfig => ({ ...c, linked: linkedIds.has(c.providerId) }));
      }),
    );

    this.linkedProviders$ = this.providers$.pipe(
      map((configs: readonly ProviderConfig[]): readonly ProviderConfig[] => {
        return configs.filter((c: ProviderConfig): boolean => !!c.linked);
      }),
    );

    this.unlinkedProviders$ = this.providers$.pipe(
      map((configs: readonly ProviderConfig[]): readonly ProviderConfig[] => {
        return configs.filter((c: ProviderConfig): boolean => c.linked === false);
      }),
    );

    // Determines if the member can link their email/password to their account
    this.canLinkEmail$ = combineLatest([
      this.clientConfig.selfSignupConfig$.pipe(map((config: SelfSignupConfig): boolean => config.enabled)),
      // If email linking is enabled if any of the provider configs has it enabled.
      this.providers$.pipe(
        map((configs: readonly ProviderConfig[]): boolean =>
          configs.some((config: ProviderConfig): boolean => config.emailLinkingEnabled === true)),
      ),
    ]).pipe(map(([ selfSignupEnabled, emailLinkingEnabled ]: [ boolean, boolean ]): boolean => emailLinkingEnabled && selfSignupEnabled));

    // Determines if the member still needs to link their email/password to their account
    this.needToLinkEmail$ = combineLatest([ this._isEmailLinked$, this.canLinkEmail$ ]).pipe(
      map(([ emailLinked, canLinkEmail ]: [ boolean, boolean ]): boolean => {
        return canLinkEmail && !emailLinked;
      }),
    );

    this.providerCountLinked$ = this.linkedProviderIds$.pipe(map((linkedProviderIds: Set<string>): number => linkedProviderIds.size));

    // A count of how many providers still remain to be linked.
    this.providerCountTotal$ = combineLatest([ this.needToLinkEmail$, this.providers$ ]).pipe(
      map(([ needToLinkEmail, configs ]: [ boolean, readonly ProviderConfig[] ]): number =>
        needToLinkEmail ? configs.length + 1 : configs.length),
    );

    this.oldEmail$ = this._refreshCurrentUserSub$.pipe(
      switchMap((): Observable<string> => {
        return this.authService.currentUser$.pipe(
          map((user: FirebaseUser): string | null => user.email),
          filter((email: string | null): email is string => email != undefined),
        );
      }),
    );

    // Find the index in the list of providers of the first unlinked provider
    // This is important because the displayed count should not always start at 1 (index 0 + 1)
    // see link-provider.component.html
    this._firstIndex$ = this.providers$.pipe(
      map((providers: readonly ProviderConfig[]): number => {
        const firstIndex = providers.findIndex((p: ProviderConfig): boolean => p.linked === false);
        return firstIndex === -1 ? 0 : firstIndex; // -1 would mess up the display count
      }),
    );

    this._currentIndexSub$ = new BehaviorSubject(this.currentIndex);
    this.currentIndex$ = this._currentIndexSub$.pipe(
      // Override the default index in case the member made partial progress through the list of providers
      switchMap((n: number): Observable<number> => {
        return this._firstIndex$.pipe(
          map((firstIndex: number): number => {
            if (firstIndex > n) {
              this.currentIndex = firstIndex; // catch the local variable up to speed
              return firstIndex;
            }

            return n; // otherwise just return the default
          }),
        );
      }),
    );
  }

  public incrementIndex(): void {
    // We should not need to cap the index here because we may be able
    // to rely on checking the current index exceeds the length somewhere above
    this.currentIndex += 1;
    this._currentIndexSub$.next(this.currentIndex);
  }

  /** Temporary bandaid to make currentUser$ emit again so we can get a refreshed
   * list of the link status of the providers.
   */
  public refreshCurrentUser(): void { // TODO unit test
    this._refreshCurrentUserSub$.next();
  }

  /** Temporary bandaid to make currentUser$ emit again so we can get a refreshed
   * list of the link status of the providers.
   */
  public refreshUnlinkedProviders(): void { // TODO unit test1
    this._nextProviderSub$.next();
  }
}
