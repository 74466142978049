import { Injectable } from '@angular/core';
import {
  combineLatest,
  concatMap,
  first,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { DocumentReference, Transaction } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { FeaturesConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import { getProgramId } from '@app/utilities/incentive-programs';

interface HistoricalEventBase {
  readonly createdAt: Date | FirestoreTimestamp;
  readonly eventName: string; // Type of event: Budget Completion, Checkup Completion, Lesson Completion, etc.
  readonly key: string; // Duplicates of primary key will only be counted once in the summary for the year.
  readonly metadata: Record<string, boolean | number | string | undefined>;
}

interface HistoricalEventFirestore extends HistoricalEventBase {
  readonly createdAt: FirestoreTimestamp;
}

export interface HistoricalSummary {
  [event: string]: number; // Count of number of unique (by primary key) events of each type.
}

interface UserHistoricalBase {
  readonly createdAt: Date | FirestoreTimestamp;
  readonly lastModified: Date | FirestoreTimestamp;
  readonly summary: Readonly<HistoricalSummary>;
}

interface UserHistoricalFirebase extends UserHistoricalBase {
  readonly createdAt: FirestoreTimestamp;
  readonly lastModified: FirestoreTimestamp;
}

export interface UserHistorical extends UserHistoricalBase {
  readonly createdAt: Date;
  readonly lastModified: Date;
  readonly programId: string;
}

@Injectable({ providedIn: 'root' })
export class UserHistoricalService {
  /** If the LearnLux app is kept open during a program ID change this will result in an incorrect ID. */
  public readonly currentHistorical$: Observable<UserHistorical | undefined>;

  private readonly _programId$: Observable<string>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly clientConfig: ClientConfigService,
  ) {
    this._programId$ = this.clientConfig.featuresConfig$.pipe(
      map((config: FeaturesConfig): string => {
        const maybeProgramId = getProgramId(config.incentiveConfig);
        if (maybeProgramId) {
          return maybeProgramId;
        }

        // TODO: ROB 20240102 - IncentiveProgramService has a similar default program ID. Align them.
        const now = new Date();
        return `${now.getFullYear()}`;
      }),
      // Specifically not `shareReplay`ing this Observable so that `recordEvent` will check the current
      // datetime for the correct programID
    );

    this.currentHistorical$ = this._programId$.pipe(
      switchMap((programId: string): Observable<UserHistorical | undefined> => this.getHistoricalForProgramId(programId)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  public getHistoricalForProgramId(programId: string): Observable<UserHistorical | undefined> {
    return this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<UserHistoricalFirebase> => this.afStore.doc(`users/${user.uid}/historicals/${programId}`),
      ),
      switchMap(
        (docRef: DocumentReference<UserHistoricalFirebase>): Observable<UserHistoricalFirebase | undefined> => this.afStore.docData(docRef),
      ),
      map((rawHistorical: UserHistoricalFirebase | undefined): UserHistorical | undefined => {
        if (rawHistorical) {
          return {
            ...rawHistorical,
            createdAt: rawHistorical.createdAt.toDate(),
            lastModified: rawHistorical.lastModified.toDate(),
            programId,
          };
        }
        return undefined;
      }),
    );
  }

  public recordEvent(eventName: string, key: string, metadata: HistoricalEventBase['metadata']): Observable<void> {
    const nowTs = FirestoreTimestamp.now();
    const payload: HistoricalEventFirestore = {
      createdAt: nowTs,
      eventName,
      key,
      metadata,
    };
    const primaryKey = `${eventName}__${key}`;

    // Based on documentation from https://firebase.google.com/docs/firestore/solutions/aggregation
    return combineLatest([
      this._programId$.pipe(first()), // Must complete so `concatMap` completes.
      this.authService.requireUser$,
    ]).pipe(
      concatMap(async ([ programId, user ]: [ string, FirebaseUser ]): Promise<void> => {
        const historicalPath = `users/${user.uid}/historicals/${programId}`;
        const historicalDocRef = this.afStore.doc(historicalPath);
        const newEventColRef = this.afStore.collection(historicalPath, 'events');

        return this.afStore.runTransaction(async (transaction: Transaction): Promise<void> => {
          const historicalSnap = await transaction.get(historicalDocRef);
          if (historicalSnap.exists()) {
            const summary = (historicalSnap.get('summary') ?? {}) as HistoricalSummary;
            // `summary[primaryKey]` is the count of `primaryKey` labeled events.
            // `summary[eventName]` is the count of the unique `primaryKey` values for the root `eventName`.
            // For lessons if you complete lessons foo, bar, bar (again), and baz that would result in:
            // summary: {
            //   Lessons_LessonProgress_Completed: 3,
            //   Lessons_LessonProgress_Completed__foo: 1,
            //   Lessons_LessonProgress_Completed__bar: 2,
            //   Lessons_LessonProgress_Completed__baz: 1,
            // }
            const currentCount = summary[primaryKey] ?? 0;
            if (currentCount === 0) {
              // Only increment the event type summary if this is a new primary event.
              summary[eventName] = (summary[eventName] ?? 0) + 1;
            }
            summary[primaryKey] = currentCount + 1;

            transaction.update(historicalDocRef, { lastModified: nowTs, summary });
          } else {
            // Need to create a new Historical
            const historicalData: UserHistoricalFirebase = {
              createdAt: nowTs,
              lastModified: nowTs,
              summary: {
                [eventName]: 1,
                [primaryKey]: 1,
              },
            };
            transaction.set(historicalDocRef, historicalData);
          }

          // It is not well documented how to create a new document in a Transaction using the Firebase Modular SDK.
          // This SO self-answer says this works: https://stackoverflow.com/a/75413485
          await this.afStore.addDoc(newEventColRef, payload);
        });
      }),
    );
  }
}
