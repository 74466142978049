// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 1.5rem;
}

.lux-dashboard-list {
  margin-bottom: 1rem;
  counter-reset: step;
}
.lux-dashboard-list h2 {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 800;
}

.step {
  position: relative;
  margin-bottom: 2.5rem;
  padding: 0 1.25rem;
  color: #475053;
}
@media (min-width: 992px) {
  .step {
    padding-left: 4rem;
  }
}

@media (min-width: 992px) {
  .step-advisor {
    margin-left: 1.5rem;
    border: 1px solid #f1f3f3;
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1.25rem 1.25rem 1.25rem 2.5rem;
  }
  .step-advisor .number-wrapper::before {
    position: absolute;
    top: 1.25rem;
    left: -1.5rem;
  }
}
.number-wrapper::before {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 1.25rem;
  background-color: #06c7a9;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 2.1875rem;
  content: counter(step);
  counter-increment: step;
}
@media (min-width: 992px) {
  .number-wrapper::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.step-actions {
  margin-top: 0.5rem;
  text-align: start;
}
@media (max-width: 575.98px) {
  .step-actions {
    text-align: center;
  }
}

aside {
  margin: 0 auto 3rem;
  max-width: 21rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
