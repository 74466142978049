import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslationModule } from '@app/translation/translation.module';

import { AuthService } from '../auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { EmailLoginComponent } from '../login/email-login/email-login.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ EmailLoginComponent, TranslationModule ],
  selector: 'lux-advisor-login',
  standalone: true,
  templateUrl: './advisor-login.component.html',
})
export class AdvisorLoginComponent {
  constructor(private readonly authService: AuthService) {
    this.authService.redirectUrl = '/planner/admin';
  }
}
