import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { combineLatest, map, shareReplay } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, DocumentReference } from '@app/angular-fire-shims/angular-firestore.service';
import type { Slides } from '@app/presentation/slides/slides';

import type {
  Lesson,
  LessonsFirestore,
  LessonTags,
  LessonTagsFirestore,
} from '../lessons';
import type { LessonsDataService } from './lessons-data.service';

type LessonMap = Map<string, Lesson>;
const lessonIdMap = (lesson: Lesson): [ string, Lesson ] => [ lesson.id, lesson ];

@Injectable({ providedIn: 'root' })
export class FirestoreLessonsDataService implements LessonsDataService {
  public readonly lessons$: Observable<Lesson[]>;
  public readonly lessonTags$: Observable<LessonTags[]>;

  constructor(private readonly afStore: AngularFirestoreService) {
    const lessonsCollection = this.afStore.collection<LessonsFirestore>('lessons');
    this.lessons$ = this.afStore.collectionData(lessonsCollection, { idField: 'id' })
      .pipe(
        map((lessons: LessonsFirestore[]): Lesson[] =>
          lessons.map((lesson: LessonsFirestore): Lesson =>
            // hidden logic is responsibility of lesson service this is only transforming the data type
            ({ ...lesson, date: lesson.date.toDate(), hidden: false }))),
        shareReplay({ bufferSize: 1, refCount: false }),
      );

    const lessonTagsCol = this.afStore.query(
      this.afStore.collection<LessonTagsFirestore>('lesson-tags'),
      this.afStore.orderByOrder,
    );

    this.lessonTags$ = combineLatest([
      this.afStore.collectionData(lessonTagsCol), // Don't need the tag IDs.
      this.lessons$.pipe(
        map((lessons: Lesson[]): LessonMap => new Map<string, Lesson>(lessons.map(lessonIdMap))),
      ),
    ]).pipe(
      map(([ tags, lessonMap ]: [ LessonTagsFirestore[], LessonMap ]): LessonTags[] => {
        // Populate the lessons for each tag.
        return tags.map((rawTag: LessonTagsFirestore): LessonTags => {
          const lessons = rawTag.lessons.map((lessonRef: DocumentReference<LessonsFirestore>): Lesson => {
            const lesson = lessonMap.get(lessonRef.id);
            if (!lesson) {
              throw new Error(`Tag '${rawTag.slug}' Lesson '${lessonRef.id}' not found.`);
            }

            return lesson;
          });

          return {
            ...rawTag,
            lessons,
          };
        });
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /** Fetch all Slides for a specific lesson by firestore UUID. */
  public getSlidesForLessonById(lessonId: string): Observable<Slides[]> {
    const lessonDoc = this.afStore.doc<Slides>(`lessons/${lessonId}`);
    const slideCol = this.afStore.collection<Slides>(lessonDoc.path, 'slides');
    const query = this.afStore.query(slideCol, this.afStore.orderByOrder);
    return this.afStore.collectionData(query); // No need for IDs here
  }
}
