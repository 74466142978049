import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { Observable } from 'rxjs';

import { SpinnerComponent } from '@app/stuff/spinner/spinner.component';
import { TranslationModule } from '@app/translation/translation.module';

import { ProfileProgressService } from '../profile-progress.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { ProfileProgress } from '../profile-progress.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    TranslationModule,
  ],
  selector: 'lux-dashboard-menu',
  standalone: true,
  styleUrls: [ './dashboard-menu.component.scss' ],
  templateUrl: './dashboard-menu.component.html',
})
export class DashboardMenuComponent {
  public readonly progress$: Observable<ProfileProgress>;

  constructor(private readonly service: ProfileProgressService) {
    this.progress$ = this.service.progress$;
  }
}
