import { Injectable } from '@angular/core';
import {
  map,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { CollectionReference, DocumentReference, Transaction } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

interface UserPreferenceBase {
  // Index signature value type must include all properties, but we probably only really want boolean | string | and number.
  readonly [preference: string]: Date | FirestoreTimestamp | boolean | number | string | undefined;

  readonly id?: string;
  readonly createdAt: Date | FirestoreTimestamp;
  readonly lastModified: Date | FirestoreTimestamp;
}

export interface UserPreference extends UserPreferenceBase {
  readonly createdAt: Date;
  readonly lastModified: Date;
}

export interface UserPreferenceFirestore extends UserPreferenceBase {
  readonly createdAt: FirestoreTimestamp;
  readonly lastModified: FirestoreTimestamp;
}

@Injectable({ providedIn: 'root' })
export class UserPreferencesService {
  /** All current user's preferences from multiple preference docs */
  public readonly allUserPreferences$: Observable<UserPreference[]>;
  /** Current user's language preferences in language doc */
  public readonly userLanguagePreference$: Observable<UserPreference | undefined>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
  ) {
    this.allUserPreferences$ = this._getAllPreferences();
    this.userLanguagePreference$ = this._getPreference('language');
  }

  /**
   * Converts a list of UserPreferenceFirestore objects to a list of UserPreference objects.
   * Public so it can also be used by AdvisorDataService.
   */
  public mogrifyFirestore(rawPreferences: UserPreferenceFirestore[]): UserPreference[] {
    return rawPreferences.map((raw: UserPreferenceFirestore): UserPreference => {
      return {
        ...raw,
        createdAt: raw.createdAt.toDate(),
        lastModified: raw.lastModified.toDate(),
      };
    });
  }

  /**
   * Updates or creates a users preference if the field exists in
   * the preferences documents interface
   * @param id - Preferences document id. Currently only 'language' is defined.
   * @param userPreference - Partial UserPreference interface, id is stripped out if included.
   */
  public setPreferences(id: string, userPreference: Partial<UserPreference>): Observable<void> {
    // This Observable will not fire until the user is logged in, which means it might never trigger.
    return this.authService.currentUser$.pipe(
      take(1), // Each subscribe should emit once then complete, but not error is this never happens!
      map(
        (user: FirebaseUser): DocumentReference<UserPreferenceFirestore> =>
          this.afStore.doc<UserPreferenceFirestore>(`users/${user.uid}/preferences/${id}`),
      ),
      switchMap(async (preferenceDoc: DocumentReference<UserPreferenceFirestore>): Promise<void> => {
        const nowTs = FirestoreTimestamp.now();

        await this.afStore.runTransaction(
          async (transaction: Transaction): Promise<Transaction> => {
            const prefDocSnap = await transaction.get(preferenceDoc);
            const createdAt: FirestoreTimestamp = (prefDocSnap.get('createdAt') as FirestoreTimestamp | undefined) ?? nowTs;
            const payload: UserPreferenceFirestore = {
              ...userPreference,
              createdAt,
              lastModified: nowTs,
            };
            return transaction.set(preferenceDoc, payload, { merge: true });
          },
        );
      }),
    );
  }

  /**
   * Gets current users specified preferences from all preference documents
   * @returns All preferences in an object
   */
  private _getAllPreferences(): Observable<UserPreference[]> {
    return this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<UserPreferenceFirestore> =>
          this.afStore.collection<UserPreferenceFirestore>(`users/${user.uid}/preferences`),
      ),
      // Add document id as a field to each collection
      switchMap(
        (collRef: CollectionReference<UserPreferenceFirestore>): Observable<UserPreferenceFirestore[]> =>
          this.afStore.collectionData(collRef, { idField: 'id' }), // Need the IDs
      ),
      // Format the dates within the collections
      map((rawPreferences: UserPreferenceFirestore[]): UserPreference[] => rawPreferences.map(
        (raw: UserPreferenceFirestore): UserPreference => ({
          ...raw,
          createdAt: raw.createdAt.toDate(),
          lastModified: raw.lastModified.toDate(),
        }),
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Gets current users specified preferences from a singular document
   * @param id - Document id, currently only supports 'language'
   * @returns Preferences of specified document in an object
   */
  private _getPreference(id: string): Observable<UserPreference | undefined> {
    return this.allUserPreferences$.pipe(
      map((preferences: UserPreference[]): UserPreference | undefined => {
        return preferences.find((p: UserPreference): boolean => p.id === id);
      }),
    );
  }
}
