import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import type { Observable } from 'rxjs';

import { UserPermissionsService } from '@app/users/user-permissions.service';
import type { UserPermissions } from '@app/users/user-permissions.service';

export const advisorAdminGuard = (): Observable<boolean> => {
  const permissionsSrvc = inject(UserPermissionsService);

  return permissionsSrvc.getUserPermissions().pipe(
    take(1), // RouterGuards that return observables must complete!
    map((permissions: UserPermissions | undefined): boolean =>
      // Routes that return false will fall through to the PageNotFound wildcard ('**') route.
      permissions?.roles.includes('advisor') ?? false),
  );
};
